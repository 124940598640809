import styled from 'styled-components';

const Header = styled.header`
	position: sticky;
	top: -100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: var(--headerPaddingTop) var(--headerPaddingLeft);
	transition: all var(--trTime2) ease-in-out;
	pointer-events: none;
	z-index: 14;

	> div {
		pointer-events: painted;
	}

	&.show {
		top: 0;
	}

	&.headerTransparent {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		z-index: 11;

		.widgetWrap {
			.SingInWrap {
				.avatarImg {
					border: 1px solid var(--black);
				}
			}
		}

		&.hide {
			top: -100%;
		}
	}

	&.headerBlack {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		z-index: 11;

		&.hide {
			top: -100%;
		}

		.widgetWrap {
			> div {
				color: var(--black) !important;

				i {
					color: var(--black) !important;
				}
			}

			.SingInWrap {
				.avatarImg {
					border: 1px solid var(--black);
				}
			}
		}

		.Burger span {
			background-color: var(--black) !important;
		}
	}

	.widgetWrap {
		display: flex;
		align-items: center;
		position: relative;
		z-index: 111;

		> div {
			cursor: pointer;
			margin-left: var(--sp3x);

			i {
				color: var(--white);
				font-size: var(--iconSize);
			}

			&:first-child {
				margin-left: 0;
			}
		}

		.SingInWrap {
			display: block;
			max-width: 60px;
			text-align: center;

			.avatarImg {
				overflow: hidden;
				border: 1px solid var(--white);
				border-radius: 100px;
				width: var(--iconSize);
				height: var(--iconSize);
			}
		}

		&.active {
			> div {
				color: var(--white) !important;

				i {
					color: var(--white) !important;
				}
			}

			.Burger span {
				background-color: var(--white) !important;
			}

			.SingInWrap .avatarImg {
				border: 1px solid var(--white);
			}
		}
	}

	@media only screen and (min-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) {
		&.headerTransparent {
			.widgetWrap {
				> div {
					color: var(--black);

					i {
						color: var(--black);
					}
				}

				.Burger span {
					background-color: var(--black);
				}
			}
		}
	}

	@media only screen and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
		.widgetWrap {
			> div {
				margin-left: var(--sp4x);
			}
		}
	}

	@media only screen and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
		justify-content: flex-start;

		.widgetWrap {
			position: absolute;
			top: var(--headerPaddingTop);
			right: var(--sp2-5x);
			flex-direction: column;
			justify-content: center;

			> div {
				margin-left: 0;
				margin-bottom: var(--sp5x);

				&:last-child {
					margin-bottom: 0;
				}
			}

			.SingIn .comp-title {
				white-space: pre-line;
				max-width: 70px;
				display: block;
				text-align: center;
			}
		}
	}

	@media only screen and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
		.widgetWrap {
			> div {
				margin-bottom: var(--sp6x);
			}
		}
	}
`;

export default Header;
