'use client';

import React, { memo as Memo, useState, useCallback, useEffect, Fragment, useMemo } from 'react';
import _ from 'lodash';

//* HOC's
import withLanguageContext from '../../../../../context/consumerHOC/LanguageConsumer';

//* Helpers
import formValidation from '../../../../../helpers/_formValidation';

//* Components
import FieldErrorMsg from '../../../Error/FieldErrorMsg';

const Textarea = Memo((props) => {
	//! Initial State
	const initialState = useMemo(() => {
		return {
			value: props.value || '',
			count: props.value ? props.value.length : 0,
		};
	}, [props.value]);

	//! States
	const [val, setVal] = useState(initialState);
	const [errorMsg, setErrorMsg] = useState();
	const [activeLine, setActiveLine] = useState(false);

	//! Field Value Change
	const onChange = (e) => {
		const length = e.target.value.length;

		if ((props.required.max && length <= props.required.max) || !props.required.max) {
			validate(e.target.value);
			props.onChange && props.onChange(props.name, e.target.value);
			setVal({ value: e.target.value, count: length });
		}
	};

	//!Validate
	const validate = useCallback(
		(newVal) => {
			if (props.realTimeValidate && props.required) {
				const valid = formValidation(newVal, props.type, props.errorMsg, !_.isBoolean(props.required) && props.required);
				!_.isBoolean(valid) && _.isBoolean(props.parentError) ? setErrorMsg(valid) : errorMsg && setErrorMsg('');
			}
		},
		[props.realTimeValidate, props.required, props.type, props.errorMsg, props.parentError]
	);

	//! Error Message
	useEffect(() => {
		setErrorMsg(props.errorMsg);
	}, [props.errorMsg]);

	//! Parent Component Reset
	useEffect(() => {
		if (props.parentVal !== val.value) {
			setVal(initialState);
			setErrorMsg('');
		}
	}, [props.parentVal]);

	const ActivLine = useCallback(() => {
		setActiveLine(true);
		setTimeout(() => {
			setActiveLine(false);
		}, 500);
	}, []);

	//! Checking Placeholder
	const placeholder = useCallback(props.placeholder ? props.translate(props.placeholder) + (props.required ? '*' : '') : '', [props.selectedLang, props.placeholder, props.required]);

	return (
		<Fragment>
			<div className={`textarea-cont ${props.className || ''} ${errorMsg ? 'error' : ''}`}>
				<textarea
					name={props.name || null}
					placeholder={placeholder}
					value={val.value}
					onFocus={ActivLine}
					onChange={(e) => onChange(e)}
					onKeyDown={(e) => !e.shiftKey && props.keyDown(e)}
				/>
			</div>

			<FieldErrorMsg msg={props.errorMsg || errorMsg || null} />
		</Fragment>
	);
});

export default withLanguageContext(Textarea, ['translate']);
