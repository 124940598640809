'use client';

import React, { memo as Memo, useMemo, useState, useRef } from 'react';
import { Collapse } from '@mui/material';

//* HOC's
import { withAccountContext } from '@/context';

//* Helpers
import formJson from '@/helpers/forms.json';

//* Components
import { Form, Text } from '@/components/common';

const ProfileForm = Memo((props) => {
	//! States
	const [expanded, setExpanded] = useState(false);
	const [accountSuccess, setAccountSuccess] = useState(false);
	const [passwordSuccess, setPasswordSuccess] = useState(false);

	const passwordRef = useRef(null);

	const accountNewParams = useMemo(() => {
		let obj = formJson.accountParams;
		Object.keys(formJson.accountParams.fields).map((name) => {
			obj = {
				...obj,
				fields: {
					...obj.fields,
					[name]: {
						...obj.fields[name],
						props: {
							...obj.fields[name].props,
							value: props.user?.[name],
						},
					},
				},
			};
		});

		return obj;
	}, [props.user]);

	const uploaderNewParams = useMemo(() => {
		const params = formJson.uploaderParams;
		params.fields.avatar.props.value[0].url = props.user?.avatar?.src || '/images/uploadPicture.svg';

		return params;
	}, [props.user]);

	const handleClick = () => {
		setExpanded(!expanded);
	};

	const passwordSuccessPop = () => {
		setPasswordSuccess(true);
		setExpanded(false);
		setTimeout(() => {
			setExpanded(true);
			setPasswordSuccess(false);
		}, 12000);
	};

	const changeAvatar = (name, file, fieldValues) => {
		props.updateAvatar(file[0]);
	};

	const updateUser = (data) => {
		props.updateProfile(data).then(() => {
			setAccountSuccess(true);

			setTimeout(() => {
				setAccountSuccess(false);
			}, [3000]);
		});
	};

	const changePassword = (data) => {
		props.updatePassword(data).then(() => {
			passwordRef.current.resetFields();
			passwordSuccessPop();
		});
	};

	return (
		<>
			<div className={'avatarForm'}>
				<Text
					tag={'div'}
					className={'h7 formItemTitle black arm-bold'}
					text={'AccountInformation'}
				/>
				<div className={'formCont'}>
					<Form
						wrapClass={'blackForm'}
						className={'formItems'}
						onChange={changeAvatar}
						{...uploaderNewParams}
					/>
				</div>
			</div>
			<div className={'profileForm'}>
				<div className={'formCont'}>
					<Form
						wrapClass={'blackForm'}
						className={'formItems'}
						submit={updateUser}
						{...accountNewParams}
					/>
				</div>
				{accountSuccess && (
					<div className='AccountSuccess'>
						<Text
							tag={'div'}
							className={'h9 arm-regular black'}
							text={'AccountSuccess'}
						/>
					</div>
				)}
			</div>
			<Collapse
				in={expanded}
				timeout='auto'
				unmountOnExit>
				<div className={'passwordForm'}>
					<Text
						tag={'div'}
						className={'h7 formItemTitle black arm-bold'}
						text={'changePassword'}
					/>

					<div className={'formCont'}>
						<Form
							wrapClass={'blackForm'}
							className={'formItems'}
							submit={changePassword}
							ref={passwordRef}
							{...formJson.passwordParams}
						/>
					</div>
				</div>
			</Collapse>
			<Collapse
				in={!passwordSuccess}
				timeout='auto'
				unmountOnExit>
				<div
					className='PasswordPop'
					onClick={handleClick}>
					<Text
						tag={'div'}
						className={'h9 arm-regular grey3 underline'}
						text={expanded ? 'Cancel' : 'ChangePassword'}
					/>
				</div>
			</Collapse>

			<Collapse
				in={passwordSuccess}
				timeout='auto'
				unmountOnExit>
				<div className='PasswordSuccess'>
					<Text
						tag={'div'}
						className={'h7 formItemTitle black arm-bold'}
						text={'changePassword'}
					/>

					<Text
						tag={'div'}
						className={'h9 arm-regular black'}
						text={'PasswordSuccess'}
					/>
				</div>
			</Collapse>
		</>
	);
});

export default withAccountContext(ProfileForm, ['user', 'updateAvatar', 'updateProfile', 'updatePassword']);
