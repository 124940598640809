import styled from 'styled-components';

const ContainerStyle = styled.section.attrs((props) => ({ className: props.className }))`
	max-width: calc(100% + (var(--containerPadding) * 2));
	padding-left: var(--containerPadding);
	padding-right: var(--containerPadding);
	margin-right: auto;
	margin-left: auto;
	width: 100%;
	margin-top: var(--marginTop);
	margin-bottom: var(--marginBottom);

	&.fullWidth {
		max-width: 100%;
	}

	@media only screen and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
		&.fullWidth {
			padding-left: var(--headerPaddingLeft);
			padding-right: var(--headerPaddingLeft);
		}
	}
`;
export default ContainerStyle;
