import React, { memo as Memo } from 'react';

//* HOC's
import { withUIContext, withDataContext } from '@/context';

//* Components
import Text from '../../../Text';
import PrivacyPolicyLink from '../../../PrivacyPolicyLink';

//* Style
import ContactStyle from './style';

const ContactInformation = Memo(({ type, globalData, ...props }) => {
	return globalData ? (
		<ContactStyle className={'ContactStyle'}>
			<div
				className='contactInfoWrap'
				onClick={props.onClick}>
				{type ? (
					<div className='contactInfoItem'>
						<div className='contactInfoItemTitle'>
							<Text
								tag={'span'}
								className={'h8 arm-regular greyMid'}
								text={'FollowUs'}
							/>
						</div>
						<a
							href={globalData.socials.facebook}
							className='p'
							target='_blank'
							rel='noopener noreferrer'>
							<i className={'icon-facebook'} />
						</a>
						<a
							href={globalData.socials.instagram}
							className='p'
							target='_blank'
							rel='noopener noreferrer'>
							<i className={'icon-insta'} />
						</a>
					</div>
				) : null}

				<div className='contactInfoItem'>
					<div className='contactInfoItemTitle'>
						<Text
							tag={'span'}
							className={'h8 arm-regular greyMid'}
							text={'PhoneNumber'}
						/>
					</div>
					<a
						href={`tel:${globalData.phone}`}
						className='h8 arm-regular'
						target='_blank'
						data={globalData.phone}
						rel='noopener noreferrer'>
						{globalData.phone}
					</a>
				</div>

				<div className='contactInfoItem'>
					<div className='contactInfoItemTitle'>
						<Text
							tag={'span'}
							className={'h8 arm-regular greyMid'}
							text={'EMail'}
						/>
					</div>
					<a
						href={`mailto:${globalData.email}`}
						className='h8 arm-regular'
						target='_blank'
						data={globalData.email}
						rel='noopener noreferrer'>
						{globalData.email}
					</a>
				</div>

				{!type ? (
					<div className='contactInfoItem'>
						<div className='contactInfoItemTitle'>
							<Text
								tag={'span'}
								className={'h8 arm-regular greyMid'}
								text={'FollowUs'}
							/>
						</div>
						<a
							href={globalData.socials.facebook}
							className='p'
							target='_blank'
							rel='noopener noreferrer'>
							<i className={'icon-facebook'} />
							<span className={'h8 arm-regular greyMid'}>facebook link</span>
						</a>
						<a
							href={globalData.socials.instagram}
							className='p'
							target='_blank'
							rel='noopener noreferrer'>
							<i className={'icon-insta'} />
							<span className={'h8 arm-regular greyMid'}>instagram link</span>
						</a>
					</div>
				) : null}

				{type ? <PrivacyPolicyLink className={'contactInfoItem'} /> : null}
			</div>
		</ContactStyle>
	) : null;
});

export default withUIContext(withDataContext(ContactInformation, ['globalData']), ['winWidth', 'screenSizes']);
