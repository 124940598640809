import styled from 'styled-components';

const MenuStyle = styled.div`
	--menuMargBottom: var(--sp4x);
	--hieroglyphPad: var(--sp1x);

	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	padding: var(--sp12x) var(--headerPaddingTop);
	background-color: var(--black);
	color: var(--white);
	overflow: hidden scroll;
	transform: translate3d(0, -100%, 0);
	transition: transform 0.3s ease-out;
	z-index: 88;

	&.active {
		transform: translate3d(0, 0, 0);
	}

	.hieroglyphCont {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		overflow: hidden;
		z-index: -1;

		.hieroglyph {
			opacity: 0.07;
			word-wrap: break-word;
			padding: 0 var(--hieroglyphPad);
			font-weight: 800;
			transform: translate3d(0, -100%, 0);

			&:last-child {
				padding-right: 0;
			}
		}
	}

	.menuWrap {
		margin-bottom: var(--menuMargBottom);

		.menu-items-cont {
			.menu-item {
				color: var(--white);
				font-family: arm-bold;
				font-size: 37px;
				line-height: var(--lineHeight);
				margin-bottom: var(--sp2x);

				.Linkmenu-item {
					display: inline-block;
					position: relative;

					.word {
						overflow: hidden;
						display: flex;

						.letter {
							display: block;
							min-width: calc(var(--h2) / 2.5);
							transform: translate(0, 100%);
						}
					}

					.svgHover {
						position: absolute;
						bottom: 12%;
						left: -15px;
						right: -10px;
						z-index: -1;
						width: calc(100% + 25px);
						height: 14px;
						transition: all var(--trTime2) linear;
						clip-path: circle(100% at -100% 50%);
					}

					&:hover {
						.svgHover {
							clip-path: circle(100% at 50% 50%);
						}
					}
				}

				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}

	.contactInfoItem a,
	i {
		color: var(--white);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.MobileSize}) {
		--menuMargBottom: var(--sp15x);
		--hieroglyphPad: var(--sp2x);

		padding: var(--sp20x) var(--sp10x) var(--sp5x);

		.menuWrap {
			.menu-items-cont {
				.menu-item {
					margin-bottom: var(--sp3x);
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.LaptopSize}) {
		--hieroglyphPad: var(--sp2-5x);

		padding: var(--sp20x) var(--sp18x) var(--sp15x);
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		.hieroglyphCont {
			right: var(--sp15x);
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
		--menuMargBottom: var(--sp12x);
		--hieroglyphPad: var(--sp3x);

		padding: var(--sp17x) var(--sp15x) 0 var(--sp25x);

		.hieroglyphCont {
			right: var(--sp15x);
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
		--menuMargBottom: var(--sp12x);
		--hieroglyphPad: var(--sp5x);

		padding: var(--sp18x) var(--sp20x) 0 var(--sp40x);

		.hieroglyphCont {
			right: var(--sp20x);
		}
	}

	@media only screen and (min-width: ${(props) => props.theme.mediaQuery.MobileSize}) {
		.menuWrap {
			.menu-items-cont {
				.menu-item {
					font-size: var(--h2);
				}
			}
		}
	}

	@media only screen and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
		--menuMargBottom: var(--sp23x);
		--hieroglyphPad: var(--sp7x);

		padding: var(--sp29x) var(--sp20x) 0 var(--sp20x);

		.hieroglyphCont {
			right: var(--sp20x);
		}

		.menuWrap,
		.ContactStyle {
			max-width: 78%;
			margin-left: auto;
			margin-right: auto;
		}
	}
`;

export default MenuStyle;
