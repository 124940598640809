'use client';

import React, { memo as Memo, useEffect, useRef, useMemo, useState } from 'react';

//* Components
import { Collapse } from '@mui/material';
import Text from '@/components/common/Text';
import Translate from '@/components/common/Translate';

const CustomSelect = Memo((props) => {
	//! States
	const [isOpened, setIsOpened] = useState(false);
	const selectValRef = useRef();
	const selectListRef = useRef();

	//! Toggle Select
	const toggleSelectBox = () => {
		setIsOpened(!isOpened);
	};

	//! Handle Click
	const handleClick = (e) => {
		if (!selectValRef.current.contains(e.target) && !selectListRef.current.contains(e.target) && isOpened) {
			toggleSelectBox();
		}
	};

	//! Click Event Listener
	useEffect(() => {
		document.addEventListener('mousedown', handleClick);

		return () => {
			document.removeEventListener('mousedown', handleClick);
		};
	});

	//! Element Select
	const elClick = (selectedEl) => {
		isOpened && toggleSelectBox();
		props.onChange && props.onChange(selectedEl);
	};

	//! Selected Value HTML
	const selectedValueHTML = useMemo(() => {
		return (
			<div
				className={`selected-val ${props.placeholder && !props.selected.label ? 'placeholder' : ''}`}
				ref={selectValRef}
				onClick={toggleSelectBox}>
				{props.leftIcon && (typeof props.leftIcon === 'string' ? <i className={`select-left-icon ${props.leftIcon}`} /> : <i className={`select-left-icon`}>{props.leftIcon}</i>)}

				{<Translate val={props.selected.label} /> || (props.placeholder && props.placeholder + (props.required ? '*' : '')) || ''}

				<i className={`select-icon ${props.icon || 'ma-arrow-down'}`} />
			</div>
		);
	}, [props.selected.label, props.placeholder, isOpened]);

	//! List HTML
	const listHTML = useMemo(() => {
		return (
			<div className='list-items'>
				<div
					className='select-list'
					ref={selectListRef}>
					<Collapse
						in={isOpened}
						timeout={0.5}
						unmountOnExit>
						{props.list.map((i, k) => (
							<span
								className={`select-list-item ${i.label === props.selected.label ? 'active' : ''}`}
								key={k}
								onClick={() => elClick(i)}>
								<Translate val={i.label} />
							</span>
						))}
					</Collapse>
				</div>
			</div>
		);
	}, [isOpened]);

	return (
		<>
			<div className='labelWrap FlexBox spaceBetween alignMiddle'>
				<div className='FlexBox alignMiddle'>
					<Text
						tag={'label'}
						className='form-col-label pSm arm-regular'
						text={props.lable}
					/>
					{props.required && ' *'}
				</div>
			</div>

			<div className={`select-cont custom-select-cont ${props.errorMsg ? 'error' : ''} ${isOpened ? 'selectbox-opened' : ''} ${props.leftIcon ? 'with-left-icon' : ''}`}>
				{selectedValueHTML}

				{listHTML}
			</div>
		</>
	);
});

export default CustomSelect;
