import styled from 'styled-components';

const CheckStyle = styled.div`
	--inputPadd: var(--sp1-5x);
	--btnPaddNew: 11px;

	.form-container {
		input:-webkit-autofill,
		input:-webkit-autofill:hover,
		input:-webkit-autofill:focus,
		textarea:-webkit-autofill,
		textarea:-webkit-autofill:hover,
		textarea:-webkit-autofill:focus,
		select:-webkit-autofill,
		select:-webkit-autofill:hover,
		select:-webkit-autofill:focus {
			-webkit-text-fill-color: var(--black);
			-webkit-box-shadow: 0 0 0px 1000px #000 inset;
			transition: background-color 5000s ease-in-out 0s;
		}

		display: flex;
		flex-wrap: wrap;
		padding-top: var(--sp4x);

		.form-rows {
			.form-col {
				position: relative;
				margin-bottom: var(--formColMargBott);

				.form-col-label,
				.MuiCollapse-wrapper,
				.labelWrap {
					color: var(--black);
				}
				.labelWrap {
					padding-bottom: 3px;
					position: absolute;
					left: 0;
					bottom: 100%;
				}

				.formItems {
					input {
						border: 1px solid var(--black);
						border-right: 0;
						color: var(--black) !important;
						line-height: var(--lineHeightS);
						background-color: transparent;
						width: 100%;
						padding: var(--inputPadd);
						font-size: var(--h10);
						@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
							font-size: var(--sp2x) !important;
						}
					}
					&.error {
						margin-bottom: var(--sp1x);
						input {
							border: 1px solid var(--errorColor) !important;
						}
					}
				}

				.MuiCollapse-container {
					.errorCont {
						color: var(--errorColor);
					}
				}
			}
		}
		.btn-actions {
			flex: 0 0 35%;
			.btn {
				border: 0;
				&.btn-mid {
					border: 2px solid var(--black);
					padding: var(--btnPaddNew);
				}
			}
		}

		.disabled {
			pointer-events: none;
		}
	}
`;

export default CheckStyle;
