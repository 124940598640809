import React, { memo as Memo } from 'react';

//* Components
import Text from '../../../Text';
import Translate from '../../../Translate';
import CustomLink from '../../../CustomLink';

const Switch = Memo((props) => {
	return (
		<div className={`switch-cont FlexBox alignMiddle cursorPointer ${props.checked ? 'checked' : ''}`}>
			<div
				className={`switch ${props.whithLink ? 'switchLink' : ''}`}
				onClick={() => props.onChange && props.onChange(props.value)}>
				<input
					type={props.inputType || 'checkbox'}
					name={props.name}
					onChange={() => props.onChange && props.onChange(props.value)}
					checked={props.checked}
				/>
			</div>

			{props.whithLink ? (
				<Text
					tag={'label'}
					className={`switch-link`}
					children={
						<>
							<span onClick={() => props.onChange && props.onChange(props.value)}>
								<Translate val={props.label} />
							</span>
							<CustomLink
								url={`/privacy-policy`}
								className='underline'
								content={<Translate val={props.whithLink} />}
							/>
						</>
					}
				/>
			) : props.icon ? (
				<i
					className={`${props.label}  switch-name h7`}
					onClick={() => props.onChange && props.onChange(props.value)}
				/>
			) : (
				<Text
					tag={'label'}
					className={`switch-name underline`}
					text={props.label}
					onClick={() => props.onChange && props.onChange(props.value)}
				/>
			)}
		</div>
	);
});

export default Switch;
