import React, { memo as Memo } from 'react';

//* Components
import Text from '../../../Text';

const DefaultCheckbox = Memo((props) => {
	return (
		<div className={`def-checkbox ${props.checked ? 'checked' : ''}`}>
			<span className='def-checkbox-el'>
				<input
					name={props.name}
					type={props.inputType || 'checkbox'}
					label={props.label}
					onChange={() => props.onChange && props.onChange(props.value)}
					checked={props.checked}
				/>
			</span>

			<Text
				tag={'label'}
				className={`def-checkbox-name`}
				text={props.label}
				onClick={() => props.onChange && props.onChange(props.value)}
			/>
		</div>
	);
});

export default DefaultCheckbox;
