'use client';

import { useState, useEffect } from 'react';
import { useSearchParams } from 'next/navigation';

//* HOC's
import { withCartContext } from '@/context';

//* Helpers
import { config } from '@/helpers';
import { createQueryUrl } from '@/helpers/_functions';

//* Components
import Text from '../Text';
import Button from '../Button';
import CustomImage from '../Image';
import CustomLink from '../CustomLink';

//* Style
import ProductItemStyle from './style';

const Product = ({ description, feature_image, productId, slug, name, price, sale_price, tags, addToCart, scroll, className, shallow = false }) => {
	//! Next Navigation
	const searchParams = useSearchParams();

	//! States
	const [qty, setQty] = useState(1);
	const [btnTextState, setBtnTextState] = useState(false);

	useEffect(() => {
		setTimeout(() => {
			setBtnTextState(false);
		}, 500);
	}, [btnTextState]);

	const handleProductUrl = () => {
		history.pushState({}, '', createQueryUrl({ url: `${config.routes.products.path}/${slug}`, searchParams }));
	};

	return (
		<ProductItemStyle>
			<div className='productItemAfterWrap'>
				<div className='productItemAfter productItemAfterLeft' />
				<div className='productItemAfter productItemAfterTop' />
				<div className='productItemAfter productItemAfterRight' />
				<div className='productItemAfter productItemAfterBott' />
			</div>

			<div className='productItem'>
				{!shallow ? (
					<CustomLink
						scroll={scroll}
						url={`${config.routes.products.path}/${slug}`}
						className={`product-image-wrapper ${className || ''}`}
						content={
							<>
								<CustomImage
									alt={name}
									src={feature_image?.src}
									className={'productImg'}
								/>

								{tags?.length > 0 ? (
									<CustomImage
										alt={tags[0].name}
										src={tags[0].image?.src}
										className={'productIcon'}
									/>
								) : null}
							</>
						}
					/>
				) : (
					<div
						className={`product-image-wrapper ${className || ''}`}
						onClick={handleProductUrl}>
						<CustomImage
							alt={name}
							src={feature_image?.src}
							className={'productImg'}
						/>

						{tags?.length > 0 ? (
							<CustomImage
								alt={tags[0].name}
								src={tags[0].image?.src}
								className={'productIcon'}
							/>
						) : null}
					</div>
				)}
				<div className='productInfoWrap'>
					<div className='productInfo'>
						<Text
							tag={'div'}
							className={'productName h9 arm-regular'}>
							{name}
						</Text>

						<Text
							tag={'div'}
							className={'productDescription pSm arm-regular greyMid'}>
							{description}
						</Text>
					</div>

					<div className='productPriceWrap FlexBoxContainer spaceBetween alignMiddle'>
						<div className='productPrice FlexBox alignMiddle'>
							{sale_price ? (
								<>
									<Text
										tag={'div'}
										className={'productPriceActual h10 arm-regular'}
										children={
											<>
												<i className='icon-currency' />
												{sale_price}
											</>
										}
									/>

									<Text
										tag={'div'}
										className={'productPriceLast h10 arm-regular greyMid'}
										children={
											<>
												<i className='icon-currency' />
												{price}
											</>
										}
									/>
								</>
							) : (
								<Text
									tag={'div'}
									className={'productPriceActual h10 arm-regular'}
									text={
										<>
											<i className='icon-currency' />
											{price}
										</>
									}
								/>
							)}
						</div>

						<div className='productQuantityWrap FlexBox alignMiddle'>
							<div
								className='productQuantityMin pSm'
								onClick={() => setQty((prevState) => (prevState > 1 ? qty - 1 : qty))}>
								<i className='icon-minus' />
							</div>

							<div className='productQuantity'>
								<Text
									tag={'div'}
									className={'p arm-regular'}>
									{qty}
								</Text>
							</div>

							<div
								className='productQuantityMax pSm'
								onClick={() => setQty((prevState) => (prevState + 1 <= 99 ? qty + 1 : qty))}>
								<i className='icon-plus' />
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className='btnContWrap'>
				<Button
					className={'btn btn-xs btn-white'}
					text={btnTextState ? 'Added' : 'AddToCart'}
					onClick={() => {
						addToCart(productId, qty, sale_price || price);
						setBtnTextState(true);
					}}
				/>
			</div>
		</ProductItemStyle>
	);
};

export default withCartContext(Product, ['addToCart']);
