import styled from 'styled-components';

const PaginationStyle = styled.div`
	--paginationMargTop: var(--sp8x);
	--paginationItemMarg: var(--sp1-3x);

	margin-top: var(--paginationMargTop);

	.pagination__list {
		.pagination__item {
			margin: 0 var(--paginationItemMarg);
			display: flex;
			align-items: center;
			a {
				transition: all var(--trTime2) ease;
				color: var(--grey);
				font-family: arm-regular;
				font-size: var(--pSm);
				display: flex;
				align-items: center;

				&.pagination__link--current {
					color: var(--white);
				}
				&.pagination__link--disabled {
					i:before {
						color: var(--grey);
					}
				}
			}
			&:hover {
				a {
					color: var(--white);
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
		--paginationMargTop: var(--sp10x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.LaptopSize}) {
		--paginationMargTop: var(--sp10x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
		--paginationMargTop: var(--sp13x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
		--paginationMargTop: var(--sp15x);
	}

	@media only screen and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
		--paginationMargTop: var(--sp18x);
		--paginationItemMarg: var(--sp2x);
	}
`;

export default PaginationStyle;
