'use client';

import { memo as Memo, useState, useEffect } from 'react';
import { useSearchParams, useRouter } from 'next/navigation';
import { Fade } from '@mui/material';

//* Helpers
import { config } from '@/helpers';

//* Components
import Text from '@/components/common/Text';

//* Style
import SearchStyle from './style';

const SearchPopup = Memo((props) => {
	//! Next Navigation
	const router = useRouter();
	const searchParams = useSearchParams();

	//! State
	const [val, setVal] = useState('');

	//! Field Value Change
	const onChange = (e) => {
		let newVal = e.target.value;
		setVal(newVal.trimStart());
	};

	const escFunction = (e) => {
		if (e.keyCode === 27 && props.openedPop) {
			props.onClick();
		}
	};

	useEffect(() => {
		!props.openedPop && !searchParams.get('search') && setVal('');
	}, [props.openedPop, searchParams]);

	useEffect(() => {
		document.addEventListener('keydown', escFunction, false);

		return () => {
			document.removeEventListener('keydown', escFunction, false);
		};
	}, [props.openedPop]);

	const onEnter = (e) => {
		if (e.keyCode === 13 && props.openedPop) {
			e.preventDefault();
			props.onClick();
			val && router.push(`${config.routes.search.path}?search=${val}`);
		}
	};

	return (
		<Fade
			unmountOnExit
			in={props.openedPop}
			timeout={{ enter: 400, exit: 700 }}>
			<SearchStyle>
				<div className={`searchInputWrap ${props.openedPop ? 'active' : ''}`}>
					<div className='searchInput'>
						<input
							autoFocus
							value={val}
							type={'text'}
							name={'search'}
							placeholder={''}
							onChange={onChange}
							onKeyDown={onEnter}
						/>
					</div>

					<div className='instruction'>
						<Text
							tag={'p'}
							className={'p arm-regular'}
							text={'Instruction'}
						/>
					</div>
				</div>

				<i
					onClick={props.onClick}
					className={'close icon-X'}
				/>
			</SearchStyle>
		</Fade>
	);
});

export default SearchPopup;
