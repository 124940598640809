'use client';

import React, { forwardRef, useState, useEffect, useCallback, useMemo, useRef, useImperativeHandle } from 'react';
import _ from 'lodash';

//* HOC's
import withLanguageContext from '../../../context/consumerHOC/LanguageConsumer';
import withUIContext from '../../../context/consumerHOC/UIConsumer';

//* Helpers
import formValidation from '../../../helpers/_formValidation';

//* Components
import Button from '../Button';
import Input from './formElements/Input';
import Checkbox from './formElements/Checkbox';
import Textarea from './formElements/Textarea';
import Select from './formElements/Select';
import Uploader from './formElements/Uploader';

const Form = forwardRef((props, ref) => {
	const rowRef = useRef([]);

	//! Setting Default Values
	const setDefaultValues = useCallback(() => {
		let initialFields = {};
		const arrTypes = ['tagsInput', 'checkbox', 'dropdownCheckobox', 'select'];
		Object.keys(props.fields).map((name) => {
			const defVal = arrTypes.includes(props.fields[name].fieldType) ? [] : '';
			initialFields = {
				...initialFields,
				[name]: {
					...props.fields[name],
					errorMsg: '',
					value: props.fields[name].props?.value || defVal,
				},
			};
		});

		return initialFields;
	}, [props]);

	//! Send Function To Parent Component
	useImperativeHandle(ref, () => ({
		resetFields() {
			reset();
		},
		submitForm() {
			submitForm();
		},
		rowRefs: rowRef.current,
	}));

	//! States
	const [fields, setFields] = useState(setDefaultValues);
	const [elements, setElements] = useState([]);
	const [error, setError] = useState(null);

	//! Reset Fields
	const reset = useCallback(
		(e) => {
			e && e.preventDefault();
			updateErrorState(null);
			props.removeErrorMsg('form');
			setFields(setDefaultValues());
		},
		[props]
	);

	//! Field Values for Listen onChange when Mounting Child Components ↓
	let fieldValues = fields;

	//! Element Value Change ⮃
	const onChange = (name, val) => {
		if ((typeof val !== 'object' && fields[name].value !== val) || (val && typeof val !== 'string' && val.length && !_.isEqual(fields[name].value.sort(), val.sort())) !== false) {
			//* Syntax for Safari ---> spread operator don't work correctly in safari
			let newObj = { ...fieldValues };
			newObj[name] = { ...fields[name], value: val };
			fieldValues = newObj;
			setFields(newObj);

			const [formValues, newError] = validateForm(newObj, true);

			//* Checking is now value is equal form starting value
			!newError && _.isEqual(formOldValues, formValues) ? updateErrorState(null) : updateErrorState(newError);

			//* Calling parennt change
			props.onChange && props.onChange(name, val, formValues);
		}
	};

	//! Element Key Down
	const keyDown = (e) => {
		// Your Code Here
	};

	//! Form Submit
	const submitForm = (e) => {
		e && e.preventDefault();
		const [formValues, newError, newVals] = validateForm();

		updateErrorState(newError);

		if (newError === false) {
			props.submit && props.submit(formValues);
			props.removeErrorMsg('form');
		} else {
			setFields(newVals);
		}
	};

	//! Update Error State
	const updateErrorState = (newError) => {
		if (newError) {
			!error && setError(true);
		} else {
			setError(newError);
		}
	};

	//! Validate Form Element
	const validateFormEl = (value, type, settings) => {
		return formValidation(value, type, '', settings);
	};

	//! Validate Form
	const validateForm = (newFields, getValues = false) => {
		let newError = false,
			newVals = newFields || fields,
			formValues = {};

		Object.keys(newVals).map((name) => {
			formValues = { ...formValues, [name]: newVals[name].value };

			if (newVals[name].props?.required) {
				let value = newVals[name].value;

				if (newVals[name].props.type === 'c_password') {
					value = { pass: newVals.password.value, c_pass: newVals[name].value };
				}

				let settings = !_.isBoolean(newVals[name].props.required) && newVals[name].props.required;

				const isValid = validateFormEl(value, newVals[name].props.type || newVals[name].fieldType, settings);

				let msg = '';

				if (!_.isBoolean(isValid)) {
					newError = true;
					msg = !getValues && isValid;
				}

				//* Syntax for Safari ---> spread operator don't work correctly in safari
				let newObj = { ...newVals };
				newObj[name] = { ...newVals[name], errorMsg: msg };
				newVals = newObj;
			}
		});

		return [formValues, newError, newVals];
	};

	const [formOldValues] = useMemo(() => validateForm(setDefaultValues(), true), [props]);

	//! Returning Elements From Type
	const getElement = useCallback(
		(name, field) => {
			const fieldServerError = props.formError.names.includes(name) && props.formError.msg[name];

			switch (field.fieldType) {
				case 'input':
					return (
						<Input
							name={name}
							{...field.props}
							onChange={(name, val) => onChange(name, val)}
							keyDown={keyDown}
							realTimeValidate={props.realTimeValidate}
							errorMsg={fieldServerError || field.errorMsg}
							password={field.props.type === 'c_password' && fields.password.value}
							parentVal={field.value}
							parentError={error}
							className={props.className}
							inputWrapClass={props.inputWrapClass}
						/>
					);
				case 'select':
					return (
						<Select
							name={name}
							{...field.props}
							onChange={(name, val) => onChange(name, val)}
							keyDown={keyDown}
							realTimeValidate={props.realTimeValidate}
							errorMsg={fieldServerError || field.errorMsg}
							parentVal={field.value}
							parentError={error}
						/>
					);
				case 'checkbox':
					return (
						<Checkbox
							name={name}
							type='checkbox'
							{...field.props}
							onChange={(name, val) => onChange(name, val)}
							realTimeValidate={props.realTimeValidate}
							errorMsg={fieldServerError || field.errorMsg}
							parentVal={field.value}
							parentError={error}
							whithLink={field.props.whithLink}
						/>
					);
				case 'textarea':
					return (
						<Textarea
							name={name}
							{...field.props}
							onChange={(name, val) => onChange(name, val)}
							keyDown={keyDown}
							realTimeValidate={props.realTimeValidate}
							errorMsg={fieldServerError || field.errorMsg}
							parentVal={field.value}
							parentError={error}
						/>
					);
				case 'uploader':
					return (
						<Uploader
							name={name}
							{...field.props}
							onChange={(name, val) => onChange(name, val)}
							realTimeValidate={props.realTimeValidate}
							errorMsg={fieldServerError || field.errorMsg}
							parentVal={field.value}
							parentError={error}
						/>
					);
				default:
					return;
			}
		},
		[fields, error, props]
	);

	//! Fields to Row
	const fieldsToRow = useCallback(
		(elementsList) => {
			return props.sizes.map((c, k) => {
				const pulled = _.take(elementsList, c);
				elementsList = elementsList.slice(c);

				return (
					<div
						className={`form-row ${c === 2 ? props.classColl : ''}`}
						key={k}>
						{pulled}
					</div>
				);
			});
		},
		[props.sizes, props.formError, props.classColl]
	);

	//! Returning All Elements with Titles and Subtitles
	const getElements = useCallback(() => {
		let elementsList = Object.keys(fields).map((name, i) => {
			return (
				<div
					className={`form-col ${props.wrapContClass || ''}`}
					key={i}
					ref={(ref) => (rowRef.current[i] = ref)}>
					{getElement(name, fields[name])}
				</div>
			);
		});

		if (props.sizes) {
			return fieldsToRow(elementsList);
		}

		return elementsList;
	}, [fields, props.sizes, props.formError, error, rowRef]);

	//! Getting Fields and Setting All Elements
	useEffect(() => {
		setElements(getElements());
	}, [fields, error, props.sizes, props.formError]);

	return (
		<form
			onSubmit={submitForm}
			className={`form-container ${props.wrapClass ? props.wrapClass : ''} ${error ? 'form-error' : ''}`}
			autoComplete='off'
			ref={ref}>
			<div className='form-rows'>{elements}</div>

			{(props.submitText || props.reset) && (
				<div className='btn-actions'>
					{props.submitText && (
						<Button
							className={`btn btn-mid btn-max-width ${props.wrapClass === 'whiteForm' ? 'btn-white' : ''}`}
							disabled={!!props.disabledBtn && error !== false}
							onClick={submitForm}
							text={props.translate(props.submitText)}
						/>
					)}

					{props.reset && (
						<Button
							className={`btn btn-mid btn-max-width ${props.wrapClass === 'whiteForm' ? 'btn-white' : ''}`}
							onClick={reset}
							text={props.translate(props.resetText || 'reset')}
						/>
					)}
				</div>
			)}
		</form>
	);
});

export default withLanguageContext(withUIContext(Form, ['formError', 'removeErrorMsg']), ['translate', 'selectedLang']);
