import styled from 'styled-components';

const LogoStyle = styled.div`
	.Logo {
		display: flex;
		align-items: center;

		img {
			width: 100%;
			height: auto;
		}
	}

	.headerLogo {
		position: relative;
		max-width: var(--logoSize);
		z-index: 111;
	}

	.footerLogo {
		--logoFooterSize: 260px;
		--logoMargBottom: var(--sp5x);

		span {
			width: 100%;
		}

		max-width: var(--logoFooterSize);
		margin-bottom: var(--logoMargBottom);

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.MobileSize}) {
			--logoFooterSize: 300px;
			--logoMargBottom: var(--sp6x);
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.LaptopSize}) {
			--logoFooterSize: 400px;
			--logoMargBottom: var(--sp7x);
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) {
			--logoFooterSize: 420px;
			--logoMargBottom: var(--sp10x);
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
			--logoFooterSize: 560px;
			--logoMargBottom: var(--sp10x);
		}

		@media only screen and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
			--logoFooterSize: 740px;
			--logoMargBottom: var(--sp15x);
		}
	}
`;

export default LogoStyle;
