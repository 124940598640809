import styled from 'styled-components';

const BasketStyle = styled.div`
	.basketWrap {
		display: block;
		text-align: center;
		.quantityProd {
			position: absolute;
			top: 67%;
			left: 50%;
			transform: translate3d(-50%, -50%, 0);
			font-size: 8px;
		}
		.priceProd {
			margin-bottom: -70%;
			i {
				position: absolute;
				top: 0;
				right: 0;
				transform: translateX(100%);
				font-size: calc(var(--pSm) / 2) !important;
			}
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
			.quantityProd {
				font-size: 10px;
				top: 67%;
			}
		}

		@media only screen and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
			.priceProd {
				margin-bottom: 0;
			}
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
			.quantityProd {
				font-size: 10px;
				top: 60%;
			}
		}

		@media only screen and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
			.quantityProd {
				font-size: 12px;
				top: 67%;
			}
		}
	}
`;

export default BasketStyle;
