import React, { memo as Memo } from 'react';

const DefaultTag = Memo((props) => {
	return (
		<div className={`default-tag-cont tag-cont ${props.className || ''}`}>
			{props.children}

			{props.onRemove && (
				<span
					className='remove-tag'
					onClick={props.onRemove}>
					<i className='ma-close p-xs' />
				</span>
			)}
		</div>
	);
});

export default DefaultTag;
