'use client';

import React, { memo as Memo, useMemo, useEffect, useRef, useCallback } from 'react';
import { TimelineLite } from 'gsap';

//* Helpers
import { config } from '@/helpers';

//* Components
import Text from '../Text';
import CustomLink from '../CustomLink';

//* Style
import SliderTextStyle from './style';

const TextSlider = Memo(() => {
	//! Ref's
	const textRef2 = useRef(),
		textRef3 = useRef();

	//! Global Variables
	const tl0 = useMemo(() => new TimelineLite({ repeat: -1, paused: true }), []);
	const tl1 = useMemo(() => new TimelineLite({ repeat: -1, paused: true }), []);

	useEffect(() => {
		tl1.progress(0).clear();
		tl0.progress(0).clear();

		tl0.to(textRef2.current, 14, {
			x: '-50%',
			ease: 'none',
		});

		tl1.set(textRef3.current, {
			x: '-50%',
			ease: 'none',
		}).to(textRef3.current, 14, {
			x: '0%',
			ease: 'none',
		});

		tl0.play();
		tl1.play();
	}, []);

	//! Global Varibales
	const fastSpeed = useMemo(() => 15, []);
	const slowSpeed = useMemo(() => 50, []);

	//! Animation Updata

	const toggleHover2 = useCallback(
		(startDuration) => {
			tl0.duration(startDuration);
			tl1.duration(startDuration);
		},
		[textRef2, textRef3]
	);

	//! Map Number Of News
	const links = useMemo(() => {
		const arr = [];
		for (let i = 1; i < 13; i++) {
			arr.push(
				<Text
					key={i}
					tag={'span'}
					className={'arm-bold'}
					text={'ShopNow'}
					uppercase={true}
				/>
			);
		}
		return arr;
	}, []);

	return (
		<SliderTextStyle>
			<div
				className='ShopNowTickerCont'
				onMouseEnter={() => toggleHover2(slowSpeed)}
				onMouseLeave={() => toggleHover2(fastSpeed)}>
				<CustomLink
					className='ShopNowTickerBlack'
					url={config.routes.products.path}
					shallow={true}
					content={
						<div
							className='FlexBox'
							ref={textRef2}>
							<div className='FlexBox white'>{links}</div>
							<div className='FlexBox white'>{links}</div>
						</div>
					}
				/>

				<CustomLink
					className='ShopNowTickerWhite'
					url={config.routes.products.path}
					shallow={true}
					content={
						<div
							className='FlexBox'
							ref={textRef3}>
							<div className='FlexBox black'>{links}</div>
							<div className='FlexBox black'>{links}</div>
						</div>
					}
				/>
			</div>
		</SliderTextStyle>
	);
});

export default TextSlider;
