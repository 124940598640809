import styled from 'styled-components';

const OrderItemStyle = styled.div`
	.tableItemBott {
		--tableCollWidth: 50%;
		--tableItemPadTop: var(--sp2x);
		--tableItemPadLeft: var(--containerPadding);
		--productTableImgSize: 39%;

		&.cartItems {
			.tableItemBottTitles {
				border-bottom: 1px solid var(--greyLight);
			}

			.tableItemBotWrap {
				position: relative;

				&:after {
					content: '';
					position: absolute;
					left: calc(var(--containerPadding) * -1);
					right: calc(var(--containerPadding) * -1);
					bottom: 0;
					height: 1px;
					background-color: var(--greyLight);
				}

				.productTableImgWrap {
					padding: var(--tableItemPadTop) var(--tableItemPadLeft) var(--tableItemPadTop) 0;
					width: 22%;
				}

				.productTableInfo {
					padding: var(--tableItemPadTop) var(--tableItemPadLeft) 0 0;
				}

				.productInfoText {
					width: 90%;
					padding-bottom: calc(var(--tableItemPadTop) / 1.5);
				}

				&:first-child {
					&:before {
						content: '';
						position: absolute;
						left: calc(var(--containerPadding) * -1);
						right: calc(var(--containerPadding) * -1);
						top: 0;
						height: 1px;
						background-color: var(--greyLight);
					}
				}
			}

			.productQuantityMinMax {
				cursor: pointer;
				font-size: 8px;
			}

			.quantityNumb {
				padding: 0 var(--sp2x);
			}

			.icon-X {
				position: absolute;
				top: var(--tableItemPadTop);
				right: 0;
			}
		}

		&.orderItems {
			border-top: 1px solid var(--greyLight);

			.tableItemBotWrap {
				border-bottom: 1px solid var(--greyLight);

				&:last-child {
					border-bottom: 0;
				}
			}
		}

		.tableItemBotWrap {
			.grey3,
			.greyMid {
				margin-bottom: var(--sp1x);
			}

			.productTableImgWrap {
				padding: var(--tableItemPadTop) var(--tableItemPadLeft);
				width: var(--productTableImgSize);
				.productTableImg {
					padding-top: 100%;
				}
			}

			.productTableInfo {
				padding: var(--tableItemPadTop) var(--tableItemPadLeft);
				width: 61%;
			}

			.productInfoText {
				p {
					margin-right: var(--sp1x);
				}
			}
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
			--tableCollWidth: 25%;
			--tableItemPadLeft: var(--sp1x);
			--productTableImgSize: 60px;
		}

		@media only screen and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
			--tableItemPadTop: var(--sp3x);

			.tableCollBott {
				width: var(--tableCollWidth);

				&:first-child {
					width: calc(var(--tableCollWidth) * 2);
				}
			}

			&.orderItems {
				.tableItemBottTitles {
					.tableCollBott {
						padding: calc(var(--tableItemPadTop) / 2) var(--tableItemPadLeft) 0;
					}
				}
			}

			&.cartItems {
				.tableItemBottTitles {
					.tableCollBott {
						padding: 0 var(--tableItemPadLeft) calc(var(--tableItemPadTop) / 2);
					}
				}

				.tableItemBotWrap {
					.productTableImgWrap {
						width: var(--productTableImgSize);
						padding: 0;
					}

					.productInfoText {
						padding-bottom: 0;
					}

					&:after {
						left: 0;
						right: 0;
					}

					&:first-child {
						&:before {
							left: 0;
							right: 0;
						}
					}
				}

				.icon-X {
					cursor: pointer;
					top: 50%;
					right: var(--tableItemPadLeft);
					transform: translateY(-50%);
				}
			}

			.tableItemBottTitles {
				.tableCollBott {
					padding: calc(var(--tableItemPadTop) / 2) var(--tableItemPadLeft) 0;
				}
			}

			.tableItemBotWrap {
				.tableCollBott {
					padding: var(--tableItemPadTop) var(--tableItemPadLeft);
				}

				.productTableImgWrap {
					padding: 0;

					~ .productInfoText {
						padding-left: var(--tableItemPadTop);
					}
				}

				.productInfoText {
					max-width: calc(100% - var(--productTableImgSize));
				}

				.productTableInfo {
					padding: var(--tableItemPadTop) var(--tableItemPadLeft);
				}
			}
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.LaptopSize}) {
			--tableCollWidth: 25%;
			--tableItemPadLeft: var(--sp2x);
			--accountRightOtherWidth: 100%;
			--productTableImgSize: 60px;
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.LaptopSize}) {
			&.cartItems {
				.productQuantityMinMax {
					cursor: pointer;
					font-size: 10px;
				}
			}
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
			--tableCollWidth: 22%;
			--lastTableCollWidth: 12%;
			--tableItemPadLeft: var(--sp2x);
			--accountRightOtherWidth: 94%;
			--productTableImgSize: 70px;
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
			--tableCollWidth: 23%;
			--lastTableCollWidth: 8%;
			--tableItemPadLeft: var(--sp3x);
			--accountRightOtherWidth: 90%;
			--productTableImgSize: 80px;
		}

		@media only screen and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
			--tableCollWidth: 22%;
			--lastTableCollWidth: 10%;
			--tableItemPadLeft: var(--sp5x);
			--accountRightOtherWidth: 82%;
			--productTableImgSize: 90px;

			&.cartItems {
				.productQuantityMinMax {
					font-size: 12px;
				}

				.quantityNumb {
					padding: 0 var(--sp3x);
				}
			}
		}
	}
`;

export default OrderItemStyle;
