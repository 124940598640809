import styled from 'styled-components';

const ButtonStyle = styled.div.attrs((props) => ({ className: props.className }))`
	.btn {
		--maxWidthBntMid: 100%;

		-webkit-appearance: none;
		cursor: pointer;
		border: 2px solid var(--white);
		display: flex;
		align-items: center;
		justify-content: center;
		color: var(--white);
		width: 100%;
		line-height: var(--lineHeightS);
		background-color: var(--black);
		position: relative;
		transition: 0.2s;

		&.disabled {
			opacity: 0.5;
			pointer-events: none;
		}

		&.btn-mid {
			font-family: arm-bold;
			font-size: var(--h10);
			padding: var(--sp1-5x);
			max-width: var(--maxWidthBntMid);

			span {
				z-index: 2;
				color: var(--white);
				mix-blend-mode: difference;
			}
		}

		&.btn-max-width {
			max-width: 100%;
		}

		&.btn-doubleText {
			position: relative;
			overflow: hidden;

			.word {
				display: flex;

				.letter {
					min-width: calc(var(--h8) / 2);
				}

				&:last-child {
					position: absolute;
					z-index: 5;
					color: var(--black);
					.letter {
						transform: translateY(150%);
					}
				}
			}
		}

		&.btn-large {
			font-family: arm-bold;
			font-size: var(--h7);
			padding: var(--sp3x);
		}

		&.btn-sm {
			font-family: arm-regular;
			font-size: var(--h8);
			padding: var(--sp1-3x);
		}

		&.btn-xs {
			font-family: arm-regular;
			font-size: var(--p);
			padding: var(--sp1-5x);
		}

		&.btn-black {
			color: var(--white);
			background-color: var(--black);
			border: 2px solid var(--white);
		}

		&.btn-white {
			color: var(--black);
			background-color: var(--white);
			border: 2px solid var(--white);
		}

		&.btn-transparent {
			color: var(--white);
			background-color: var(--black);
			border: 2px solid var(--white);

			@media (hover: hover) {
				&:hover {
					color: var(--black);
					border-color: var(--white) !important;
					background-color: var(--white);

					&:before {
						width: 100%;
					}
				}
			}
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
			--maxWidthBntMid: 420px;

			&.btn-xs {
				padding: var(--sp2x);
			}
		}

		@media screen and (min-width: ${(props) => props.theme.mediaQuery.LaptopSize}) {
			&.btn-white {
				border: 2px solid var(--black);

				&:hover {
					border-color: var(--white) !important;
				}

				&:before {
					content: '';
					position: absolute;
					top: 0;
					left: 0;
					width: 0;
					height: 100%;
					background-color: var(--white);
					transition: 0.2s ease-out;
					z-index: 1;
					mix-blend-mode: difference;
				}
			}

			&:hover {
				border-color: var(--black) !important;
				background-color: var(--white);

				&:before {
					width: 100%;
				}
			}
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.MobileSize}) {
			&.btn-large {
				padding: var(--sp3x);
			}
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
			--maxWidthBntMid: 500px;

			&.btn-large {
				padding: var(--sp4x);
			}

			&.btn-xs {
				padding: var(--sp2x);
			}
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
			--maxWidthBntMid: 590px;

			&.btn-large {
				padding: var(--sp5x);
			}

			&.btn-mid {
				padding: var(--sp2x);
			}

			&.btn-xs {
				padding: var(--sp2x);
			}
		}

		@media only screen and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
			--maxWidthBntMid: 684px;

			&.btn-large {
				padding: var(--sp5-5x);
			}

			&.btn-mid {
				padding: var(--sp2x);
			}

			&.btn-xs {
				padding: var(--sp2x);
			}
		}
	}
`;

export default ButtonStyle;
