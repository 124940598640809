import React, { memo as Memo } from 'react';

const OvalTag = Memo((props) => {
	return (
		<div className={`oval-tag-cont tag-cont ${props.className || ''}`}>
			{props.children}

			{props.onRemove && (
				<span
					className='remove-tag'
					onClick={props.onRemove}>
					<i className='ma-close p-xs' />
				</span>
			)}
		</div>
	);
});

export default OvalTag;
