'use client';

import React, { memo as Memo, useState, useEffect, useCallback, Fragment, useMemo } from 'react';
import _ from 'lodash';

//* HOC's
import withLanguageContext from '../../../../../context/consumerHOC/LanguageConsumer';

//* Helpers
import formValidation from '../../../../../helpers/_formValidation';

//* Components
import Text from '@/components/common/Text';
import FieldErrorMsg from '../../../Error/FieldErrorMsg';

const Input = Memo((props) => {
	//! States
	const [val, setVal] = useState(props.value || '');
	const [type, setType] = useState(props.type === 'c_password' ? 'password' : props.type === 'phone' ? 'number' : props.type || 'text');
	const [errorMsg, setErrorMsg] = useState('');

	//! Field Value Change
	const onChange = (e) => {
		let newVal = type === 'number' && !e.target.validity.valid ? val : e.target.value;

		setVal(newVal);
		validate(newVal);
		props.onChange && props.onChange(props.name, newVal);
	};

	//! Field Validation
	const validate = useCallback(
		(nextVal) => {
			if (props.realTimeValidate && props.required) {
				const newVal = props.type === 'c_password' ? { pass: props.password, c_pass: nextVal } : nextVal,
					valid = formValidation(newVal, props.type, props.errorMsg, !_.isBoolean(props.required) && props.required);

				//* !_.isBoolean(valid) && _.isBoolean(props.parentError) ? setErrorMsg(valid) : (errorMsg && setErrorMsg(''))
				!_.isBoolean(valid) ? setErrorMsg(valid) : errorMsg && setErrorMsg('');
			}
		},
		[val, props.password, props.parentError]
	);

	//! Error Message
	useEffect(() => {
		setErrorMsg(props.errorMsg);
	}, [props.errorMsg]);

	//! Parent Component Reset
	useEffect(() => {
		if (props.parentVal !== val) {
			setVal(props.parentVal);
			setErrorMsg('');
		}
	}, [props.parentVal]);

	//! Checking Placeholder & Type
	const placeholder = useCallback(props.placeholder ? props.translate(props.placeholder) + (props.required ? '*' : '') : '', [props.selectedLang, props.placeholder, props.required]);

	//! Password Show/Hide Icon Click
	const passIconClick = () => {
		setType(type === 'password' ? 'text' : 'password');
	};

	//! Number Paramas For Other Browsers
	const numericParams = useMemo(
		() =>
			type === 'number' && {
				pattern: '[0-9]*',
				inputMode: 'numeric',
				min: 0,
				onInput: onChange,
			},
		[type]
	);

	const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

	return (
		<Fragment>
			<div className={` ${errorMsg ? 'error' : ''} ${props.className || ''} ${props.leftIcon ? 'with-left-icon' : ''}`}>
				<div className='labelWrap FlexBox spaceBetween alignMiddle'>
					<div className='FlexBox alignMiddle'>
						<Text
							tag={'label'}
							className='form-col-label pSm arm-regular'
							text={props.lable}
						/>
						{props.required && ' *'}
					</div>

					{props.type === 'password' && (
						<i
							className={`pass-icon pSm ${type === 'password' ? 'icon-Icon-awesome-eye-slash' : 'icon-Icon-awesome-eye'}`}
							onClick={passIconClick}
						/>
					)}
				</div>

				<input
					type={type}
					name={props.name || null}
					placeholder={placeholder}
					value={val || ''}
					onChange={onChange}
					onKeyDown={props.keyDown}
					autoComplete={props.autocomplete ? 'on' : isChrome ? 'chrome-off' : 'off'}
					{...numericParams}
				/>
			</div>

			<FieldErrorMsg msg={props.errorMsg || errorMsg || null} />
		</Fragment>
	);
});

export default withLanguageContext(Input, ['translate', 'selectedLang']);
