'use client';

import React, { memo as Memo, useEffect, useState } from 'react';
import { usePathname } from 'next/navigation';

//* HOC's
import { withLanguageContext } from '@/context';

//* Helpers
import stringToLetters from '@/helpers/stringToLetters';

//* Components
import CustomLink from '../../../CustomLink';
import MenuItemSvg from '../../menuItemSvg';

const Custom = Memo((props) => {
    const [letterifyText, setLetterifyText] = useState(false);

    const pathname = usePathname();

    let text = props.translate(props.text);

    useEffect(() => {
        setLetterifyText(stringToLetters(text));
    }, [text, pathname]);

    return (
        <CustomLink
            className={'Link' + props.className}
            url={props.path}
            content={
                <>
                    {letterifyText}
                    <span>
                        <MenuItemSvg />
                    </span>
                </>
            }
            nav={true}
            data={props.data}
        />
    );
});

export default withLanguageContext(Custom, ['translate']);
