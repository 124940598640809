'use client';

import { useRef, useState, useMemo } from 'react';
import { TimelineLite } from 'gsap';
import SwiperCore, { Scrollbar } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

//* HOC's
import { withUIContext } from '@/context';

//* Components
import Title from '../Title';
import Product from '../Product';
import Container from '../Container';
import TextSlider from '../TextSlider';

//* Style
import SliderStyle from './style';

function SampleNextArrow(props) {
	const { onClick } = props;

	return (
		<i
			className={'slick-arrow h5 slick-next icon-sliderArrRight'}
			onClick={onClick}
		/>
	);
}

function SamplePrevArrow(props) {
	const { onClick } = props;

	return (
		<i
			className={'slick-arrow h5 slick-prev icon-sliderArrLeft'}
			onClick={onClick}
		/>
	);
}

SwiperCore.use([Scrollbar]);

const GlobalSlider = (props) => {
	//! Refs
	const sliderRef = useRef();

	//! States
	const [sliderState, setSliderState] = useState();
	const [sliderActive, setSliderActive] = useState(true);

	const paddingTimeLine = useMemo(() => new TimelineLite({ ease: 'power3.out' }), []);

	//! Animation
	const slidePrev = () => {
		if (sliderActive) {
			if (sliderState.activeIndex !== 0) {
				sliderRef.current.swiper.slidePrev();
				setSliderActive(false);

				if (props.winWidth >= 1024) {
					let activeSlide = sliderState.activeIndex;
					let previousSlide = props.winWidth >= 1920 ? 4 : 3;
					let arr = [];

					sliderState.slides.forEach((item, ind) => {
						if (ind >= activeSlide && ind <= activeSlide + previousSlide) {
							arr.push(item);
							return arr;
						}
					});

					arr = arr.reverse();

					paddingTimeLine
						.to(arr, 0.4, {
							stagger: 0.01,
							marginLeft: '30px',
							marginRight: '30px',
							delay: -0.03,
						})
						.to(arr, 0.4, {
							stagger: 0.01,
							marginLeft: 0,
							marginRight: 0,
						});
				}
			}

			setTimeout(() => {
				setSliderActive(true);
			}, 1000);
		}
	};

	const slideNext = () => {
		if (sliderActive) {
			if (!sliderState.isEnd) {
				sliderRef.current.swiper.slideNext();
				setSliderActive(false);

				if (props.winWidth >= 768) {
					let activeSlide = sliderState.activeIndex;
					let previousSlide = props.winWidth >= 1920 ? 4 : 3;
					let arr = [];

					sliderState.slides.forEach((item, ind) => {
						if (ind >= activeSlide - previousSlide && ind <= activeSlide + previousSlide) {
							arr.push(item);
							return arr;
						}
					});

					paddingTimeLine
						.to(arr, 0.4, {
							stagger: 0.01,
							marginLeft: '30px',
							marginRight: '30px',
							delay: -0.03,
						})
						.to(arr, 0.4, {
							stagger: 0.01,
							marginLeft: '0px',
							marginRight: '0px',
						});
				}
			}

			setTimeout(() => {
				setSliderActive(true);
			}, 1000);
		}
	};

	return (
		<SliderStyle>
			<div className='overflowWrap'>
				<div className='HomeSliderCont'>
					<Container>
						<Title
							tag={'h2'}
							contClassName={'MostWantedTitle'}
							className={'arm-regular'}
							text={props.sliderTitle}
							uppercase={true}
						/>

						<div className='MostWantedCont'>
							<div className='MostWanted'>
								<SampleNextArrow onClick={slideNext} />
								<SamplePrevArrow onClick={slidePrev} />

								<Swiper
									speed={1000}
									ref={sliderRef}
									slidesPerView={4}
									slidesPerGroup={4}
									className='swiper-container'
									scrollbar={{ draggable: true }}
									breakpoints={{
										320: {
											slidesPerView: 2,
											slidesPerGroup: 2,
										},
										768: {
											slidesPerView: 2,
											slidesPerGroup: 2,
										},
										1024: {
											slidesPerView: 3,
											slidesPerGroup: 3,
										},
										1900: {
											slidesPerView: 4,
											slidesPerGroup: 4,
										},
									}}
									onSwiper={(swiper) => setSliderState(swiper)}>
									{props?.data?.map((item, ind) => {
										return (
											<SwiperSlide key={ind}>
												<Product
													tags={item.tags}
													name={item.name}
													price={item.price}
													productId={item.id}
													quantity={item.quantity}
													sale_price={item.sale_price}
													description={item.description}
													feature_image={item.feature_image}
													slug={`${item.category.slug}/${item.slug}`}
												/>
											</SwiperSlide>
										);
									})}
								</Swiper>
							</div>
						</div>
					</Container>

					<TextSlider />
				</div>
			</div>
		</SliderStyle>
	);
};

export default withUIContext(GlobalSlider, ['winWidth']);
