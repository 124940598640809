//* Style
import BurgerStyle from './style';

const Burger = (props) => {
	return (
		<BurgerStyle>
			<div
				className={`Burger ${props.checked ? 'active' : ''}`}
				onClick={props.onClick}>
				<span />
				<span />
			</div>
		</BurgerStyle>
	);
};

export default Burger;
