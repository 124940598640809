'use client';

import React, { memo as Memo, useEffect, useState } from 'react';

//* Components
import Text from '../Text';

//* Style
import CursorStyle from './style';

const isMobile = () => {
	const ua = navigator.userAgent;
	return /Android|Mobi/i.test(ua);
};

const SliderCursor = Memo(({}) => {
	// const [move, setMove] = useState({ x: -100, y: -100, cursor_class: "" });

	// var cursorStyle = {
	//     top: move.y,
	//     left: move.x,
	// };

	// const handleMove = e => {

	//     if (e.target.closest('.MostWanted') || e.target.closest('.MoreLikeProduct') || e.target.closest('.productImgWrap')) {
	//         setMove({ x: e.clientX, y: e.clientY, cursor_class: "active" })
	//     }
	//     else {
	//         setMove({ x: e.clientX, y: e.clientY, cursor_class: "" })
	//     }

	// };

	// useEffect(() => {
	//     window.addEventListener('mousemove', handleMove);
	//     return () => {
	//         window.removeEventListener('mousemove', handleMove);
	//     }
	// }, [])

	if (typeof navigator !== 'undefined' && isMobile()) return null;

	const [position, setPosition] = useState({ x: 0, y: 0 });
	const [clicked, setClicked] = useState(false);
	const [hidden, setHidden] = useState(false);
	const [active, setActive] = useState(false);

	useEffect(() => {
		addEventListeners();
		return () => removeEventListeners();
	}, []);

	const addEventListeners = () => {
		document.addEventListener('mousemove', onMouseMove);
		document.addEventListener('mouseenter', onMouseEnter);
		document.addEventListener('mouseleave', onMouseLeave);
		document.addEventListener('mousedown', onMouseDown);
		document.addEventListener('mouseup', onMouseUp);
	};

	const removeEventListeners = () => {
		document.removeEventListener('mousemove', onMouseMove);
		document.removeEventListener('mouseenter', onMouseEnter);
		document.removeEventListener('mouseleave', onMouseLeave);
		document.removeEventListener('mousedown', onMouseDown);
		document.removeEventListener('mouseup', onMouseUp);
	};

	const onMouseMove = (e) => {
		if (e.target.closest('.productImgWrap')) {
			setPosition({ x: e.clientX, y: e.clientY });
			setActive(true);
		} else {
			setPosition({ x: e.clientX, y: e.clientY });
			setActive(false);
		}
	};

	const onMouseDown = () => {
		setClicked(true);
	};

	const onMouseUp = () => {
		setClicked(false);
	};

	const onMouseLeave = () => {
		setHidden(true);
	};

	const onMouseEnter = () => {
		setHidden(false);
	};

	return (
		<CursorStyle
			style={{ left: `${position.x}px`, top: `${position.y}px` }}
			className={`${active ? 'active' : ''} ${clicked ? 'cursor-clicked' : ''}`}>
			<div className={`cursorDrag FlexBox alignCenter alignMiddle`}>
				<Text
					className={'arm-regular white'}
					text={'drag'}
				/>
			</div>
		</CursorStyle>
	);
});

export default SliderCursor;
