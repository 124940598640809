import React from 'react';

const stringToLetters = (text) => {
	let wordLength = text.split('');
	let arr = [];

	for (let i = 0; i < wordLength.length; i++) {
		arr.push(text.charAt(i));
	}

	return (
		<div className='word'>
			{arr.map((i, k) => {
				return (
					<span
						className='letter'
						key={k}>
						{i}
					</span>
				);
			})}
		</div>
	);
};

export default stringToLetters;
