'use client';

import { Fragment, useEffect, useState } from 'react';
import SwiperCore, { Scrollbar } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

//* HOC's
import { withUIContext, withCartContext } from '@/context';

//* Helpers
import { config } from '@/helpers';

//* Components
import Text from '../Text';
import Button from '../Button';
import CustomImage from '../Image';
import Translate from '../Translate';
import CustomLink from '../CustomLink';
import SliderCursor from '../SliderCursor';

//* Style
import ProductIndividualStyle from './style';

SwiperCore.use([Scrollbar]);

const ProductIndividual = ({ singleProd, addToCart, winWidth }) => {
	//! States
	const [qty, setQty] = useState(1);
	const [btnTextState, setBtnTextState] = useState(false);

	useEffect(() => {
		setTimeout(() => {
			setBtnTextState(false);
		}, 500);
	}, [btnTextState]);

	return singleProd ? (
		<ProductIndividualStyle>
			<div className='FlexBox alignMiddle backLink'>
				<CustomLink
					url={`${config.routes.products.path}/${singleProd.category.slug}`}
					className={'arm-regular p capitalize underline'}
					content={<Translate val={'backToShop'} />}
				/>
			</div>

			<div className='product-wrapper'>
				{winWidth >= 768 ? (
					<div className='product-image-wrapper'>
						{singleProd.photos.length > 1 ? (
							<>
								<SliderCursor />

								<Swiper slidesPerView={'auto'}>
									{singleProd.photos.map((item, ind) => {
										return (
											<SwiperSlide key={ind}>
												<CustomImage
													src={item.src}
													alt={singleProd.name}
												/>
											</SwiperSlide>
										);
									})}
								</Swiper>
							</>
						) : (
							<CustomImage
								src={singleProd.feature_image.src}
								alt={singleProd.name}
							/>
						)}

						{singleProd.tags.length > 0 ? (
							<CustomImage
								src={singleProd.tags[0].image.src}
								alt={singleProd.tags[0].name}
								className={'product-tag'}
							/>
						) : null}
					</div>
				) : (
					<div className='product-image-wrapper'>
						{singleProd.photos.length > 1 ? (
							<Swiper slidesPerView={'auto'}>
								{singleProd.photos.map((item, ind) => {
									return (
										<SwiperSlide key={ind}>
											<CustomImage
												src={item}
												alt={singleProd.name}
											/>
										</SwiperSlide>
									);
								})}
							</Swiper>
						) : (
							<CustomImage
								src={singleProd.feature_image.src}
								alt={singleProd.name}
							/>
						)}

						{singleProd.tags.length > 0 ? (
							<CustomImage
								src={singleProd.tags[0].image?.src}
								alt={singleProd.tags[0].name}
								className={'product-tag'}
							/>
						) : null}
					</div>
				)}

				<div className='product-individual-info'>
					<div className='product-individual-info-wrap'>
						<Text
							tag={'div'}
							className={'greyLight subtitle h10 arm-regular'}>
							{singleProd.category.name}
						</Text>

						<Text
							tag={'h1'}
							className={'title h6 arm-regular'}>
							{singleProd.name}
						</Text>

						<Text
							tag={'div'}
							className={'greyLight description h10 arm-regular'}>
							{singleProd.description}
						</Text>

						<div className='product-individual-ingredient'>
							<div className='pi-q-block'>
								<span className='greyLight h10 arm-regular'>
									{singleProd.quantity}
									&nbsp;
								</span>

								<Text className={'greyLight h10 arm-regular'}>{singleProd.type}</Text>
							</div>

							{singleProd.attributes?.length > 0 ? (
								<div className='attr-wrapper'>
									{singleProd.attributes.map((item, ind) => {
										return (
											<CustomImage
												key={ind}
												alt={item.name}
												src={item.image?.src}
											/>
										);
									})}
								</div>
							) : null}
						</div>
					</div>

					<div>
						<div className='product-price-wrapper'>
							<div className='pp-top-wrapper'>
								{singleProd.sale_price ? (
									<Fragment>
										<Text
											tag={'div'}
											className={'h6 arm-regular relative-block'}
											children={
												<Fragment>
													<i className='icon-currency' />
													{singleProd.sale_price}
												</Fragment>
											}
										/>

										<Text
											tag={'div'}
											className={'h6 arm-regular relative-block grey sale-line'}
											children={
												<Fragment>
													<i className='icon-currency' />
													{singleProd.price}
												</Fragment>
											}
										/>
									</Fragment>
								) : (
									<Text
										tag={'div'}
										className={'h6 arm-regular relative-block'}
										text={
											<Fragment>
												<i className='icon-currency' />
												{singleProd.price}
											</Fragment>
										}
									/>
								)}
							</div>

							<div className='product-quantity-wrapper'>
								<div
									className='pq-min pSm'
									onClick={() => setQty((prevState) => (prevState > 1 ? qty - 1 : qty))}>
									<i className='icon-minus' />
								</div>

								<div className='product-quantity'>
									<Text
										tag={'div'}
										className={'h8 arm-regular'}>
										{qty}
									</Text>
								</div>

								<div
									className='pq-max pSm'
									onClick={() => setQty((prevState) => (prevState + 1 <= 99 ? qty + 1 : qty))}>
									<i className='icon-plus' />
								</div>
							</div>
						</div>

						<div className='btn-wrapper'>
							<Button
								className={'btn btn-sm btn-transparent'}
								text={btnTextState ? 'Added' : 'AddToCart'}
								onClick={() => {
									addToCart(singleProd.id, qty, singleProd.sale_price || singleProd.price);
									setBtnTextState(true);
								}}
							/>
						</div>
					</div>
				</div>
			</div>
		</ProductIndividualStyle>
	) : null;
};

export default withUIContext(withCartContext(ProductIndividual, ['addToCart']), ['winWidth']);
