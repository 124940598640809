'use client';

import React, { memo as Memo, Fragment, useState, useEffect, useCallback, useMemo } from 'react';
import _ from 'lodash';

//* Helpers
import formValidation from '../../../../../helpers/_formValidation';

//* Components
import DefaultCheckbox from './DefaultCheckbox';
import TagCheckbox from './TagCheckbox';
import Switch from './Switch';
import Translate from '../../../Translate';

const Checkbox = Memo((props) => {
	//! States
	const [sortBy, setSortBy] = useState(false);
	const [seeAll, setSeeAll] = useState(!!props.seeAll);
	const [errorMsg, setErrorMsg] = useState('');

	//! Initial State
	const initialState = useCallback(() => {
		let items = props.list,
			length = items.length,
			obj = {};

		for (let i = 0; i < length; i++) {
			obj[items[i].value] = {
				label: items[i].label,
				checked: !!items[i].checked || false,
			};
		}

		return obj;
	}, [sortBy]);

	const initialEmptyState = useCallback(() => {
		let items = props.list,
			length = items.length,
			obj = {};

		for (let i = 0; i < length; i++) {
			obj[items[i].value] = {
				label: items[i].label,
				checked: false,
			};
		}

		return obj;
	}, [sortBy]);

	//! Items State
	const [items, setItems] = useState(initialState());

	//! Getting Checked Values
	const getCheckedVals = useCallback(
		(items) => {
			return Object.keys(items).filter((i) => {
				return items[i].checked && i;
			});
		},
		[items]
	);

	//! Field Value Change
	const onChange = (val) => {
		const oldItems = props.inputType === 'radio' ? initialEmptyState() : items;
		let newItems = { ...oldItems, [val]: { ...oldItems[val], checked: !!!oldItems[val].checked } },
			vals = getCheckedVals(newItems);

		validate(vals);
		setItems(newItems);
	};

	//! Calling Parent On Change
	useEffect(() => {
		let vals = getCheckedVals(items);

		props.onChange && props.onChange(props.name, vals);
	}, [items]);

	//! Validate
	const validate = useCallback(
		(vals) => {
			if (props.realTimeValidate && props.required) {
				const valid = formValidation(vals, 'checkbox', props.errorMsg, !_.isBoolean(props.required) && props.required);
				!_.isBoolean(valid) && _.isBoolean(props.parentError) ? setErrorMsg(valid) : errorMsg && setErrorMsg('');
			}
		},
		[props.realTimeValidate, props.required, props.errorMsg, props.parentError, errorMsg]
	);

	//! Construct Checkboxes
	const elements = useCallback(() => {
		let ComponentStyle = props.style === 'tag' ? TagCheckbox : props.style === 'switch' ? Switch : DefaultCheckbox;

		const keys = Object.keys(items);
		let itemsInArray = keys.map((k) => {
			return { ...items[k], value: k };
		});

		//* Sorting Alphabetical
		if (sortBy) {
			itemsInArray = _.orderBy(itemsInArray, [(item) => item.label.toLowerCase()], ['asc']);
		}

		if (props.seeAll && seeAll && itemsInArray.length > props.seeAll) {
			itemsInArray = _.slice(itemsInArray, 0, props.seeAll);
		}

		return itemsInArray.map((el) => {
			return (
				<ComponentStyle
					inputType={props.inputType}
					name={props.name}
					key={el.value}
					label={el.label}
					icon={props.icon}
					value={el.value}
					whithLink={props.whithLink}
					checked={el.checked}
					onChange={(val) => onChange(val)}
				/>
			);
		});
	}, [items, props.style, sortBy, seeAll, props.seeAll]);

	//! Construct Filter
	const sortByHTML = useMemo(() => {
		if (props.sortBy) {
			return (
				<div className='checkbox-sort-cont'>
					<span className='sort-title'>
						<Translate val='sortBy' />
					</span>

					<div className='sort-menu-cont'>
						<span
							className={`sort-menu-item${sortBy ? ' font-bold' : ''}`}
							onClick={() => setSortBy(true)}>
							<Translate val='alphabetical' />
						</span>
						<span
							className={`sort-menu-item${!sortBy ? ' font-bold' : ''}`}
							onClick={() => setSortBy(false)}>
							<Translate val='popular' />
						</span>
					</div>
				</div>
			);
		}
	}, [sortBy, props.sortBy]);

	//! Checking is has props isOpen for seeAll state
	useEffect(() => {
		props.isOpened && !seeAll && setSeeAll(!seeAll);
	}, [props.isOpened]);

	//! Parent Component Reset
	useEffect(() => {
		let vals = getCheckedVals(items);
		if (props.parentVal !== vals && typeof props.parentVal !== 'object') {
			setItems(initialState);
			setErrorMsg('');
		}
	}, [props.parentVal]);

	return (
		<Fragment>
			{sortByHTML}

			<div className={`checkbox-cont ${props.className || ''} ${props.style || 'default'}-checkbox-cont ${errorMsg || props.errorMsg ? 'error' : ''}`}>
				{elements()}

				{props.seeAll && seeAll && props.list.length > props.seeAll && (
					<span
						className='see-all-btn'
						onClick={() => setSeeAll(!seeAll)}>
						<Translate val='seeAll' />
					</span>
				)}
			</div>
		</Fragment>
	);
});

export default Checkbox;
