'use client';

import React, { memo as useLayoutEffect, useRef, useState, useCallback } from 'react';

//* HOC's
import { withAccountContext, withCartContext } from '@/context';

//* Components
import Text from '../../Text';
import CustomLink from '../../CustomLink';

const AddressItem = ({ deleteAddress, setDefaultAddress, getCart, ...props }) => {
	const ref = useRef();
	const [activeClass, setActiveClass] = useState('');

	useLayoutEffect(() => {
		setTimeout(() => {
			if (ref?.current?.getBoundingClientRect().y < window.innerHeight) {
				setActiveClass('active');
			}
		}, 300);

		window.addEventListener('scroll', scroll);

		return () => {
			window.removeEventListener('scroll', scroll);
		};
	});

	const scroll = useCallback(() => {
		if (ref?.current?.getBoundingClientRect().y + ref?.current?.clientHeight < window.innerHeight * 0.7) {
			setActiveClass('active');
		}
	}, []);

	const setDefault = (id) => {
		setDefaultAddress(id).then(() => {
			getCart();
		});
	};

	return (
		<div
			ref={ref}
			className={`addressItem FlexBoxColumn spaceBetween ${activeClass} ${props.addressCheck ? 'addressCheck' : ''}`}>
			<div
				className={`addressDescription  ${props.addressCheck ? 'cursorPointer' : ''}`}
				onClick={props.onClick}>
				{props.addressCheck ? <div className={`switch ${props.checked === props.index || (!props.checked && props.data.is_default) ? 'checked' : ''}`} /> : ''}
				<Text
					tag={'div'}
					className={'h10 black arm-bold'}
					text={`${props.data.first_name}  ${props.data.last_name}`}
				/>
				<Text
					tag={'div'}
					className={'h10 black arm-regular'}
					text={`${props.data.street_name}  ${props.data.apartment}`}
				/>
				<Text
					tag={'div'}
					className={'h10 black arm-regular'}
					text={props.data.city.name}
				/>
				<Text
					tag={'div'}
					className={'h10 black arm-regular'}
					text={props.data.phone}
				/>
			</div>

			<div className='adresOtherFunc'>
				{props.addressCheck ? (
					<div
						className='adresOtherFuncBtn FlexBox'
						onClick={props.edit}>
						<Text
							tag={'span'}
							className={' black underline pSm arm-regular cursorPointer'}
							text={'Edit'}
						/>
					</div>
				) : props.checkoutAdresses ? (
					<div className='adresOtherFuncBtn FlexBox'>
						<Text
							tag={'span'}
							className={' black underline pSm arm-regular cursorPointer'}
							text={'Edit'}
						/>
					</div>
				) : (
					<>
						<div className='adresOtherFuncBtn FlexBox'>
							<div className={props.data.is_default ? 'default' : ''}>
								<CustomLink
									url={`/account/addresses/${props.data.id}`}
									content={
										<Text
											tag={'span'}
											className={' black underline pSm arm-regular'}
											text={'Edit'}
										/>
									}
								/>
							</div>
							{!props.data.is_default && (
								<div>
									<Text
										attr={{
											onClick: () => deleteAddress(props.data.id),
										}}
										tag={'span'}
										className={' black underline pSm arm-regular cursorPointer'}
										text={'Remove'}
									/>
								</div>
							)}
						</div>
						<div className='Default'>
							<Text
								attr={{
									onClick: () => props.data.is_default !== 1 && setDefault(props.data.id),
								}}
								tag={'span'}
								className={' black underline pSm arm-regular cursorPointer'}
								text={props.data.is_default !== 1 ? 'SetDefault' : 'Default'}
							/>
						</div>
					</>
				)}
			</div>
		</div>
	);
};

export default withAccountContext(withCartContext(AddressItem, ['getCart']), ['deleteAddress', 'setDefaultAddress']);
