import React, { memo as Memo } from 'react';

//* Components
import OvalTag from './OvalTag';
import DefaultTag from './DefaultTag';

const Tag = Memo((props) => {
	const Element = props.type === 'oval' ? OvalTag : DefaultTag;

	return <Element {...props} />;
});

export default Tag;
