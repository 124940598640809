import styled from 'styled-components';

const FormStyle = styled.div.attrs((props) => ({ className: props.className }))`
	--formTitleMarg: var(--sp4x);
	--formOtherLinkWrapMarg: var(--sp3x);
	--loginOtherWeyTitleMargBott: var(--sp3x);
	--loginOtherWeyMarg: var(--sp5x);
	--ForgotPasswordWrapMargTop: var(--sp2x);
	--ForgotPasswordWrapMargTop2: var(--sp6x);
	--formColMargBott: var(--sp3x);
	--formOtherLinkMarg: var(--sp1x);
	--checkboxIconSize: var(--pSm);
	--inputPad: var(--sp1-5x);

	--formItemTitle: var(--sp3x);
	--accountName: var(--sp4x);
	--sideBarItem: var(--sp2x);
	--signOut: var(--sp10x);
	--uploadedImage: 60px;
	--accountFormItemsMargBott: var(--sp5x);
	--accountItemsPadTop: var(--sp4x);
	--accountItemsPadBot: var(--sp4x);
	--accountItemsLeftPad: var(--containerPadding);
	--accountItemsRightPad: var(--containerPadding);

	--tableColumnWidth: 50%;
	--tableItemPadTop: var(--sp2x);
	--tabItemPadLeft: var(--accountItemsRightPad);

	--noOrderTextMargBott: var(--sp2x);
	--noOrderBtnMargTop: var(--sp5x);
	--noOrderWidth: 620px;

	.form-container {
		position: relative;

		input::-webkit-outer-spin-button,
		input::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}

		input[type='number'] {
			-moz-appearance: textfield;
		}

		.errorCont {
			color: var(--errorColor);
		}

		&.blackForm {
			input:-webkit-autofill,
			input:-webkit-autofill:hover,
			input:-webkit-autofill:focus,
			textarea:-webkit-autofill,
			textarea:-webkit-autofill:hover,
			textarea:-webkit-autofill:focus,
			select:-webkit-autofill,
			select:-webkit-autofill:hover,
			select:-webkit-autofill:focus {
				-webkit-text-fill-color: var(--black);
				-webkit-box-shadow: 0 0 0px 1000px #000 inset;
				transition: background-color 5000s ease-in-out 0s;
			}

			.form-col {
				.form-col-label,
				.MuiCollapse-wrapper,
				.pass-icon,
				.labelWrap {
					color: var(--black);
				}

				.formItems {
					input {
						border: 1px solid var(--black);
						color: var(--black) !important;
					}

					&.error {
						margin-bottom: var(--sp1x);

						input {
							border: 1px solid var(--errorColor) !important;
						}
					}
				}

				.select-cont {
					border: 1px solid var(--black);
					color: var(--black);

					.MuiCollapse-wrapper {
						background-color: var(--white);
						border: 1px solid var(--black);
						color: var(--black);
					}
				}
			}

			.checkbox-cont {
				.switch-cont {
					.switch {
						border: 1px solid var(--black);

						&:after {
							background-color: var(--black);
						}
					}

					.switch-name,
					.switch-link {
						color: var(--black);
					}
				}
			}
		}

		&.whiteForm {
			.form-col {
				.form-col-label,
				.MuiCollapse-wrapper,
				.labelWrap {
					color: var(--greyMid) !important;
				}

				.pass-icon {
					color: var(--white);
				}

				.formItems {
					input {
						border: 1px solid var(--white);
						color: var(--white) !important;
					}

					&.error {
						margin-bottom: var(--sp1x);

						input {
							border: 1px solid var(--errorColor) !important;
						}
					}
				}

				.select-cont {
					border: 1px solid var(--white);
					color: var(--white);

					.MuiCollapse-wrapper {
						background-color: var(--black);
						border: 1px solid var(--white);
						color: var(--white);
					}
				}
			}

			.checkbox-cont {
				position: absolute;
				left: 0;
				top: calc(100% + var(--ForgotPasswordWrapMargTop));

				.switch-cont {
					.switch {
						border: 1px solid var(--greyMid);

						&:after {
							background-color: var(--greyMid);
						}
					}

					.switch-name,
					.switch-link {
						color: var(--greyMid);
					}
				}
			}
		}

		.form-col {
			margin-bottom: var(--formColMargBott);

			.labelWrap {
				margin-bottom: 3px;
			}

			.formItems {
				-webkit-appearance: none;
				-webkit-border-radius: 0;

				input {
					border-radius: 0px;
					line-height: var(--lineHeightS);
					background-color: transparent;
					width: 100%;
					padding: var(--inputPad);
					font-size: var(--h10);

					@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
						font-size: var(--sp2x) !important;
					}
				}
			}

			.select-cont {
				width: 100%;
				padding: var(--inputPad);
				position: relative;
				z-index: 5;

				.selected-val,
				select {
					cursor: pointer;
					font-family: arm-regular;
					font-size: var(--h10);
					line-height: var(--lineHeightS);
					position: relative;
					@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
						font-size: var(--sp2x) !important;
					}
				}
				select {
					width: 100%;
					border: none;
					background-color: transparent;
				}
				i {
					font-size: 10px;
					position: absolute;
					top: 50%;
					right: 0;
					transform: translateY(-50%);
				}

				.list-items {
					position: absolute;
					top: 110%;
					right: 0;
					left: 0;

					.MuiCollapse-wrapper {
						padding: var(--inputPad);

						.select-list-item {
							cursor: pointer;
							display: block;
							font-family: arm-regular;
							font-size: var(--h10);
							margin-bottom: var(--sp1x);

							&:last-child {
								margin-bottom: 0;
							}
						}
					}
				}
			}

			.uploader-cont {
				.uploader-input {
					cursor: pointer;
				}

				.uploaded-image {
					position: relative;
					width: var(--uploadedImage);
					height: var(--uploadedImage);
					border-radius: 100px;
					border: 1px solid var(--black);
					overflow: hidden;

					.uploader-content {
						img {
							position: absolute;
							top: 0;
							left: 0;
							object-fit: cover;
							object-position: center;
							width: 100%;
							height: 100%;
							z-index: 1;
						}
					}

					i {
						color: var(--black);
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate3d(-50%, -50%, 0);
					}
				}

				.input-info {
					padding-left: var(--sp2x);
				}
			}
		}

		.checkbox-cont {
			.switch-cont {
				.switch {
					position: relative;
					border-radius: 100px;
					width: var(--checkboxIconSize);
					height: var(--checkboxIconSize);
					margin-right: var(--sp1x);

					input {
						opacity: 0;
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
					}

					&.switchLink {
						&:after {
							transform: translate3d(-50%, -75%, 0) rotate(45deg);
							width: 25%;
							height: 40%;
							border-radius: 0;
							background: transparent;
							border-bottom: 1px solid;
							border-right: 1px solid;
						}
					}

					&:after {
						content: '';
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate3d(-50%, -50%, 0);
						width: 60%;
						height: 60%;
						border-radius: 100px;
						transition: all var(--trTime) ease-out;
						opacity: 0;
					}
				}

				label {
					cursor: pointer;
				}

				&.checked {
					.switch {
						&:after {
							opacity: 1;
						}
					}
				}

				.switch-name,
				.switch-link {
					font-family: arm-regular;
					font-size: var(--pSm);
				}
			}

			&.error {
				.switch-name,
				.switch-link {
					color: var(--errorColor) !important;
				}

				.switch-cont .switch {
					border: 1px solid var(--errorColor) !important;
				}
			}
		}
	}

	&.accountContainer {
		.accountBackImage {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: -1;
			opacity: 0.2;
			overflow: hidden;

			.image {
				object-position: top right;
				height: auto;
			}
		}

		.accountLeftSide {
			padding: var(--sp13x) var(--accountItemsLeftPad) var(--accountItemsPadBot);

			.accountName {
				flex-wrap: wrap;
				margin-bottom: var(--accountName);
			}

			.sideBarItem {
				margin-bottom: var(--sideBarItem);
				position: relative;

				a {
					color: var(--white);
					overflow: hidden;
					background: linear-gradient(to right, var(--grey), var(--grey) 50%, var(--white) 50%);
					background-clip: text;
					-webkit-background-clip: text;
					-webkit-text-fill-color: transparent;
					background-size: 200% 100%;
					background-position: 100%;
					transition: background-position var(--trTime) ease;
					text-decoration: none;

					@media screen and (min-width: 1200px) {
						&:hover {
							background-position: 0 100%;
						}
					}
				}

				@media screen and (max-width: 480px) {
					margin-left: 20px;
				}

				&.active {
					&:before {
						content: '';
						position: absolute;
						top: 50%;
						transform: translateY(-50%);
						left: -20px;
						width: 8px;
						height: 8px;
						background-color: #fff;
						border-radius: 50%;
					}
				}
			}

			.signOut {
				margin-top: var(--signOut);

				&:hover {
					cursor: pointer;
				}
			}
		}

		.loadMore {
			cursor: pointer;
			margin-top: var(--tableItemPadTop);
		}

		.accountRightSide {
			min-height: 50vh;
			background-color: var(--white);
			padding: var(--accountItemsPadTop) var(--accountItemsRightPad);

			.formItemTitle {
				margin-bottom: var(--formItemTitle);
			}

			.profileForm {
				margin-top: var(--accountFormItemsMargBott);
			}

			.passwordForm {
				padding-top: var(--accountFormItemsMargBott);
			}

			.PasswordPop {
				margin-top: calc(var(--accountFormItemsMargBott) / 2);

				.comp-title {
					cursor: pointer;
				}
			}

			.AccountSuccess,
			.PasswordSuccess {
				padding-top: calc(var(--accountFormItemsMargBott) / 2.5);
			}

			> div {
				&:not(.accountLoader) {
					opacity: 0;
					transform: translateY(10vh);
				}
			}
		}
	}

	&.formContainer {
		max-width: calc(100% + (var(--containerPadding) * 2));
		padding-left: var(--containerPadding);
		padding-right: var(--containerPadding);
		margin-right: auto;
		margin-left: auto;
		width: 100%;
		margin-top: var(--marginTop);
		margin-bottom: var(--marginBottom);

		.formLeft {
			.formTitle {
				margin-bottom: var(--formTitleMarg);
			}
		}

		.formRight {
			.formOtherLinkWrap {
				margin-bottom: var(--formOtherLinkWrapMarg);

				.greyMid {
					margin-right: var(--formOtherLinkMarg);
				}
			}

			.ForgotPasswordWrap {
				margin-top: var(--ForgotPasswordWrapMargTop2);
			}

			.loginOtherWey {
				margin-top: var(--loginOtherWeyMarg);

				.loginOtherWeyTitle {
					margin-bottom: var(--loginOtherWeyTitleMargBott);
				}

				.loginOtherWeyBtn {
					> div {
						width: calc(50% - var(--formOtherLinkMarg));

						i {
							font-size: var(--h7);
							margin-right: var(--sp1x);
						}
					}
				}
			}
		}

		.mailTitle {
			margin-bottom: calc(var(--inputPad) * 1.5);
		}
	}

	&.addressModal {
		.formItemTitle {
			margin-bottom: var(--formItemTitle);
		}

		.profileForm {
			margin-top: var(--accountFormItemsMargBott);
		}

		.PasswordPop {
			cursor: pointer;
			margin-top: calc(var(--accountFormItemsMargBott) / 2);
		}
	}

	.statusCont {
		.statusTopSection {
			margin-bottom: calc(var(--accountFormItemsMargBott) * 1.5);
		}

		.cartRight,
		.cartLeft {
			flex: 0 0 100%;
		}

		.cartRight {
			padding: calc(var(--tableItemPadTop) * 2) 0;

			.muraCart {
				height: var(--sp30x);
				max-width: var(--sp59x);
				width: 100%;
				border-radius: 20px;
				background-color: var(--black);
				padding: var(--formItemTitle);
				position: relative;
				overflow: hidden;

				.pSm {
					margin-bottom: var(--sp1x);
				}

				.h5 {
					line-height: var(--lineHeight);
				}

				.imageCont {
					position: absolute;
					top: 0;
					bottom: 0;
					left: 0;
					width: 100%;
					z-index: 0;
				}

				.userInfo {
					text-align: right;
				}

				.userIdCode {
					text-align: right;

					> p:first-child {
						margin-bottom: var(--sp1x);
					}
				}
			}
		}

		.cartLeft {
			.muraCartTitle {
				padding-bottom: var(--tableItemPadTop);
			}

			.statusNameWrap {
				padding: var(--tableItemPadTop) 0;
				border-top: 1px solid var(--greyLight);
				border-bottom: 1px solid var(--greyLight);

				.muraCartStatus {
					margin-bottom: var(--sp2x);
				}
			}
		}

		.cashbackStatusWrap {
			margin-top: var(--accountFormItemsMargBott);
			overflow: hidden;
			width: 100%;

			.cashbackStatus {
				margin-bottom: var(--tableItemPadTop);

				.tableOverflow {
					> .FlexBox {
						margin-bottom: calc(var(--tableItemPadTop) * 2);
						position: relative;

						&:after {
							content: '';
							position: absolute;
							z-index: 1;
							bottom: 0;
							right: 0;
							left: 0;
							height: 1px;
							width: 100%;
							background-color: var(--black);
						}
					}

					.progresBar {
						height: 2px;
						background-color: var(--borderColor2);
						position: absolute;
						bottom: 0;
						left: 0;
						z-index: 11;
						min-width: 0;
						&.active {
							width: 100% !important;
							&:after {
								height: 0;
							}
						}

						&:after {
							content: '';
							position: absolute;
							top: 100%;
							right: 0;
							height: var(--sp2x);
							width: 2px;
							background-color: var(--borderColor2);
							transform: translateY(-50%);
						}
					}

					.cashbackStatusItemWrap {
						padding-bottom: calc(var(--tableItemPadTop) * 2);
						position: relative;
						&:nth-child(2) {
							width: 10%;
						}
						&:nth-child(3) {
							width: 40%;
						}
						&:nth-child(4) {
							width: 50%;
						}
						&:nth-child(5) {
							width: 30%;
						}

						.comp-title {
							line-height: var(--lineHeightS);
							margin-top: var(--sp1x);
							display: inline-block;
							width: 100%;
						}

						&.active {
							opacity: 1;

							& ~ div {
								opacity: 0.5;
								.progresBar {
									width: 0% !important;
									&:after {
										height: 0;
									}
								}
							}
						}

						&:nth-child(5) {
							position: absolute;
							bottom: 0;
							right: 0;
							text-align: right;
							display: flex;
							flex-direction: column;
							align-items: flex-end;

							&:after {
								right: 0;
								left: inherit;
							}
						}

						&:after {
							content: '';
							position: absolute;
							top: 100%;
							left: 0;
							height: var(--sp3-5x);
							width: 1px;
							background-color: var(--black);
							transform: translateY(-50%);
						}
					}
				}
			}

			.statusInfoBlock {
				margin-bottom: var(--accountFormItemsMargBott);
				margin-top: var(--accountFormItemsMargBott);
				-webkit-text-size-adjust: none;
				> div {
					width: 100%;
					margin-top: var(--tableItemPadTop);
				}
			}

			.statusLink {
				margin-top: var(--accountFormItemsMargBott);
			}
		}

		.tableOverflowWrap {
			overflow: auto;

			&::-webkit-scrollbar {
				height: 1px;
				display: block;
			}

			&::-webkit-scrollbar-track {
				background: var(--greyLight);
			}

			&::-webkit-scrollbar-thumb {
				background: var(--black);
			}

			&::-webkit-scrollbar-thumb:hover {
				background: var(--black);
			}

			.tableOverflow {
				min-width: 860px;
			}
		}

		.tableWrap {
			.tableTop {
				.tableColl {
					padding: 0 var(--tabItemPadLeft) var(--tabItemPadLeft);
					width: 27.5%;

					&:last-child {
						width: 17%;
						text-align: right;
					}
				}
			}

			.tableItem {
				border-top: 1px solid var(--greyLight);

				.tableItemTop {
					.tableColl {
						padding: var(--tableItemPadTop) var(--tabItemPadLeft);
						width: 27.5%;

						&.red {
							color: var(--errorColor);
						}

						&.green {
							color: #0b9300;
						}

						&:last-child {
							width: 17%;
							text-align: right;
						}
					}
				}
			}
		}
	}

	.orderCont {
		.tableWrap {
			.tableItem {
				border-top: 1px solid var(--borderDark);

				.tableItemTop {
					cursor: pointer;
					background-color: var(--greyExLight);

					.tableColl {
						padding: var(--tableItemPadTop) var(--tabItemPadLeft);
						width: var(--tableColumnWidth);
					}

					.popDownBtn {
						.btn {
							border-color: var(--greyExLight);
						}

						&.active {
							i {
								transform: rotate(180deg);
							}
						}

						i {
							transition: all var(--trTime) ease;
							font-size: 6px;
							padding: calc(var(--tableItemPadTop) / 2) var(--tabItemPadLeft);
						}
					}
				}

				&.tableMob {
					margin: 0 calc(-1 * var(--tabItemPadLeft));

					.popDownBtn {
						padding: 0 var(--tabItemPadLeft) var(--tableItemPadTop);
						width: 100%;
					}
				}

				&:last-child {
					border-bottom: 1px solid var(--borderDark);
				}
			}
		}

		.noOrder {
			.formItemText {
				margin-bottom: var(--noOrderTextMargBott);
			}

			.btn {
				max-width: var(--noOrderWidth);
				margin-top: var(--noOrderBtnMargTop);
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
		--formTitleMarg: var(--sp6x);
		--formOtherLinkWrapMarg: var(--sp5x);
		--loginOtherWeyTitleMargBott: var(--sp4x);
		--loginOtherWeyMarg: var(--sp6x);
		--ForgotPasswordWrapMargTop: var(--sp3x);
		--formColMargBott: var(--sp4x);
		--formOtherLinkMarg: var(--sp1-3x);
		--formLeft: 100%;
		--formRight: 75%;

		--accountItemsPadTop: var(--sp8x);
		--accountItemsPadBot: var(--sp5x);

		--tableColumnWidth: 25%;
		--tableItemPadTop: var(--sp3x);
		--tabItemPadLeft: var(--sp1x);

		&.accountContainer {
			.accountLeftSide {
				padding: var(--sp14x) var(--accountItemsLeftPad) var(--accountItemsPadBot);
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
		.orderCont {
			.tableWrap {
				.tableItem {
					.tableItemTop {
						.btn {
							margin-top: calc(var(--tableItemPadTop) / 2);
						}
					}
				}
			}
		}
	}

	@media only screen and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		--ForgotPasswordWrapMargTop2: var(--ForgotPasswordWrapMargTop);

		.form-container {
			.columnWrap {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;

				.form-col {
					width: calc(50% - var(--formOtherLinkMarg));
					.uploader-cont {
						.input-info {
							padding-left: var(--sp3x);
						}
					}
				}
			}
		}

		&.addressModal {
			.addressForm {
				width: 100%;
			}
		}

		&.formContainer {
			display: flex;
			flex-wrap: wrap;
			justify-content: flex-end;

			.formLeft {
				width: var(--formLeft);
			}

			.formRight {
				width: var(--formRight);

				.ForgotPasswordWrap {
					display: flex;
					justify-content: flex-end;
				}
			}
		}

		.orderCont {
			.tableWrap {
				.tableTop {
					.tableColl {
						padding: 0 var(--tabItemPadLeft) calc(var(--tableItemPadTop) / 2);
						width: var(--tableColumnWidth);
					}
				}
			}
		}

		.statusCont {
			.cartRight {
				.muraCart {
					height: var(--sp32x);
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.LaptopSize}) {
		--formTitleMarg: var(--sp6x);
		--formOtherLinkWrapMarg: var(--sp6x);
		--loginOtherWeyTitleMargBott: var(--sp4x);
		--loginOtherWeyMarg: var(--sp6x);
		--ForgotPasswordWrapMargTop: var(--sp2x);
		--formColMargBott: var(--sp4x);

		--accountName: var(--sp5x);
		--sideBarItem: var(--sp2x);
		--uploadedImage: 65px;
		--accountFormItemsMargBott: var(--sp8x);
		--accountLeftRightWidth: 28%;
		--accountItemsPadTop: var(--sp17x);
		--accountItemsPadBot: var(--sp5x);
		--accountItemsLeftPad: var(--sp7x);
		--accountItemsRightPad: var(--sp6x);
		--accountRightInerWidth: 97%;

		//table
		--tableColumnWidth: 25%;
		--tableItemPadTop: var(--sp3x);
		--tabItemPadLeft: var(--sp1x);
		--accountRightOtherWidth: 100%;

		.statusCont {
			.tableOverflowWrap {
				.tableOverflow {
					min-width: 990px;
				}
			}
		}
	}

	@media only screen and (min-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) {
		--formLeft: 45%;
		--formRight: 50%;
		--formOtherLinkMarg: var(--sp1-5x);

		&.formContainer {
			justify-content: space-between;

			.formLeft {
				.formTitle {
					margin-bottom: 0;
					margin-top: var(--formTitleMarg);
				}
			}
		}

		&.addressModal {
			display: flex;
			flex-wrap: wrap;
		}

		&.accountContainer {
			display: flex;
			flex-wrap: wrap;

			.accountLeftSide {
				width: 100%;
				max-width: var(--accountLeftRightWidth);
				padding: var(--accountItemsPadTop) var(--accountItemsLeftPad) var(--accountItemsPadBot);
				height: 100vh;
				position: sticky;
				top: 0;

				.accountLeftWrap {
					display: flex;
					flex-direction: column;
					height: 100%;
					justify-content: space-between;
				}
			}

			.accountRightSide {
				width: calc(100% - var(--accountLeftRightWidth));

				.avatarForm,
				.profileForm,
				.passwordForm,
				.addressForm {
					max-width: var(--accountRightInerWidth);

					&:last-child {
						margin-bottom: 0;
					}
				}

				.addressCont,
				.orderCont {
					max-width: var(--accountRightOtherWidth);
				}

				.passwordForm {
					.form-rows {
						.form-row:first-child {
							.form-col {
								width: calc(50% - var(--formOtherLinkMarg));
							}
						}
					}
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
		--formTitleMarg: var(--sp12x);
		--formOtherLinkWrapMarg: var(--sp7x);
		--loginOtherWeyTitleMargBott: var(--sp4x);
		--loginOtherWeyMarg: var(--sp6x);
		--ForgotPasswordWrapMargTop: var(--sp3x);
		--formColMargBott: var(--sp4x);

		--formItemTitle: var(--sp4x);
		--accountName: var(--sp6x);
		--sideBarItem: var(--sp2x);
		--uploadedImage: 75px;
		--accountFormItemsMargBott: var(--sp9x);
		--accountLeftRightWidth: 35%;
		--accountItemsPadTop: var(--sp23x);
		--accountItemsPadBot: var(--sp6x);
		--accountItemsLeftPad: var(--sp15x);
		--accountItemsRightPad: var(--sp8x);
		--accountRightInerWidth: 81%;

		//table
		--tableColumnWidth: 21%;
		--lastTableCollWidth: 18.5%;
		--tableItemPadTop: var(--sp3x);
		--tabItemPadLeft: var(--sp2x);
		--accountRightOtherWidth: 94%;

		//no order
		--noOrderTextMargBott: var(--sp3x);
		--noOrderBtnMargTop: var(--sp6x);
		--noOrderWidth: 86%;

		&.formContainer {
			max-width: 68%;
			padding-left: 0;
			padding-right: 0;
		}
	}

	@media only screen and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) {
		.orderCont {
			.tableWrap {
				.tableItem {
					.tableColl {
						&:nth-child(4) {
							width: var(--lastTableCollWidth);
						}
					}

					.tableItemTop {
						cursor: initial;

						.popDownBtn {
							cursor: pointer;
							padding: var(--tableItemPadTop) var(--tabItemPadLeft);
							width: var(--lastTableCollWidth);
						}
					}

					.tableItemBott {
						.tableCollBott {
							width: var(--tableColumnWidth) !important;
							padding: var(--tableItemPadTop) var(--tabItemPadLeft);

							&:first-child {
								width: calc(var(--tableColumnWidth) * 2) !important;
							}
						}
					}
				}
			}
		}

		.statusCont {
			.tableOverflowWrap {
				.tableOverflow {
					min-width: 1020px;
				}
			}

			.cashbackStatusWrap {
				.cashbackStatus {
					.tableOverflow {
						.cashbackStatusItemWrap {
							.comp-title {
								margin-top: var(--sp2x);
							}
						}
					}
				}
			}
		}
	}

	@media only screen and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) {
		--inputPad: var(--sp2x);
		.statusCont {
			.cartLeft,
			.cartRight {
				flex: 0 0 50%;
			}

			.cartRight {
				padding: 0 0 0 var(--sp5x);
				display: flex;
				justify-content: flex-end;

				.muraCart {
					border-radius: 30px;
					padding: var(--sp5x);
				}
			}

			.cartLeft {
				.muraCartTitle {
					padding-bottom: var(--sp5x);
				}

				.statusNameWrap {
					padding: var(--sp5x) 0;
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
		--formTitleMarg: var(--sp12x);
		--formOtherLinkWrapMarg: var(--sp8x);
		--loginOtherWeyTitleMargBott: var(--sp4x);
		--loginOtherWeyMarg: var(--sp8x);
		--ForgotPasswordWrapMargTop: var(--sp4x);
		--formColMargBott: var(--sp4x);

		--formItemTitle: var(--sp4x);
		--accountName: var(--sp9x);
		--sideBarItem: var(--sp2x);
		--uploadedImage: 80px;
		--accountFormItemsMargBott: var(--sp10x);
		--accountLeftRightWidth: 28%;
		--accountItemsPadTop: var(--sp25x);
		--accountItemsPadBot: var(--sp7x);
		--accountItemsLeftPad: var(--sp18x);
		--accountItemsRightPad: var(--sp22x);
		--accountRightInerWidth: 72%;

		//table
		--tableColumnWidth: 20%;
		--lastTableCollWidth: 20%;
		--tableItemPadTop: var(--sp3-5x);
		--tabItemPadLeft: var(--sp2-5x);
		--accountRightOtherWidth: 90%;

		//no order
		--noOrderTextMargBott: var(--sp3x);
		--noOrderBtnMargTop: var(--sp8x);
		--noOrderWidth: 700px;

		&.formContainer {
			max-width: 68%;
			padding-left: 0;
			padding-right: 0;
		}
	}

	@media only screen and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
		--formTitleMarg: var(--sp12x);
		--formOtherLinkWrapMarg: var(--sp10x);
		--loginOtherWeyTitleMargBott: var(--sp5x);
		--loginOtherWeyMarg: var(--sp8x);
		--ForgotPasswordWrapMargTop: var(--sp4x);
		--formColMargBott: var(--sp4x);

		--formItemTitle: var(--sp5x);
		--accountName: var(--sp12x);
		--sideBarItem: var(--sp3x);
		--uploadedImage: 100px;
		--accountFormItemsMargBott: var(--sp11x);
		--accountLeftRightWidth: 33%;
		--accountItemsPadTop: var(--sp33x);
		--accountItemsPadBot: var(--sp9x);
		--accountItemsLeftPad: var(--sp27x);
		--accountItemsRightPad: var(--sp25x);
		--accountRightInerWidth: 71%;

		//table
		--tableColumnWidth: 20%;
		--lastTableCollWidth: 20%;
		--tableItemPadTop: var(--sp3-5x);
		--tabItemPadLeft: var(--sp2-5x);
		--accountRightOtherWidth: 82%;

		//no order
		--noOrderTextMargBott: var(--sp4x);
		--noOrderBtnMargTop: var(--sp10x);
		--noOrderWidth: 896px;

		&.formContainer {
			max-width: 55%;
			padding-left: 0;
			padding-right: 0;
		}

		.statusCont {
			.cartRight {
				.muraCart {
					height: var(--sp38x);
					max-width: var(--sp66x);
				}
			}
		}
	}
`;

export default FormStyle;
