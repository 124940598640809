import React, { memo as Memo, useMemo } from 'react';

//* Components
import Text from '@/components/common/Text';

const DefaultSelect = Memo((props) => {
	//! On Change
	const onChange = (e) => {
		props.onChange && props.onChange(props.list.filter((i) => i.value == e.target.value)[0]);
	};

	//! List Items
	const listItems = useMemo(() => {
		return (
			<select
				value={props.selected.value || props.placeholder || ''}
				onChange={(e) => onChange(e)}>
				{
					<option
						disabled
						value={props.placeholder}>
						{props.placeholder ? props.placeholder + (props.required ? '*' : '') : ''}
					</option>
				}
				{props.list.map((i, k) => (
					<option
						key={k}
						value={i.value}>
						{i.label}
					</option>
				))}
			</select>
		);
	}, [props.list, props.selected.value]);

	return (
		<>
			<div className='labelWrap FlexBox spaceBetween alignMiddle'>
				<div className='FlexBox alignMiddle'>
					<Text
						tag={'label'}
						className='form-col-label pSm arm-regular'
						text={props.lable}
					/>
					{props.required && ' *'}
				</div>
			</div>

			<div className={`select-cont custom-select-cont FlexBox spaceBetween alignMiddle ${props.errorMsg ? 'error' : ''}  ${props.leftIcon ? 'with-left-icon' : ''}`}>
				{listItems}
				<div className={`selected-val`}>
					<i className='select-left-icon icon-arrow-bottom'></i>
				</div>
			</div>
		</>
	);
});

export default DefaultSelect;
