import styled from 'styled-components';

const SliderStyle = styled.div`
	--titleMarg: var(--sp9x);
	--slickArrow: calc(var(--titleMarg) + var(--h2));
	--sliderBotPad: var(--sp3x);
	--productItemPaddingLeftRight: var(--sp2-5x);

	.HomeSliderCont {
		.MostWantedTitle {
			margin-bottom: var(--titleMarg);
		}

		.MostWantedCont {
			.MostWanted {
				position: relative;
				.slick-arrow {
					cursor: pointer;
					position: absolute;
					top: calc(var(--slickArrow) / -2);

					&.slick-next {
						font-family: 'icomoon' !important;
						right: var(--productItemPaddingLeftRight);
					}

					&.slick-prev {
						right: calc(var(--productItemPaddingLeftRight) + var(--h1));
						font-family: 'icomoon' !important;
					}
				}

				.swiper-container {
					padding-bottom: var(--sliderBotPad);

					.swiper-scrollbar {
						height: 2px !important;
						background: #707070 !important;

						.swiper-scrollbar-drag {
							background: var(--white) !important;
						}
					}

					.swiper-wrapper {
						.swiper-slide {
							height: initial !important;
							> div {
								height: 100%;
								flex: 1;
								display: flex;
								flex-direction: column;
								margin-bottom: 0;
							}
						}
					}
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) {
		.HomeSliderCont {
			.MostWantedCont {
				.MostWanted {
					.swiper-container {
						.swiper-scrollbar {
							left: var(--productItemPaddingLeftRight);
							width: calc(100% - (var(--productItemPaddingLeftRight) * 2));
						}
					}
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
		--titleMarg: var(--sp9x);
		--productItemPaddingLeftRight: var(--sp1-5x);
		--sliderBotPad: var(--sp4x);
	}

	@media only screen and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
		--slickArrow: calc(var(--titleMarg) + var(--h7));

		.HomeSliderCont {
			.MostWantedCont {
				.MostWanted {
					.slick-arrow {
						top: calc(var(--slickArrow) * -1);
					}
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.LaptopSize}) {
		--titleMarg: var(--sp9x);
		--sliderBotPad: var(--sp5x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
		--titleMarg: var(--sp11x);
		--sliderBotPad: var(--sp6x);
	}

	@media only screen and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
		.HomeSliderCont {
			.MostWantedCont {
				margin: 0 var(--MostWantedLeftRightpadd);
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
		--titleMarg: var(--sp13x);
		--sliderBotPad: var(--sp8x);
	}

	@media only screen and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
		--titleMarg: var(--sp15x);
		--productItemPaddingLeftRight: var(--sp2-5x);
		--sliderBotPad: var(--sp9x);
	}
`;

export default SliderStyle;
