/* eslint-disable no-case-declarations */
import React from 'react';

//* Components
import Translate from '../components/common/Translate';

const formValidation = (val, type, msg, settings) => {
	const newVal = '' + val;
	const isLength = val && (type === 'text' && (typeof val === 'string' || typeof val === 'number') ? newVal.trim().length > 0 : val.length > 0);

	if (!isLength && type !== 'timepicker' && type !== 'c_password') {
		return <Translate val={msg || 'fieldIsRequired'} />;
	}

	switch (type) {
		case 'email':
			const email = /^(([^<>()\[\]\\.,:\s@"]+(\.[^<>()\[\]\\.,:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
				isEmail = email.test(String(val).toLowerCase());
			return isEmail || <Translate val={msg || 'invalidEmailAddress'} />;
		case 'password':
			const pass = /.{8,}/,
				isPassword = pass.test(val);
			return isPassword || <Translate val={msg || 'invalidPassword'} />;
		case 'c_password':
			return (val.c_pass && val.pass === val.c_pass) || <Translate val={msg ? msg : 'invalidConfirmPassword'} />;
		case 'phone':
			const phone = /^[0][1-9]\d{7}$|^[1-9]\d{7}$/g,
				isPhone = phone.test(val);
			return isPhone || <Translate val={msg || 'PhoneNumberRequired'} />;
		case 'number':
			let number = /[0-9]/g,
				isNumber = number.test(val);
			if (settings && isNumber) {
				isNumber = checkMinMax(settings, val, type);
			}
			return isNumber || <Translate val={msg || 'invalidNumber'} />;
		case 'checkbox':
			let isChecked = true;
			if (settings) {
				isChecked = checkMinMax(settings, val, type);
			}

			return isChecked || <Translate val={msg || 'fieldIsRequired'} />;
		case 'uploader':
			let hasFiles = true;

			if (settings && settings.multiple) {
				hasFiles = checkMinMax(settings, val, type);
			}

			return hasFiles;
		case 'timepicker':
			let isValid = typeof val === 'object' ? !!(val.min && val.max) : val.length > 0;

			return isValid || <Translate val={msg || 'fieldIsRequired'} />;
		default:
			let isVal = true;

			if (settings) {
				isVal = checkMinMax(settings, val, type);
			}
			return isVal || <Translate val={msg || 'fieldIsRequired'} />;
	}
};

const checkMinMax = (settings, val, type) => {
	let value = type === 'number' ? val : typeof val === 'string' ? val.trim().length : val.length;
	if (settings.min && settings.max) {
		let minMaxText = type === 'number' ? 'minIsNumberAndMaxIsNumber' : type === 'checkbox' ? 'checkMinAndMaxFields' : type === 'tagsInput' ? 'addMinAndMaxElements' : type === 'uploader' ? 'MaximumUploadSize' : 'typeMinCharsAndTypeMaxChars';
		minMaxText = (
			<Translate
				val={minMaxText}
				vars={{ min: settings.min, max: settings.max }}
			/>
		);

		return (value >= settings.min && value <= settings.max) || minMaxText;
	} else if (settings.min && !settings.max) {
		let minText = type === 'number' ? 'minIsNumber' : type === 'checkbox' ? 'checkMinFields' : type === 'tagsInput' ? 'addMinElements' : type === 'uploader' ? 'MaximumUploadSize' : 'typeMinChars';
		minText = (
			<Translate
				val={minText}
				vars={{ min: settings.min }}
			/>
		);

		return value >= settings.min || minText;
	} else if (settings.max && !settings.min) {
		let maxText = type === 'number' ? 'maxIsNumber' : type === 'checkbox' ? 'checkMaxFields' : type === 'tagsInput' ? 'addMaxElements' : type === 'uploader' ? 'MaximumUploadSize' : 'typeMaxChars';
		maxText = (
			<Translate
				val={maxText}
				vars={{ max: settings.min }}
			/>
		);

		return value <= settings.max || maxText;
	}
	return false;
};

export default formValidation;
