import React, { memo as Memo } from 'react';

//* HOC's
import { withAccountContext } from '@/context';

//* Components
import CustomLink from '@/components/common/CustomLink';
import Text from '@/components/common/Text';
import CustomImage from '@/components/common/Image';

const SingIn = Memo((props) => {
	return (
		<div className='SingIn'>
			<CustomLink
				url={props.isLoggedIn ? '/account' : '/login'}
				className='SingInWrap'
				content={
					props.isLoggedIn ? (
						props?.user?.avatar ? (
							<CustomImage
								className={'avatarImg'}
								src={props.user.avatar.src}
								alt={props.user.first_name}
							/>
						) : (
							<i className='icon-loginAva' />
						)
					) : (
						<Text
							tag={'span'}
							className={'p arm-regular'}
							text={'SignIn'}
						/>
					)
				}
			/>
		</div>
	);
});

export default withAccountContext(SingIn, ['isLoggedIn', 'user']);
