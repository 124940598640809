import styled from 'styled-components';

const ProductIndividualStyle = styled.div`
	/* //! Fake styles */
	--popupLeftBlockSize: 40%;
	--popupBlocksDistance: var(--sp8x);
	--popupProductTagSize: var(--sp4x);
	--popupProductTagDistance: var(--sp3x);
	--popupProductAttrSize: var(--sp4x);
	--popupProductAttrDistance: var(--sp2x);
	--popupProductQtyDistance: var(--sp2x);
	--popupProductRightItemsMTop: 2rem;

	--currencySize: var(--sp1-5x);
	--currencyAndSaleGap: var(--sp4x);

	--blockPaddingRight: calc(var(--headerPaddingLeft) + var(--sp4x));

	padding: var(--headerPaddingTop) var(--headerPaddingLeft);
	padding-bottom: calc(var(--headerPaddingTop) * 4);
	padding-right: var(--blockPaddingRight);

	.backLink {
		margin-bottom: var(--popupProductTagDistance);
	}

	.product-wrapper {
		display: flex;
		flex-wrap: wrap;
		gap: var(--popupBlocksDistance);
	}

	.product-image-wrapper {
		width: var(--popupLeftBlockSize);
		position: relative;

		.imageCont {
			padding-top: 100%;
		}

		.product-tag {
			width: var(--popupProductTagSize);
			height: var(--popupProductTagSize);
			position: absolute;
			top: var(--popupProductTagDistance);
			right: var(--popupProductTagDistance);
			padding-top: unset;

			img {
				object-fit: contain;
			}
		}
	}

	.product-individual-info {
		flex: 1;
	}

	.attr-wrapper {
		display: flex;
		flex-wrap: wrap;
		gap: var(--popupProductAttrDistance);

		.imageCont {
			width: var(--popupProductAttrSize);
			height: var(--popupProductAttrSize);
			padding-top: unset;

			img {
				object-fit: contain;
			}
		}
	}

	.product-quantity-wrapper {
		display: flex;
		align-items: center;
		gap: var(--popupProductQtyDistance);
	}

	.title,
	.description,
	.pi-q-block,
	.attr-wrapper,
	.pp-top-wrapper,
	.product-quantity-wrapper,
	.btn-wrapper {
		margin-top: var(--popupProductRightItemsMTop);
	}

	.pi-q-block {
		display: flex;
	}

	.product-quantity-wrapper {
		i {
			cursor: pointer;
		}
	}

	.pp-top-wrapper {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		gap: var(--currencyAndSaleGap);
	}

	.relative-block {
		position: relative;

		i {
			position: absolute;
			top: 0;
			left: 100%;
			font-size: var(--currencySize);
		}
	}

	.sale-line {
		&::before {
			content: '';
			position: absolute;
			top: 50%;
			transform: translateY(-50%) rotate(-15deg);
			width: 100%;
			height: 2px;
			background-color: var(--grey);
		}
	}

	/* //! 1920 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
		--popupProductRightItemsMTop: 1.5rem;
	}

	/* //! 1440 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
		--popupProductRightItemsMTop: 1rem;
	}

	/* //! 1024 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.LaptopSize}) {
		--popupProductRightItemsMTop: 1rem;
		--blockPaddingRight: var(--headerPaddingLeft);
	}

	/* //! 768 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
		--popupLeftBlockSize: 100%;
		--popupProductRightItemsMTop: 1.5rem;

		--blockPaddingRight: var(--headerPaddingLeft);

		.product-individual-info {
			width: 100%;
		}
	}

	/* //! Mobile */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		--popupLeftBlockSize: 100%;
		--popupBlocksDistance: var(--sp4x);
		--popupProductRightItemsMTop: 1rem;
		--popupProductAttrSize: var(--sp3x);

		--blockPaddingRight: var(--headerPaddingLeft);

		.product-individual-info {
			width: 100%;
		}
	}
`;

export default ProductIndividualStyle;
