import styled from 'styled-components';

const ContactStyle = styled.div.attrs((props) => '')`
	--contactItemMargBottom: var(--sp4x);
	--contactItemTitleMarg: var(--sp2x);

	&.ContactStyle {
		.contactInfoWrap {
			.contactInfoItem {
				margin-bottom: var(--contactItemMargBottom);

				a {
					display: inline-block;
					margin-right: var(--sp3x);
					position: relative;
					overflow: hidden;
					&:last-child {
						margin-right: 0;
					}
					span {
						position: absolute;
						top: 0;
					}
				}

				.contactInfoItemTitle {
					margin-bottom: var(--contactItemTitleMarg);
				}

				i {
					font-size: 24px;
				}
			}
		}

		a {
			color: var(--white);
			overflow: hidden;
			background: linear-gradient(to right, var(--grey), var(--grey) 50%, var(--white) 50%);
			background-clip: text;
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
			background-size: 200% 100%;
			background-position: 100%;
			transition: background-position var(--trTime) ease;
			text-decoration: none;

			&:hover {
				background-position: 0 100%;
			}
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.MobileSize}) {
			--contactItemMargBottom: var(--sp6x);

			.contactInfoWrap {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;

				.contactInfoItem {
					padding-right: var(--contactItemMargRight);
					&:nth-child(3n) {
						padding-right: 0;
					}
					&.PrivacyPolicy {
						width: 100%;
					}
				}
			}
		}

		@media only screen and (min-width: ${(props) => props.theme.mediaQuery.MobileSize}) {
			--contactItemTitleMarg: var(--sp2x);
			--contactItemMargRight: var(--sp10x);
		}

		@media only screen and (min-width: ${(props) => props.theme.mediaQuery.LaptopSize}) {
			--contactItemMargBottom: var(--sp6x);
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
			.contactInfoWrap {
				.contactInfoItem {
					i {
						font-size: 27px;
						margin-right: var(--sp4x);
					}
				}
			}
		}

		@media only screen and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
			--contactItemTitleMarg: var(--sp2-5x);

			.contactInfoWrap {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;

				.contactInfoItem {
					padding-right: var(--contactItemMargRight);
					&:last-child {
						padding-right: 0;
					}

					&.PrivacyPolicy {
						display: flex;
						align-items: flex-end;
					}
				}
			}
		}

		@media only screen and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
			--contactItemMargBottom: var(--sp6x);

			.contactInfoWrap {
				.contactInfoItem {
					i {
						font-size: 34px;
						margin-right: var(--sp6x);
					}
				}
			}
		}
	}
`;

export default ContactStyle;
