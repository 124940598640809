'use client';

import { usePathname } from 'next/navigation';
import { useEffect, useMemo, useRef, useState } from 'react';

//* Helpers
import config from '@/helpers/_config';

//* Components
import ContactInformation from '../AppHeader/HeaderComponent/ContactInformation';
import Logo from '../Logo';
import Menu from '../Menu';
import PrivacyPolicyLink from '../PrivacyPolicyLink';
import Text from '../Text';
import Translate from '../Translate';

//* Style
import Footer from './style';

const AppFooter = () => {
	//! Next Navigation
	const pathname = usePathname();

	//! Ref
	const ftrRef = useRef();

	//! States
	const [activeClass, setActiveClass] = useState('');
	const [footerDisable, setFooterDisable] = useState(false);

	//! Big Menu Object
	const menuObj = useMemo(
		() => ({
			type: 'nav',
			contClass: 'menu-items-cont',
			itemClass: 'menu-item',
			items: [
				{
					name: config.routes.products.name,
					path: config.routes.products.path,
				},
				{
					name: config.routes.aboutUs.name,
					path: config.routes.aboutUs.path,
				},
				{
					name: config.routes.contactUs.name,
					path: config.routes.contactUs.path,
				},
				{
					name: config.routes.news.name,
					path: config.routes.news.path,
				},
				{
					name: config.routes.muramoney.name,
					path: config.routes.muramoney.path,
				},
			],
		}),
		[]
	);

	useEffect(() => {
		if (process.browser) {
			window?.addEventListener('scroll', scroll);

			return () => {
				window?.removeEventListener('scroll', scroll);
			};
		}
	});

	const scroll = () => {
		if (ftrRef?.current?.getBoundingClientRect().y < window.innerHeight * 0.8) {
			setActiveClass('active');
		}
	};

	useEffect(() => {
		if (pathname.includes('account') || pathname.includes('login') || pathname.includes('register') || pathname.includes('forgot-password') || pathname.includes('reset-password')) {
			setFooterDisable(false);
		} else {
			setFooterDisable(true);
		}
	}, [pathname]);

	return footerDisable ? (
		<Footer>
			<div
				ref={ftrRef}
				className={`footerTop ${activeClass}`}>
				<div className='footerTopLeft'>
					<div className='footerLogoWrap'>
						<Logo
							footerLogo
							className={'footerLogo'}
						/>
					</div>

					<div className='footerContactWrap'>
						<ContactInformation type={false} />
					</div>
				</div>

				<div className={`footerTopRight ${activeClass}`}>
					<Menu {...menuObj} />

					<PrivacyPolicyLink />
				</div>
			</div>

			<div className='footerBottom'>
				<Text
					tag={'span'}
					className={'p arm-regular'}>
					{new Date().getFullYear()}
				</Text>

				<Text
					tag={'span'}
					className={'p arm-regular'}
					text={'Warning'}
				/>

				<a
					target='_blank'
					rel='noopener noreferrer'
					aria-label='Concept Studio'
					className='concept p arm-regular'
					href='https://conceptstudio.com/'>
					<Translate val={'ConceptStudio'} />
				</a>
			</div>
		</Footer>
	) : null;
};

export default AppFooter;
