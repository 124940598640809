'use client';

import React, { memo as Memo, useRef, useState } from 'react';
import { Collapse } from '@mui/material';

//* HOC's
import { withCartContext } from '@/context';

//* Helpers
import formJson from '@/helpers/forms.json';

//* Components
import Text from '../Text';
import Form from '../Form';
import Button from '../Button';
import Translate from '../Translate';

//* Style
import CheckStyle from './style';

const OrderSummary = Memo(({ checkPromocode, deletePromocode, fillBalance, btnDisabled, ...props }) => {
	const promoRef = useRef();
	const [selectePromo, setSelectePromo] = useState(false);
	const [promoError, setPromoError] = useState(null);
	const [selecteMuramoney, setSelecteMuramoney] = useState(false);

	const onTypeChange = (name, value) => props.onTypeChange(value[0]);

	const onPromoChange = (data) => {
		checkPromocode(data)
			.then(() => setPromoError(null))
			.catch((err) => setPromoError(err));
	};

	const promoReset = () => deletePromocode().then(() => promoRef.current.resetFields());

	const onSelectePromo = () => setSelectePromo(!selectePromo);

	const onMuramoneyChange = (name, value) => {
		fillBalance({ [name]: value[0] == 1 ? 1 : 0 });
		setSelecteMuramoney(value[0] === '1');
	};

	return (
		<div className='cartCollMin'>
			<div className='cartCollSticky'>
				<Text
					tag={'div'}
					className={'h7 Title black arm-bold'}
					text={'OrderSummary'}
				/>

				<div className='OrderSummaryCont'>
					<div className='OrderSummaryItemWrap'>
						<div className='OrderSummaryItem FlexBox spaceBetween'>
							<Text
								tag={'div'}
								className={'h10 black arm-bold'}
								text={'Subtotal'}
							/>
							<Text
								tag={'div'}
								className={'p black arm-regular'}
								text={props.subtotal + props.currency}
							/>
						</div>
						<div className='OrderSummaryItem FlexBox spaceBetween'>
							<Text
								tag={'div'}
								className={'h10 black arm-bold'}
								text={'Delivery'}
							/>
							<Text
								tag={'div'}
								className={'p black arm-regular'}
								text={typeof props.delivery === 'string' ? props.delivery : props.delivery + props.currency}
							/>
						</div>
					</div>
					{props.Checkout ? (
						<>
							<CheckStyle className={`promocodWrap ${selecteMuramoney !== true ? 'active' : 'disabled'}`}>
								<div
									className='OrderSummaryItem FlexBox spaceBetween cursorPointer'
									onClick={onSelectePromo}>
									<Text
										tag={'div'}
										className={'h10  arm-regular'}
										text={'PromoCode'}
									/>
									<Text
										tag={'div'}
										className={'h8  arm-bold'}
										text={'+'}
									/>
								</div>

								<Collapse
									in={selectePromo && !selecteMuramoney}
									timeout='auto'
									unmountOnExit>
									<Form
										ref={promoRef}
										submit={onPromoChange}
										wrapClass={`${props.code ? 'disabled' : ''}`}
										className={`formItems`}
										{...formJson.promoCod}
									/>
									{promoError && <Text className={'red pSm arm-regular'}>{promoError}</Text>}
								</Collapse>
							</CheckStyle>
							{props.code && (
								<Collapse
									in={selectePromo && !selecteMuramoney}
									timeout='auto'
									unmountOnExit>
									<CheckStyle className={`promocodDiscountWrapp`}>
										<div className='muramonyTotal FlexBox spaceBetween'>
											<div>
												<Text className={'h10  arm-bold black'}>
													{props.code.reward}% <Translate val={'OneTimeDiscount'} />
												</Text>
												<Text
													attr={{ onClick: promoReset }}
													tag={'div'}
													className={'p  arm-regular black underline cursorPointer'}
													text={'Remove'}
												/>
											</div>
											<Text
												tag={'div'}
												className={'p  arm-regular black'}
												text={props.code_total + props.currency}
											/>
										</div>
									</CheckStyle>
								</Collapse>
							)}
							<CheckStyle className={`muramonyWrap ${selecteMuramoney === true ? 'active' : ''}`}>
								<Form
									onChange={onMuramoneyChange}
									className={'formItems'}
									{...formJson.muramoneyParams}
								/>
								<div className='muramonyTotal FlexBox spaceBetween'>
									<Text
										tag={'div'}
										className={'h10  arm-bold'}
										text={'Muromony'}
									/>
									<Text
										tag={'div'}
										className={'p  arm-regular'}
										text={props.muramoney + props.currency}
									/>
								</div>
							</CheckStyle>
						</>
					) : (
						''
					)}

					<div className='OrderSummary FlexBox spaceBetween'>
						<Text
							tag={'div'}
							className={'h8 black arm-bold'}
							text={'Total'}
						/>
						<Text
							tag={'div'}
							className={'h8  black arm-bold'}
							text={props.total + props.currency}
						/>
					</div>

					{props.Checkout ? (
						<>
							<div className='OrderPaymentWrap'>
								<div className='Title'>
									<Text
										tag={'div'}
										className={'h7 black arm-bold'}
										text={'Payment'}
									/>
									<Text
										tag={'div'}
										className={'p subTitle black arm-regular'}
										text={'ChooseDesiredPaymentMethodBelow'}
									/>
								</div>

								<Form
									onChange={onTypeChange}
									wrapClass={'blackForm'}
									className={'formItems'}
									{...formJson.paymentParams}
								/>
							</div>
							<Button
								onClick={props.onOrder}
								className={`btn btn-mid btn-black btn-max-width ${btnDisabled ? '' : 'disabled'}`}
								text={'ContinueToPayment'}
							/>
						</>
					) : (
						<Button
							link={'/checkout'}
							className={`btn-mid btn-black btn-max-width ${btnDisabled ? '' : 'disabled'}`}
							text={'ContinueToCheckout'}
						/>
					)}
				</div>
			</div>
		</div>
	);
});

export default withCartContext(OrderSummary, ['checkPromocode', 'deletePromocode', 'fillBalance']);
