import styled from 'styled-components';

const LanguageSwitcherStyle = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;
	line-height: var(--lineHeightS);

	.activeLanguage {
		position: relative;

		&::after {
			content: '';
			position: absolute;
			bottom: -5px;
			left: 0;
			height: 2px;
			width: var(--sp2x);
			background-color: var(--borderColor);
		}
	}

	.lang-cont {
		position: absolute;
		top: 150%;
		left: 0;
		height: 0;

		& > div {
			display: flex;
			flex-direction: column;
			overflow: hidden;
			a {
				display: block;
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
		.activeLanguage {
			&::after {
				width: var(--sp2-5x);
			}
		}
	}

	@media only screen and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
		.activeLanguage {
			&::after {
				width: var(--sp3x);
			}
		}
	}
`;

export default LanguageSwitcherStyle;
