import React, { memo as Memo } from 'react';
import { Collapse } from '@mui/material';

const FieldErrorMsg = Memo(({ msg }) => {
	return (
		<Collapse
			in={!!msg}
			timeout='auto'
			unmountOnExit>
			<span className={`errorCont pSm arm-regular`}>{msg}</span>
		</Collapse>
	);
});

export default FieldErrorMsg;
