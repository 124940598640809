import React, { memo as Memo } from 'react';

//* Components
import CustomLink from '../../../CustomLink';

const Nav = Memo((props) => {
	return (
		<CustomLink
			className={'Link' + props.className}
			url={props.path}
			content={props.children}
			nav={true}
			data={props.data}
		/>
	);
});

export default Nav;
