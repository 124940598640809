import styled from 'styled-components';

const ProductItemStyle = styled.div`
	--productItemPaddingLeftRight: var(--sp1x);
	--productItemPaddingBottom: var(--sp3x);

	--productIcon: 22px;
	--productIconMarg: var(--sp2x);
	--productInfoMarg: var(--sp2x);
	--productNameMargBottom: var(--sp1x);
	--productPriceWrapPad: var(--sp1-5x);
	--productPriceMargLeft: var(--sp2x);
	--productQuantityMarg: var(--sp1x);
	--currencySize: 7px;
	--currencySlideSize: 5px;
	--productImgSize: 87%;

	--btnHeight: 43px;

	position: relative;
	overflow: hidden;
	padding-left: var(--productItemPaddingLeftRight);
	padding-right: var(--productItemPaddingLeftRight);
	margin-bottom: var(--productItemPaddingBottom);

	.productItemAfterWrap {
		pointer-events: none;
		position: absolute;
		top: 0;
		left: var(--productItemPaddingLeftRight);
		right: var(--productItemPaddingLeftRight);
		height: 100%;
		padding-bottom: 1px;
		z-index: 6;

		.productItemAfter {
			position: absolute;
			background-color: var(--white);
			transition: all 0.2s linear;

			&.productItemAfterBott {
				height: 0px;
				bottom: 0;
				left: 0;
				right: 0;
				transition-delay: 0.5s;
			}

			&.productItemAfterLeft {
				height: 0%;
				width: 1px;
				bottom: 0;
				left: 0;
				transition-delay: 0.4s;
			}

			&.productItemAfterTop {
				height: 1px;
				width: 0%;
				top: 0;
				left: 0;
				transition-delay: 0.2s;
			}

			&.productItemAfterRight {
				height: 0%;
				width: 1px;
				top: 0;
				right: 0;
				transition-delay: 0s;
			}
		}
	}

	.productItem {
		border-right: 1px solid var(--borderDark);
		border-left: 1px solid var(--borderDark);
		border-top: 1px solid var(--borderDark);
		flex: 1;
		display: flex;
		flex-direction: column;

		.product-image-wrapper {
			border-bottom: 1px solid var(--borderDark);
			position: relative;
			cursor: pointer;

			.productIcon {
				position: absolute;
				top: var(--productIconMarg);
				right: var(--productIconMarg);
				height: var(--productIcon);
				width: var(--productIcon);

				.image {
					object-fit: contain;
				}
			}

			.productImg {
				transition: all var(--trTime2) ease;
				padding-top: var(--productImgSize);
			}
		}

		.productInfoWrap {
			flex: 1;
			display: flex;
			flex-direction: column;

			.productInfo {
				flex: 1;
				padding: var(--productInfoMarg);
				display: flex;
				flex-direction: column;

				.productName {
					margin-bottom: var(--productNameMargBottom);
					font-size: var(--h10);
					flex: 1;
				}

				.productDescription {
					margin-bottom: var(--productNameMargBottom);
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;
				}
			}
		}

		.productPriceWrap {
			padding: var(--productPriceWrapPad);
			border-top: 1px solid var(--borderDark);
			background-color: #0a0a0a;

			.productPrice {
				flex: 0 0 auto;
				max-width: 50%;

				.productPriceActual {
					position: relative;

					i {
						font-size: var(--currencySize);
						transform: translateX(100%);
						position: absolute;
						top: 0;
						right: 0;
						color: var(--grey);
					}
				}

				.productPriceLast {
					position: relative;
					margin-left: var(--productPriceMargLeft);
					text-decoration: line-through;

					i {
						font-size: var(--currencySlideSize);
						transform: translateX(120%);
						position: absolute;
						top: 0;
						right: 0;

						&:before {
							color: var(--grey);
						}
					}
				}
			}

			.productQuantityWrap {
				flex: 0 0 auto;
				max-width: 50%;

				.productQuantity {
					margin: 0 var(--productQuantityMarg);
				}

				i {
					cursor: pointer;
				}

				> div {
					text-align: center;
					min-width: var(--sp3x);
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		--productQuantityMarg: 0;
	}

	@media only screen and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
		--productItemPaddingLeftRight: var(--sp1-5x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
		--productIcon: 22px;
		--btnHeight: 50px;
	}

	@media only screen and (min-width: ${(props) => props.theme.mediaQuery.LaptopSize}) {
		.productItem {
			border-bottom: 1px solid var(--borderDark);
		}
	}

	@media only screen and (min-width: ${(props) => props.theme.mediaQuery.LaptopSize}) {
		&:hover {
			.productItem {
				border-bottom: 0px;

				.product-image-wrapper {
					.productImg {
						padding-top: calc(var(--productImgSize) - var(--btnHeight));
					}
				}
			}

			.btnContWrap {
				height: var(--btnHeight);
			}

			.productItemAfterWrap {
				.productItemAfter {
					&.productItemAfterBott {
						height: 1px;
						transition-delay: 0s;
					}

					&.productItemAfterLeft {
						height: 100%;
						transition-delay: 0s;
					}

					&.productItemAfterTop {
						width: 100%;
					}

					&.productItemAfterRight {
						height: 100%;
						transition-delay: 0.4s;
					}
				}
			}
		}

		.btnContWrap {
			position: relative;
			height: 0;
			transition: all var(--trTime2) ease;

			> div {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				z-index: 3;
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.LaptopSize}) {
		--productIcon: 22px;
		--currencySize: 10px;
		--currencySlideSize: 8px;
		--btnHeight: 50px;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
		--itemWidth: 33.3333%;

		--productIcon: 24px;
		--currencySize: 10px;
		--currencySlideSize: 8px;

		--btnHeight: 54px;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
		--productIcon: 30px;
		--currencySize: 10px;
		--currencySlideSize: 8px;

		--btnHeight: 54px;
	}

	@media only screen and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
		--productItemPaddingBottom: var(--sp5x);
		--productItemPaddingLeftRight: var(--sp2-5x);

		--productIcon: 40px;
		--productIconMarg: var(--sp2-5x);
		--productInfoMarg: var(--sp2-5x);
		--productNameMargBottom: var(--sp2x);
		--productPriceMargLeft: var(--sp3x);
		--productQuantityMarg: var(--sp2x);
		--currencySize: 10px;
		--currencySlideSize: 8px;

		--btnHeight: 60px;
	}
`;

export default ProductItemStyle;
