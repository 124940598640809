import styled from 'styled-components';

const SliderTextStyle = styled.div`
	--shopNowFont: 20px;
	--ShopNowTickerContMarg: var(--sp7x);
	--ShopNowContPadLeft: var(--sp2x);
	--ShopNowTickerPadd: var(--sp1-5x);
	--ShopNowTickerItem: var(--sp2-5x);

	.ShopNowTickerCont {
		margin: var(--ShopNowTickerContMarg) 0;
		white-space: nowrap;
		position: relative;

		.comp-title {
			font-size: var(--shopNowFont);
		}

		.ShopNowTickerBlack {
			display: block;
			width: 106%;
			margin-left: -3%;
			transform: rotate(-7deg);
			background: var(--black);
			border-top: 2px solid var(--white);
			border-bottom: 2px solid var(--white);
			padding: var(--ShopNowTickerPadd) var(--ShopNowContPadLeft);
			transform-origin: 50% 0;
			> span {
				display: block;
				width: 100%;
				> div {
					width: max-content;
				}
			}
		}
		.ShopNowTickerWhite {
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 106%;
			margin-left: -3%;
			transform: rotate(7deg);
			background: var(--white);
			padding: var(--ShopNowTickerPadd) var(--ShopNowContPadLeft);
			border-top: 2px solid var(--black);
			border-bottom: 2px solid var(--black);
			transform-origin: 50% 0;
			> span {
				display: block;
				width: 100%;
				> div {
					width: max-content;
				}
			}
		}

		.comp-title {
			padding: 0 var(--ShopNowTickerItem);
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
		--ShopNowContPadLeft: var(--sp2x);
		--ShopNowTickerContMarg: var(--sp12x);
		--ShopNowTickerItem: var(--sp3x);
	}

	@media only screen and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
		--shopNowFont: 22px;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.LaptopSize}) {
		--ShopNowContPadLeft: var(--sp3x);
		--ShopNowTickerContMarg: var(--sp12x);
		--ShopNowTickerItem: var(--sp3x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
		--shopNowFont: 26px;
		--ShopNowContPadLeft: var(--sp4x);
		--ShopNowTickerContMarg: var(--sp15x);
		--ShopNowTickerPadd: var(--sp2x);
		--ShopNowTickerItem: var(--sp4x);

		.ShopNowTickerCont {
			.ShopNowTickerBlack {
				transform: rotate(-5deg);
			}
			.ShopNowTickerWhite {
				transform: rotate(5deg);
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
		--shopNowFont: 28px;
		--ShopNowContPadLeft: var(--sp5x);
		--ShopNowTickerContMarg: var(--sp20x);
		--ShopNowTickerPadd: var(--sp2x);
		--ShopNowTickerItem: var(--sp5x);

		.ShopNowTickerCont {
			.ShopNowTickerBlack {
				transform: rotate(-5deg);
			}
			.ShopNowTickerWhite {
				transform: rotate(5deg);
			}
		}
	}

	@media only screen and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
		--shopNowFont: 26px;
		--ShopNowContPadLeft: var(--sp7x);
		--ShopNowTickerContMarg: var(--sp24x);
		--ShopNowTickerPadd: var(--sp2-5x);
		--ShopNowTickerItem: var(--sp7x);

		.ShopNowTickerCont {
			.ShopNowTickerBlack {
				transform: rotate(-4deg);
			}
			.ShopNowTickerWhite {
				transform: rotate(4deg);
			}
		}
	}
`;

export default SliderTextStyle;
