'use client';

import { useState, useRef, useEffect } from 'react';
import Router from 'next/router';

const Loader = () => {
	const ref = useRef();

	const [loaderState, setLoaderState] = useState('');
	useEffect(() => {
		if (process.browser) {
			document.body.classList.add('active');

			setTimeout(() => {
				// setLoaderState('end')
				document.body.classList.remove('active');
			}, 1000);
		}
	}, []);

	Router.onRouteChangeStart = () => {
		document.body.classList.add('active');
		setLoaderState('start');
	};

	Router.onRouteChangeComplete = () => {
		setTimeout(() => {
			setLoaderState('end');
			document.body.classList.remove('active');
		}, 0);
	};

	return (
		<div
			ref={ref}
			className={`loader ${loaderState}`}>
			<span className='line-top'></span>
			<div className='circle'></div>
			<span className='line-bottom'></span>
		</div>
	);
};

export default Loader;
