import styled from 'styled-components';

const Footer = styled.footer`
	--footerTopPad: var(--sp6x);
	--footerBottomPad: var(--sp2-5x);
	--footerLeftRightPad: var(--containerPadding);

	.footerTop {
		position: relative;

		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 0;
			height: 2px;
			background-color: var(--white);
			transition: var(--trTime2) ease-out;
		}

		&:after {
			content: '';
			position: absolute;
			bottom: 0;
			right: 0;
			width: 0;
			height: 2px;
			background-color: var(--white);
			transition: var(--trTime2) ease-out var(--trTime);
		}

		.footerTopLeft {
			padding: var(--footerTopPad) var(--footerLeftRightPad);

			.ContactStyle .contactInfoWrap .contactInfoItem {
				&:last-child {
					margin-bottom: 0;
				}
			}
		}

		.footerTopRight {
			padding: 0 var(--footerLeftRightPad) var(--footerTopPad);
			position: relative;

			a {
				color: var(--white);
				overflow: hidden;
				background: linear-gradient(to right, var(--grey), var(--grey) 50%, var(--white) 50%);
				background-clip: text;
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
				background-size: 200% 100%;
				background-position: 100%;
				transition: background-position var(--trTime) ease;
				text-decoration: none;
			}

			.PrivacyPolicy {
				> a {
					display: block;
				}
			}

			.menu-items-cont {
				display: flex;
				flex-wrap: wrap;

				.menu-item {
					line-height: normal;
					text-transform: capitalize;
					flex: 0 0 100%;
					max-width: 100%;
					margin-bottom: var(--sp3x);

					.Linkmenu-item {
						font-family: arm-regular;
						font-size: var(--h8);
					}
				}
			}
		}
	}

	.footerBottom {
		padding: var(--footerBottomPad) var(--footerLeftRightPad);

		span {
			line-height: var(--lineHeightL);
			margin-right: 5px;
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.MobileSize}) {
		--footerTopPad: var(--sp8x);
		--footerBottomPad: var(--sp3x);

		.footerTop {
			.footerTopLeft {
				flex: 0 0 60%;
				max-width: 60%;

				.ContactStyle .contactInfoWrap .contactInfoItem {
					width: 100%;
				}

				.contactInfoWrap {
					.contactInfoItem {
						&:nth-child(1) {
							order: 1;
						}

						&:nth-child(2) {
							order: 3;
						}

						&:nth-child(3) {
							order: 2;
						}
					}
				}
			}

			.footerTopRight {
				flex: 0 0 40%;
				max-width: 40%;

				.menu-items-cont {
					.menu-item {
						margin-bottom: var(--sp4x);
					}
				}
			}
		}
	}

	@media only screen and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
		.footerTop {
			display: flex;
			flex-wrap: wrap;

			&.active {
				&:before,
				&:after {
					width: 100%;
				}
			}

			.footerTopLeft {
				display: flex;
				flex-direction: column;
				flex-wrap: wrap;
				justify-content: center;

				.ContactStyle .contactInfoWrap {
					display: flex;
					flex-wrap: wrap;
				}
			}

			.footerTopRight {
				padding: var(--footerTopPad) var(--footerLeftRightPad);
				display: flex;
				flex-direction: column;
				justify-content: center;

				&.active {
					&:before {
						height: 100%;
					}
				}

				&:before {
					content: '';
					position: absolute;
					top: 0;
					left: 0;
					width: 2px;
					height: 0;
					background-color: var(--white);
					transition: var(--trTime2) ease-out var(--trTime2);
				}
			}
		}
	}

	@media only screen and (min-width: ${(props) => props.theme.mediaQuery.LaptopSize}) {
		.footerTop {
			&.active {
				&:before,
				&:after {
					width: 100%;
				}
			}

			.footerTopRight {
				a {
					&:hover {
						background-position: 0 100%;
					}
				}

				&.active {
					&:before {
						height: 100%;
					}
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) and (min-width: 0) {
		.footerTop {
			&:before,
			&:after {
				width: 100%;
			}

			.footerTopRight {
				&:before {
					height: 100%;
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.LaptopSize}) {
		--footerTopPad: var(--sp7x);
		--footerBottomPad: var(--sp4x);

		.footerTop {
			.footerTopLeft {
				flex: 0 0 65%;
				max-width: 65%;

				.ContactStyle .contactInfoWrap .contactInfoItem {
					width: 50%;
					margin-bottom: var(--sp5x);
				}
			}

			.footerTopRight {
				flex: 0 0 35%;
				max-width: 35%;

				.menu-items-cont {
					.menu-item {
						margin-bottom: var(--sp4x);
					}
				}
			}
		}
	}

	@media only screen and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
		.footerTop {
			.footerTopLeft {
				.ContactStyle .contactInfoWrap .contactInfoItem {
					margin-bottom: 0;
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
		--footerTopPad: var(--sp5x);
		--footerBottomPad: var(--sp4x);

		.footerTop {
			.footerTopLeft {
				flex: 0 0 73%;
				max-width: 73%;
			}

			.footerTopRight {
				flex: 0 0 27%;
				max-width: 27%;

				.menu-items-cont {
					.menu-item {
						margin-bottom: var(--sp5x);
					}
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
		--footerTopPad: var(--sp6x);
		--footerBottomPad: var(--sp5x);

		.footerTop {
			.footerTopLeft {
				flex: 0 0 73%;
				max-width: 73%;

				.ContactStyle .contactInfoWrap .contactInfoItem {
					margin-bottom: 0;
				}
			}

			.footerTopRight {
				flex: 0 0 27%;
				max-width: 27%;

				.menu-items-cont {
					.menu-item {
						margin-bottom: var(--sp5x);
					}
				}
			}
		}
	}

	@media only screen and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
		--footerTopPad: var(--sp10x);
		--footerBottomPad: var(--sp6x);

		.footerTop {
			.footerTopLeft {
				flex: 0 0 73%;
				max-width: 73%;

				.ContactStyle .contactInfoWrap .contactInfoItem {
					margin-bottom: 0;
				}
			}

			.footerTopRight {
				flex: 0 0 27%;
				max-width: 27%;

				.menu-items-cont {
					.menu-item {
						margin-bottom: var(--sp6x);
					}
				}
			}
		}
	}
`;

export default Footer;
