import styled from 'styled-components';

const AddressStyle = styled.div`
	--addressItemPaddTop: var(--sp2x);
	--addressItemPaddLeft: var(--sp1x);
	--addressItemMarBott: var(--sp1x);

	.errorMes {
		color: var(--errorColor);
		padding-top: var(--TitleMargBott);
	}

	.addressCont {
		.formItemTitleDuo {
			.comp-title {
				margin-bottom: var(--sp2x);
			}
		}

		.addressItemWrap {
			/* border-top:1px solid var(--black); */
			position: relative;

			&.error {
				&:before {
					background-color: var(--errorColor);
				}
			}

			&.active {
				&:before {
					width: 100%;
				}
			}

			&:before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 0;
				height: 1px;
				transition: 0.5s ease-out;
				background-color: var(--borderDark);
			}

			.addressItem {
				border-bottom: 1px solid var(--black);
				padding: var(--addressItemPaddTop) var(--addressItemPaddLeft);
				margin-bottom: var(--addressItemMarBott);
				position: relative;

				&.active {
					&:before {
						width: 100%;
					}
				}

				&.addressCheck {
					padding: var(--addressItemPaddTop) var(--addressItemPaddLeft) var(--addressItemPaddTop) calc(var(--addressItemPaddTop) * 1.5);
				}

				.switch {
					position: absolute;
					top: var(--addressItemPaddTop);
					left: 0;
					z-index: 1;
					border-radius: 100px;
					border: 1px solid var(--black);
					width: var(--ckboxIconSize);
					height: var(--ckboxIconSize);
					transform: translateY(50%);
					&:after {
						content: '';
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate3d(-50%, -50%, 0);
						width: 60%;
						height: 60%;
						border-radius: 100px;
						opacity: 0;
						background-color: var(--black);
						transition: all var(--trTime) ease-out;
					}
					&.checked {
						&:after {
							opacity: 1;
						}
					}
				}

				&:before {
					content: '';
					position: absolute;
					bottom: 0;
					left: 0;
					width: 0;
					height: 1px;
					transition: all var(--trTime2) ease-out;
					background-color: var(--borderDark);
				}

				.addressDescription {
					max-width: 60%;
					> div {
						line-height: var(--lineHeightL);
					}
				}

				.adresOtherFunc {
					margin-top: var(--sp2x);
					.adresOtherFuncBtn {
						> div {
							span {
								cursor: pointer;
							}
							&:first-child {
								margin-right: var(--sp2x);
								position: relative;
								&:not(.default) {
									&:after {
										content: '';
										position: absolute;
										top: 50%;
										bottom: 0;
										right: calc(var(--sp2x) * -1);
										transform: translateY(-50%);
										height: 65%;
										width: 2px;
										background-color: var(--black);
									}
								}
							}
							&:last-child {
								&:not(.default) {
									margin-left: var(--sp2x);
								}
							}
						}
					}
					.Default {
						margin-top: var(--sp2x);
						text-align: right;
						.comp-title {
							cursor: pointer;
						}
					}
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
		//address
		--addressItemPaddTop: var(--sp3x);
		--addressItemPaddLeft: var(--sp2x);
		--addressItemMarBott: var(--sp4x);
		--formOtherLinkMarg: var(--sp1-3x);
	}

	@media only screen and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		.addressCont {
			.formItemTitleDuo {
				display: flex;
				justify-content: space-between;

				.comp-title {
					margin-bottom: 0;
				}
			}

			.addressItemWrap {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;

				.addressItem {
					width: calc(50% - var(--formOtherLinkMarg));

					.adresOtherFunc {
						display: flex;
						justify-content: space-between;

						.Default {
							margin-top: 0;
						}
					}
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.LaptopSize}) {
		//address
		--addressItemPaddTop: var(--sp3x);
		--addressItemPaddLeft: var(--sp2x);
		--addressItemMarBott: var(--sp4x);
		--formOtherLinkMarg: var(--sp1-5x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
		//address
		--addressItemPaddTop: var(--sp3x);
		--addressItemPaddLeft: var(--sp2x);
		--addressItemMarBott: var(--sp4x);
		--formOtherLinkMarg: var(--sp2-5x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
		//address
		--addressItemPaddTop: var(--sp3x);
		--addressItemPaddLeft: var(--sp2x);
		--addressItemMarBott: var(--sp4x);
		--formOtherLinkMarg: var(--sp4x);
	}

	@media only screen and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
		//address
		--addressItemPaddTop: var(--sp4x);
		--addressItemPaddLeft: var(--sp3x);
		--addressItemMarBott: var(--sp5x);
		--formOtherLinkMarg: var(--sp4x);
	}
`;

export default AddressStyle;
