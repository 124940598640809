'use client';

import React from 'react';

//* Style
import ContainerStyle from './style';

const Container = React.forwardRef((props, ref) => {
    return (
        <ContainerStyle
            ref={ref}
            onWheel={props.onWheel}
            onMouseEnter={props.onMouseEnter}
            onMouseLeave={props.onMouseLeave}
            onMouseMove={props.onMouseMove}
            className={`${props.className || ''} ${props.full ? ' full' : ''}`}>
            {props.children}
        </ContainerStyle>
    );
});

export default Container;
