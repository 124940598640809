import styled from 'styled-components';

const BurgerStyle = styled.div`
	.Burger {
		--burgerSize: 17px;
		--burgerHeightSize: 20px;

		width: var(--burgerSize);
		height: var(--burgerHeightSize);
		display: flex;
		flex-direction: column;
		align-items: stretch;
		justify-content: space-evenly;

		span {
			display: block;
			height: 1.5px;
			width: 100%;
			background-color: var(--white);
			transition: all var(--trTime3);

			&:first-child {
				transform-origin: 25%;
			}

			&:last-child {
				transform-origin: 18%;
			}
		}

		&.active {
			span {
				&:first-child {
					transform: rotate(45deg);
				}

				&:last-child {
					transform: rotate(-45deg);
				}
			}
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.MobileSize}) {
			--burgerSize: 20px;
			--burgerHeightSize: 24px;

			span {
				height: 2px;
			}
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
			--burgerSize: 22px;
			--burgerHeightSize: 26px;
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
			--burgerSize: 24px;
			--burgerHeightSize: 28px;
		}

		@media only screen and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
			--burgerSize: 25px;
			--burgerHeightSize: 30px;
		}
	}
`;

export default BurgerStyle;
