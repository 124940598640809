'use client';

import React, { useEffect, useState } from 'react';
import { usePathname, useSearchParams } from 'next/navigation';
import NextLink from 'next/link';
import queryString from 'query-string';
import pickBy from 'lodash/pickBy';
import isEmpty from 'lodash/isEmpty';

//* Helpers
import { getPageNumbers } from '@/helpers';

//* Components
import List from './components/List';
import Item from './components/Item';
import Link from './components/Link';

//* Style
import PaginationStyle from './style';

const Pagination = ({ total, pageSize }) => {
    const pathname = usePathname();
    const searchParams = useSearchParams()

    const [hasRouter, setHasRouter] = useState(false);

    useEffect(() => {
        setHasRouter(true);
    }, [pathname]);

    if (!hasRouter) return null;
    const query = pickBy({ ...(searchParams || {}) }, (q) => !isEmpty(q));
    const currentPage = Number(searchParams.get('page') || 1);

    pageSize = Number(query.size) || pageSize;
    const isLastPage = currentPage * pageSize >= total;
    const pageNumbers = getPageNumbers({ currentPage, pageSize, total });

    const url = (page) => `?${queryString.stringify({ ...query, page })}`;

    return (
        <PaginationStyle>
            <List>
                <Item>
                    {currentPage !== 1 ? (
                        <NextLink
                            href={url(currentPage - 1)}
                            passHref
                            prefetch={false}>
                            <Link label='Previous page'>
                                <i className={'icon-Icon-ionic-md-arrow-back'} />
                            </Link>
                        </NextLink>
                    ) : (
                        <Link
                            label='No previous page available'
                            disabled>
                            <i className={'icon-Icon-ionic-md-arrow-back'} />
                        </Link>
                    )}
                </Item>
                {pageNumbers.map((pageNumber, i) =>
                    pageNumber === '...' ? (
                        <Item
                            key={`${pageNumber}${i}`}
                            hellip>
                            <Link
                                disabled
                                label='ellipsis'>
                                &hellip;
                            </Link>
                        </Item>
                    ) : (
                        <Item key={pageNumber}>
                            {pageNumber === currentPage ? (
                                <Link
                                    label={`Page ${pageNumber}, current page`}
                                    disabled
                                    current>
                                    {pageNumber}
                                </Link>
                            ) : (
                                <NextLink
                                    href={url(pageNumber)}
                                    passHref
                                    prefetch={false}>
                                    <Link label={`Page ${pageNumber}`}>{pageNumber}</Link>
                                </NextLink>
                            )}
                        </Item>
                    )
                )}
                <Item>
                    {!isLastPage ? (
                        <NextLink
                            href={url(currentPage + 1)}
                            passHref
                            prefetch={false}>
                            <Link label='Next page'>
                                <i className={'icon-Icon-ionic-md-arrow-right'} />
                            </Link>
                        </NextLink>
                    ) : (
                        <Link
                            label='No next page available'
                            disabled>
                            <i className={'icon-Icon-ionic-md-arrow-right'} />
                        </Link>
                    )}
                </Item>
            </List>
        </PaginationStyle>
    );
};

export default Pagination;
