export { default as Page } from './Page';
export { default as Text } from './Text';
export { default as Form } from './Form';
export { default as Title } from './Title';
export { default as Button } from './Button';
export { default as Loader } from './Loader';
export { default as Filter } from './Filter';
export { default as Lottie } from './Lottie';
export { default as Product } from './Product';
export { default as CustomImage } from './Image';
export { default as ProdComp } from './ProdComp';
export { default as AppHeader } from './AppHeader';
export { default as AppFooter } from './AppFooter';
export { default as ShareLink } from './ShareLink';
export { default as Translate } from './Translate';
export { default as OrderItem } from './OrderItem';
export { default as Container } from './Container';
export { default as CustomLink } from './CustomLink';
export { default as Pagination } from './Pagination';
export { default as TextSlider } from './TextSlider';
export { default as OrderSummary } from './OrderSummary';
export { default as GlobalSlider } from './GlobalSlider';
export { default as SliderCursor } from './SliderCursor';
export { default as AddressesComp } from './AddressesComp';
export { default as ProductIndividual } from './ProductIndividual';
export { default as AddressItem } from './AddressesComp/addressItem';
