'use client';

import { useRef, useState, useEffect, useCallback } from 'react';
import { Fade, Modal, Backdrop } from '@mui/material';

//* HOC's
import { withAccountContext, withCartContext } from '@/context';

//* Components
import Text from '../Text';
import CustomLink from '../CustomLink';
import Translate from '../Translate';
import AddressItem from './addressItem';
import { AddressForm } from '@/components/pages';

//* Style
import ModalStyle from '@/styles/pagesStyles/ModalStyle';
import FormStyle from '@/styles/pagesStyles/FormStyle';
import AddressStyle from './style';

const AddressesComp = ({ addresses, ...props }) => {
	//! Refs
	const contRef = useRef();

	//! States
	const [open, setOpen] = useState({ opened: false, id: '' });
	const [activeClass, setActiveClass] = useState('');
	const [checked, setChecked] = useState(0);

	useEffect(() => {
		setTimeout(() => {
			if (contRef?.current?.getBoundingClientRect().y < window.innerHeight) {
				setActiveClass('active');
			}
		}, 300);

		window.addEventListener('scroll', scroll);

		return () => {
			window.removeEventListener('scroll', scroll);
		};
	});

	const scroll = useCallback(() => {
		if (contRef?.current?.getBoundingClientRect().y < window.innerHeight * 0.85) {
			setActiveClass('active');
		}
	}, []);

	const checkedAddress = (id) => {
		props.getCart(id).then(() => {
			setChecked(id);
		});
	};

	return (
		<AddressStyle>
			<div className={'addressCont'}>
				<div className='formItemTitle formItemTitleDuo AlignEnd'>
					<Text
						tag={'div'}
						className={'h7 black arm-bold'}
						text={'Addresses'}
					/>

					{props.checkoutAdresses ? (
						<span onClick={() => setOpen({ opened: true, id: '' })}>
							<Text
								tag={'span'}
								className={'pSm black arm-regular underline cursorPointer'}
								text={'AddNewAddress'}
							/>
						</span>
					) : (
						<CustomLink
							url={'/account/addresses/create'}
							content={
								<Text
									tag={'span'}
									className={'pSm black arm-regular underline'}
									text={'AddNewAddress'}
								/>
							}
						/>
					)}
				</div>

				{props.checkoutAdresses ? (
					<div className='formItemTitle'>
						<Text
							tag={'div'}
							className={'pSm black arm-regular'}
							text={'ForOtherCitiesPlease1'}
						/>

						<Text
							tag={'div'}
							className={'pSm black arm-regular'}
							children={
								<>
									<Translate val={'ForOtherCitiesPlease'} />

									<CustomLink
										url={'/contact-us'}
										className='underline'
										content={<Translate val={'ContactUs'} />}
									/>
								</>
							}
						/>
					</div>
				) : (
					''
				)}

				<div
					ref={contRef}
					className={`addressItemWrap ${activeClass} ${props.error ? 'error' : ''}`}>
					{addresses && addresses.length > 0
						? addresses.map((address, ind) => (
								<AddressItem
									key={ind}
									data={address}
									addressCheck={props.addressCheck}
									checkoutAdresses={props.checkoutAdresses}
									index={address.id}
									checked={checked}
									edit={() => {
										setOpen({ opened: true, id: address.id });
									}}
									onClick={() => {
										checkedAddress(address.id);
									}}
								/>
						  ))
						: null}
				</div>
			</div>

			{props.error ? (
				<Text
					tag={'div'}
					className={'errorMes pSm arm-regular'}
					text={'addressErrorMessage'}
				/>
			) : null}

			{props.checkoutAdresses ? (
				<Modal
					open={open.opened}
					closeAfterTransition
					BackdropComponent={Backdrop}
					BackdropProps={{
						timeout: 500,
					}}>
					<Fade in={open.opened}>
						<div className={'overflowScroll'}>
							<ModalStyle>
								<div
									className='backElem'
									onClick={() => setOpen({ opened: false, id: '' })}
								/>
								<FormStyle className='addressModal'>
									<i
										className='icon-X h7 black'
										onClick={() => setOpen({ opened: false, id: '' })}
									/>
									<AddressForm
										id={open.id}
										onClick={() => setOpen({ opened: false, id: '' })}
									/>
								</FormStyle>
							</ModalStyle>
						</div>
					</Fade>
				</Modal>
			) : (
				''
			)}
		</AddressStyle>
	);
};

export default withAccountContext(withCartContext(AddressesComp, ['getCart']), ['user', 'addresses']);
