'use client';

import React, { memo as Memo, useState, useEffect, useCallback, useMemo, Fragment } from 'react';
import { useDropzone } from 'react-dropzone';

//* Helpers
import formValidation from '../../../../../helpers/_formValidation';

//* Components
import Translate from '../../../Translate';
import FieldErrorMsg from '../../../Error/FieldErrorMsg';

const Uploader = Memo((props) => {
	//! Initial State
	const initialState = useCallback(() => {
		return props.value || [];
	}, []);

	//! States
	const [filesObj, setFilesObj] = useState(initialState);
	const [errorMsg, setErrorMsg] = useState('');

	//! Upload File Max Size
	const MaxSize = useMemo(() => props.maxSize || 8);
	const Multiple = useMemo(() => props.multiple || false);

	//! Calling Parent On Change
	useEffect(() => {
		props.onChange && props.onChange(props.name, filesObj);
	}, [filesObj]);

	//! Add File
	const onDrop = useCallback((acceptedFiles) => {
		acceptedFiles && acceptedFiles.map((file) => (file.url = URL.createObjectURL(file)));

		let newFiles = props.multiple ? [...filesObj, ...acceptedFiles] : acceptedFiles;

		setNewFiles(newFiles);
	});

	//! Revoke Files URL
	useEffect(
		() => () => {
			filesObj.forEach((file) => URL.revokeObjectURL(file));
		},
		[filesObj]
	);

	//! Remove File
	const onRemove = useCallback((elKey) => {
		setNewFiles(filesObj.filter((i, key) => key !== elKey));
	});

	//! Setting New Created Files & Data
	const setNewFiles = (files) => {
		// let formData = new FormData(); //check data in backend and then remove
		// formData.append('files', files);

		(!Multiple && filesObj.length) || validate(files);

		setFilesObj(files);
	};

	//! Validate
	const validate = useCallback((vals) => {
		if (props.realTimeValidate && props.required) {
			const valid = formValidation(
				vals,
				'uploader',
				props.errorMsg,
				!_.isBoolean(props.required) && {
					...props.required,
					multiple: Multiple,
				}
			);

			!_.isBoolean(valid) && _.isBoolean(props.parentError) ? setErrorMsg(valid) : errorMsg && setErrorMsg('');
		}
	});

	//! Dropzone HOOK
	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		accept: props.fileType || 'image/x-png,image/png,image/jpeg',
		minSize: 0,
		maxSize: MaxSize * 1048576,
		multiple: Multiple,
		onDrop,
	});

	//! Parent Component Reset
	useEffect(() => {
		if (props.parentVal !== filesObj && typeof props.parentVal !== 'array') {
			setFilesObj(initialState);
			setErrorMsg('');
		}
	}, [props.parentVal]);

	return (
		<Fragment>
			<div className={`uploader-cont FlexBox alignMiddle${props.className || ''} ${props.avatar ? 'avatar' : 'default'}`}>
				{props.avatar && filesObj.length < 1 ? (
					<div className='uploaded-image'>
						<i className={'icon-photo-camera-interface-symbol-for-button h5'} />
					</div>
				) : (
					filesObj.map(
						(file, k) =>
							file.url && (
								<div
									key={k}
									className={`uploaded-image uploader-el ${file.preview && k === 0 ? 'animate' : ''}`}>
									<div className='uploader-el-inner'>
										<div className='uploader-content'>
											<img
												src={file.url}
												alt={''}
											/>

											{props.multiple && (
												<i
													className={'icon-X'}
													onClick={() => onRemove(k)}
												/>
											)}
										</div>
									</div>
								</div>
							)
					)
				)}

				<div className={`uploader-input uploader-el ${errorMsg || props.errorMsg ? 'error' : ''} ${isDragActive ? 'active' : ''}`}>
					<div {...getRootProps({ className: 'uploader-el-inner' })}>
						<div className='uploader-content'>
							<input {...getInputProps()} />
							<span className='input-info arm-regular black underline'>{(props.avatar || !Multiple) && filesObj.length > 0 ? <Translate val='UpdatePicture' /> : <Translate val='UploadPicture' />}</span>
						</div>
					</div>
				</div>
			</div>

			<FieldErrorMsg msg={errorMsg || props.errorMsg || null} />
		</Fragment>
	);
});

export default Uploader;
