import React from 'react';

const Link = ({ children, current, disabled, label, theme, ...props }) => {
    return (
        <a
            className={`pagination__link ${current ? 'pagination__link--current' : ''} ${disabled ? 'pagination__link--disabled' : ''}`}
            aria-label={label}
            aria-current={current}
            {...props}>
            {children}
        </a>
    );
}

export default Link;