'use client';

import React, { memo as Memo } from 'react';

//* HOC's
import { withUIContext, withCartContext } from '@/context';

//* Components
import Text from '../Text';
import CustomImage from '../Image';
import CustomLink from '../CustomLink';

//* Helpers
import { config } from '@/helpers';

//* Style
import OrderItemStyle from './style';

const OrderItem = Memo((props) => {
	return (
		<OrderItemStyle>
			<div className={`tableItemBott ${props.type !== 1 ? 'cartItems' : 'orderItems'} `}>
				{props.winWidth >= 768 ? (
					<div className='tableItemBottTitles FlexBoxContainer'>
						<div className='tableCollBott'>
							<Text
								tag={'div'}
								className={'grey arm-regular'}
								text={'Product'}
							/>
						</div>
						<div className='tableCollBott'>
							<Text
								tag={'div'}
								className={'grey arm-regular'}
								text={'Quantity'}
							/>
						</div>
						<div className='tableCollBott'>
							<Text
								tag={'div'}
								className={'grey arm-regular'}
								text={'Price'}
							/>
						</div>
					</div>
				) : (
					''
				)}

				{props.items.map((item, ind) => {
					return props.winWidth >= 768 ? (
						<div
							key={ind}
							className='tableItemBotWrap alignMiddle FlexBoxContainer'>
							<div className='tableCollBott alignMiddle FlexBoxContainer'>
								{props.withoutImg ? (
									''
								) : (
									<div className='productTableImgWrap'>
										{item.product?.feature_image ? (
											<CustomImage
												src={item.product.feature_image.src}
												alt={item.product.name}
												className={'productTableImg'}
											/>
										) : null}
									</div>
								)}

								<div className='productInfoText'>
									<Text
										tag={'div'}
										className={`greyMid arm-regular ${props.type === 2 ? 'p' : 'pSm'}`}
										text={item.product?.category?.name}
									/>
									<CustomLink
										target={'_blank'}
										url={`${config.routes.products.path}/${item.product?.category?.slug}/${item.product.slug}`}>
										<Text
											tag={'div'}
											className={`greyDark arm-regular ${props.type === 2 ? 'p' : 'pSm'} underline`}
											text={item.product.name}
										/>
									</CustomLink>
								</div>
							</div>

							<div className='tableCollBott'>
								{props.type === 2 ? (
									<div className='FlexBox alignMiddle'>
										<div
											className='productQuantityMinMax'
											onClick={() => props.addToCart(item.product.id, item.quantity > 1 ? item.quantity - 1 : item.quantity, item.product.sale_price ? item.product.sale_price : item.product.price, true)}>
											<i className='icon-minus black' />
										</div>

										<Text
											tag={'span'}
											className={'quantityNumb greyDark arm-regular'}
											text={item.quantity}
										/>

										<div
											className='productQuantityMinMax'
											onClick={() => props.addToCart(item.product.id, item.quantity + 1 <= 99 ? item.quantity + 1 : item.quantity, item.product.sale_price ? item.product.sale_price : item.product.price, true)}>
											<i className='icon-plus black' />
										</div>
									</div>
								) : (
									<Text
										className={'greyDark pSm arm-regular'}
										text={item.quantity}
									/>
								)}
							</div>

							<div className='tableCollBott'>
								<Text
									className={'greyDark arm-regular'}
									text={item.product.price + props.currency}
								/>
								{item.product.sale_price ? (
									<Text
										className={'greyLight arm-regular lineThrough'}
										text={item.product.sale_price + props.currency}
									/>
								) : (
									''
								)}
							</div>

							{props.type === 2 ? (
								<i
									onClick={() => props.removeCart(item.product.id)}
									className='icon-X p black'
								/>
							) : (
								''
							)}
						</div>
					) : (
						<div
							key={ind}
							className='tableItemBotWrap FlexBoxContainer'>
							{props.withoutImg ? (
								''
							) : (
								<div className='productTableImgWrap'>
									<CustomImage
										src={item.product.feature_image.src}
										alt={item.product.name}
										className={'productTableImg'}
									/>
								</div>
							)}
							<div className='productTableInfo FlexBoxColumn spaceBetween'>
								<div className='productInfoText'>
									<Text
										tag={'div'}
										className={'grey3 pSm arm-regular'}
										text={item.product?.category?.name}
									/>
									<CustomLink
										target={'_blank'}
										url={`${config.routes.products.path}/${item.product?.category?.slug}/${item.product.slug}`}>
										<Text
											tag={'div'}
											className={'greyDark pSm arm-regular underline'}
											text={item.product.name}
										/>
									</CustomLink>
								</div>

								{!props.cartStyle ? (
									<div className='productInfoText'>
										<div className='FlexBox'>
											{props.type === 2 ? (
												<div className='FlexBox alignMiddle'>
													<div
														className='productQuantityMinMax'
														onClick={() => props.addToCart(item.product.id, item.quantity > 1 ? item.quantity - 1 : item.quantity, item.product.sale_price ? item.product.sale_price : item.product.price, true)}>
														<i className={`icon-minus black ${item.quantity > 1 ? 'black' : 'greyLight'}`} />
													</div>

													<Text
														tag={'span'}
														className={'quantityNumb greyDark arm-regular'}
														text={item.quantity}
													/>

													<div
														className='productQuantityMinMax'
														onClick={() => props.addToCart(item.product.id, item.quantity + 1 <= 99 ? item.quantity + 1 : item.quantity, item.product.sale_price ? item.product.sale_price : item.product.price, true)}>
														<i className='icon-plus black' />
													</div>
												</div>
											) : (
												<>
													<Text
														className={'grey3 pSm arm-regular'}
														text={'Quantity'}
													/>
													<Text
														className={'greyDark pSm arm-regular'}
														text={item.quantity}
													/>
												</>
											)}
										</div>

										<div className='FlexBox'>
											{props.type === 2 ? (
												<div className='FlexBox'>
													<Text
														className={'greyDark arm-regular'}
														text={item.product.price + props.currency}
													/>

													{item.product.sale_price ? (
														<Text
															className={'greyLight pSm arm-regular lineThrough'}
															text={item.product.sale_price + props.currency}
														/>
													) : (
														''
													)}
												</div>
											) : (
												<>
													<Text
														className={'grey3 arm-regular'}
														text={'Price'}
													/>
													<div>
														<Text
															className={'greyDark arm-regular'}
															text={item.product.price + props.currency}
														/>

														{item.product.sale_price ? (
															<Text
																className={'greyLight pSm arm-regular lineThrough'}
																text={item.product.sale_price + props.currency}
															/>
														) : (
															''
														)}
													</div>
												</>
											)}
										</div>
									</div>
								) : (
									''
								)}
							</div>

							{props.cartStyle ? (
								<div className='productInfoText FlexBox alignMiddle spaceBetween'>
									<div className='FlexBox'>
										{props.type === 2 ? (
											<div className='FlexBox alignMiddle'>
												<div
													className='productQuantityMinMax'
													onClick={() => props.addToCart(item.product.id, item.quantity > 1 ? item.quantity - 1 : item.quantity, item.product.sale_price ? item.product.sale_price : item.product.price, true)}>
													<i className={`icon-minus black ${item.quantity > 1 ? 'black' : 'greyLight'}`} />
												</div>

												<Text
													tag={'span'}
													className={'quantityNumb greyDark arm-regular'}
													text={item.quantity}
												/>

												<div
													className='productQuantityMinMax'
													onClick={() => props.addToCart(item.product.id, item.quantity + 1 <= 99 ? item.quantity + 1 : item.quantity, item.product.sale_price ? item.product.sale_price : item.product.price, true)}>
													<i className='icon-plus black' />
												</div>
											</div>
										) : (
											<>
												<Text
													className={'grey3 pSm arm-regular'}
													text={'Quantity'}
												/>
												<Text
													className={'greyDark pSm arm-regular'}
													text={item.quantity}
												/>
											</>
										)}
									</div>

									<div className='FlexBox'>
										{props.type === 2 ? (
											<div className='FlexBox'>
												<Text
													className={'greyDark arm-regular'}
													text={item.product.price + props.currency}
												/>
												{item.product.sale_price ? (
													<Text
														className={'greyLight pSm arm-regular lineThrough'}
														text={item.product.sale_price + props.currency}
													/>
												) : (
													''
												)}
											</div>
										) : (
											<>
												<Text
													className={'grey3 arm-regular'}
													text={'Price'}
												/>
												<div>
													<Text
														className={'greyDark arm-regular'}
														text={item.product.price + props.currency}
													/>
													{item.product.sale_price ? (
														<Text
															className={'greyLight pSm arm-regular lineThrough'}
															text={item.product.sale_price + props.currency}
														/>
													) : (
														''
													)}
												</div>
											</>
										)}
									</div>
								</div>
							) : (
								''
							)}

							{props.type === 2 ? (
								<i
									className='icon-X p black'
									onClick={() => props.removeCart(item.product.id)}
								/>
							) : (
								''
							)}
						</div>
					);
				})}
			</div>
		</OrderItemStyle>
	);
});

export default withUIContext(withCartContext(OrderItem, ['removeCart', 'addToCart']), ['winWidth']);
