import { Fragment } from 'react';

//* HOC's
import { withUIContext } from '@/context';

//* Components
import CustomLink from '../CustomLink';

//* Style
import LogoStyle from './style';

const Logo = (props) => {
	const logo = '/images/logo.svg';
	const logoMob = '/images/logoMob.svg';
	const logoBlack = '/images/logoBlack.svg';
	const logoMobBlack = '/images/logoMobBlack.svg';

	return (
		<LogoStyle>
			<CustomLink
				url={'/'}
				className={`Logo ${props.className || ''}`}
				content={
					<Fragment>
						{props.footerLogo ? (
							<img
								alt='logo'
								onClick={props.onClick}
								src={props.logoColor ? logoBlack : logo}
							/>
						) : props.winWidth < 768 ? (
							<img
								alt='logo'
								onClick={props.onClick}
								src={props.logoColor && !props.checked ? logoMobBlack : logoMob}
							/>
						) : (
							<img
								alt='logo'
								onClick={props.onClick}
								src={props.logoColor && !props.checked ? logoBlack : logo}
							/>
						)}
					</Fragment>
				}
			/>
		</LogoStyle>
	);
};

export default withUIContext(Logo, ['winWidth']);
