'use client';

import React, { memo as Memo, useMemo } from 'react';
import { usePathname } from 'next/navigation';

//* Style
import ShareStyle from './style';

const ShareLink = Memo((props) => {
    const pathname = usePathname();

    const url = useMemo(() => process.env.NEXT_PUBLIC_URL + pathname, [pathname]);
    const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${url}`;
    const twitterUrl = `https://twitter.com/intent/tweet?url=${url}`;
    const linkedinUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${url}`;

    return (
        <ShareStyle className={`shareLinkWrapp ${props.className}`}>
            <a
                href={twitterUrl}
                className='shareLinkItem'
                target='_blank'
                rel='noopener noreferrer'>
                <i className={'icon-Icon-ionic-logo-twitter'} />
            </a>
            <a
                href={facebookUrl}
                className='shareLinkItem'
                target='_blank'
                rel='noopener noreferrer'>
                <i className={'icon-facebook'} />
            </a>
            <a
                href={linkedinUrl}
                className='shareLinkItem'
                target='_blank'
                rel='noopener noreferrer'>
                <i className={'icon-linkedin'} />
            </a>
        </ShareStyle>
    );
});

export default ShareLink;
