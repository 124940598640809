import React, { memo as Memo } from 'react';

//* Components
import Text from '../../../Text';
import Tag from '../Tag';

const TagCheckbox = Memo((props) => {
	return (
		<Tag
			type='oval'
			className={`tag-checkbox ${props.checked ? 'checked' : ''}`}>
			<input
				type='checkbox'
				name={props.label}
				onChange={() => props.onChange && props.onChange(props.value)}
				checked={props.checked}
			/>

			<Text
				tag={'label'}
				className={`tag-checkbox-name`}
				text={props.label}
				onClick={() => props.onChange && props.onChange(props.value)}
			/>
		</Tag>
	);
});

export default TagCheckbox;
