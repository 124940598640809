'use client';

import { useCallback, useRef, useMemo, useEffect } from 'react';
import { TimelineLite } from 'gsap';
import { usePathname } from 'next/navigation';

//* Components
import MenuItem from './menuItem';

const Menu = (props) => {
    const pathname = usePathname();

    const getParentClassFromActiveChild = useCallback(
        (child) => {
            let el = child.filter((i) => i.path === pathname);
            return el.length ? 'active disabled' : '';
        },
        [pathname]
    );

    let refEl = useRef();

    const tl = useMemo(() => new TimelineLite({}), []);

    useEffect(() => {
        if (props.type === 'custom') {
            let arr = refEl.current.childNodes;

            for (let i = 0; i < arr.length; i++) {
                tl.to(
                    arr[i].firstChild.firstChild.firstChild.childNodes,
                    0.4,
                    {
                        y: '0%',
                        stagger: 0.1,
                    },
                    0
                );
            }

            props.checked ? tl.play() : tl.reverse(-1);
        }
    }, [props.checked, props.type]);

    const menuItems = (arr, t, c = '') => {
        return arr.map((o, k) => {
            const parentClass = o.child ? getParentClassFromActiveChild(o.child.items) : '';

            return (
                <MenuItem
                    item={o}
                    key={k}
                    classes={c + ' ' + parentClass}
                    type={t}
                    path={o.path}
                    navLinksClass={o.navLinksClass}
                />
            );
        });
    };

    return (
        <ul
            ref={refEl}
            onClick={props.onClick}
            className={props.contClass || ''}>
            {menuItems(props.items, props.type, props.itemClass, props.navLinksClass)}
        </ul>
    );
};

export default Menu;
