import styled from 'styled-components';

const SearchStyle = styled.div`
	--searchSize: 100%;

	background: var(--blackOpacity);
	position: fixed;
	z-index: 111;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	padding: var(--sp12x) var(--sp2x);
	display: flex;
	align-items: center;
	justify-content: center;
	color: var(--white);

	.close {
		cursor: pointer;
		position: absolute;
		top: calc(var(--headerPaddingTop) + 3px);
		right: var(--sp3x);
		font-size: 20px;
	}

	.searchInputWrap {
		max-width: var(--searchSize);
		width: 100%;

		.searchInput {
			position: relative;
			margin-bottom: var(--sp2x);

			input {
				padding: var(--sp2x) 0;
				background-color: transparent;
				border: 0;
				width: 100%;
				font-size: var(--h2);
				color: var(--white);
			}
			&:after {
				content: '';
				position: absolute;
				bottom: 0;
				left: 0;
				width: 0;
				border-bottom: 1px solid var(--white);
				transition: all var(--trTime2) ease;
			}
		}

		.instruction {
			display: flex;
			justify-content: flex-end;
			opacity: 0;
			transform: translateY(200%);
			transition: all var(--trTime2) ease;

			p {
				display: inline-block;
			}
		}

		&.active {
			.searchInput {
				&:after {
					width: 100%;
				}
			}

			.instruction {
				opacity: 1;
				transform: translateY(0%);
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.MobileSize}) {
		--searchSize: 88%;

		.close {
			right: var(--sp4x);
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
		--searchSize: 75%;

		.close {
			right: var(--sp4x);
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.LaptopSize}) {
		--searchSize: 58%;
		.close {
			right: var(--sp5-5x);
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
		--searchSize: 66%;
		.close {
			right: var(--sp6x);
		}
	}

	@media only screen and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
		--searchSize: 55%;

		.close {
			right: var(--sp8x);
		}

		.searchInputWrap {
			.searchInput {
				border-bottom: 2px solid var(--white);
				margin-bottom: var(--sp3x);
			}
		}
	}
`;

export default SearchStyle;
