import React, { memo as Memo } from 'react';

//* Style
import SearchStyle from './style';

const Search = Memo((props) => {
	return (
		<SearchStyle>
			<i
				className={'icon-search'}
				onClick={props.onClick}
			/>
		</SearchStyle>
	);
});

export default Search;
