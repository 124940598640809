import styled from 'styled-components';

const ShareStyle = styled.div.attrs((props) => ({ className: props.className }))`
	--shareLinkkMarg: var(--sp3x);
	--shareLinkkSize: 22px;
	--shareLinkkRight: var(--sp3x);

	margin-bottom: var(--shareLinkkMarg);

	.shareLinkItem {
		font-size: var(--shareLinkkSize);
		margin-right: var(--shareLinkkRight);
		&:last-child {
			margin-right: 0;
		}
	}

	@media only screen and (min-width: ${(props) => props.theme.mediaQuery.LaptopSize}) {
		&.otherShareLink {
			.shareLinkItem {
				display: block;
				font-size: var(--shareLinkkSize);
				margin-bottom: var(--shareLinkkRight);
				margin-right: 0;
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
		--shareLinkkSize: 26px;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
		--shareLinkkSize: 28px;
	}

	@media only screen and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
		--shareLinkkSize: 33px;
	}
`;

export default ShareStyle;
