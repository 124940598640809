'use client';

import React, { memo as Memo, Fragment, useState, useMemo, useEffect } from 'react';

//* Helpers
import { isMobile } from '@/helpers';

//* Components
import CustomSelect from './CustomSelect';
import DefaultSelect from './DefaultSelect';
import FieldErrorMsg from '../../../Error/FieldErrorMsg';

const Select = Memo((props) => {
    //! Initial State
    const selectedObj = useMemo(() => {
        return props.value
            ? props.list.filter((i) => i.value === props.value || i.label === props.value)[0]
            : {
                label: '',
                value: '',
            };
    }, [props.selectedLang]);

    //! States
    const [isDefault, setIsDefault] = useState(false);
    const [selected, setSelected] = useState(selectedObj);

    //! On Change
    const onChange = (selecedEl) => {
        selecedEl.value !== props.parentVal && setSelected(selecedEl);
    };

    //! Getting Default Value on Language Change
    useEffect(() => {
        if (selected !== selectedObj) {
            setSelected(selectedObj);
        }
    }, [props.selectedLang]);

    //! Calling Parent On change
    useEffect(() => {
        props.onChange && props.onChange(props.name, selected.value);
    }, [selected]);

    //! Check IsDefault
    const checkDefault = () => {
        if (isDefault && window.innerWidth > 760) {
            setIsDefault(false);
        } else if (isMobile() && window.innerWidth <= 760 && props.mobileDefault && !isDefault) {
            setIsDefault(true);
        }
    };

    //! Window resize
    useEffect(() => {
        window.addEventListener('resize', checkDefault);

        return () => {
            window.removeEventListener('resize', checkDefault);
        };
    });

    //! Check IsDefault First Render
    useEffect(() => {
        checkDefault();
    }, []);

    //! Change Value Call from Parent
    useEffect(() => {
        if (props.changeValue && props.changeValue !== selected) {
            onChange(props.changeValue);
        }
    }, [props.changeValue && props.changeValue]);

    //! Parent Component Reset
    useEffect(() => {
        if (props.parentVal !== selected.value) {
            setSelected(selectedObj);
        }
    }, [props.parentVal]);

    return (
        <Fragment>
            {isDefault ? (
                <DefaultSelect
                    {...props}
                    selected={selected}
                    onChange={onChange}
                />
            ) : (
                <CustomSelect
                    {...props}
                    selected={selected}
                    onChange={onChange}
                />
            )}

            <FieldErrorMsg msg={props.errorMsg || null} />
        </Fragment>
    );
});

export default Select;
