import styled from 'styled-components';

const filterStyle = styled.div`
	--filterWrapMargBottom: var(--sp10x);
	--filterTitleMargTop: var(--sp1-5x);
	--filterTitleMargLeftRight: var(--sp2x);
	--filterTitleIconSize: 8px;
	--filterItemsPaddingTop: var(--sp2x);
	--filterItemsPaddingLeftRight: var(--sp3x);
	--filterItemsMargBottom: var(--sp1-5x);
	--SortByIconSize: 6px;

	.MuiCollapse-wrapperInner {
		width: fit-content;
	}

	&.activeItem {
		.desktopVersion {
			.Category {
				transform: translateY(0%) !important;
			}
		}
	}

	.filterBackColor {
		position: fixed;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background-color: var(--blackOpacity);
		visibility: hidden;
		opacity: 0;
		transition: all var(--trTime2) ease;
		z-index: 2;

		&.active {
			visibility: visible;
			opacity: 1;
		}
	}

	.filterTitle {
		padding: var(--filterTitleMargTop) var(--filterTitleMargLeftRight);
		font-size: 20px;
	}

	.filterItems {
		.Category {
			&:hover span {
				color: var(--white);
			}
		}

		.Ingredient,
		.Category {
			cursor: pointer;
			position: relative;
			padding: 0 var(--filterItemsPaddingLeftRight);
			margin-bottom: var(--filterItemsMargBottom);
			margin-left: var(--filterTitleMargLeftRight);

			span {
				transition: all var(--trTime) ease;
			}

			&:first-child {
				margin-top: var(--filterItemsPaddingTop);
			}

			&:last-child {
				margin-bottom: var(--filterItemsPaddingTop);
			}

			&.active {
				span {
					color: var(--white);
				}
			}
		}
	}

	.desktopVersion {
		.Category {
			transform: translateY(200%);
		}

		.filterTitle {
			padding-bottom: var(--sp3x);
		}

		.filterItemsWrap {
			position: relative;

			overflow-y: scroll;
			height: calc(100dvh - var(--sp28x));

			.after-elem-min {
				border-radius: 100px;
				height: 5px;
				width: 5px;
				background-color: var(--white);
				overflow: hidden;
				position: absolute;
				top: 0;
				transform: translateY(calc(var(--pMd) / 1.8));
				transition: top 0.5s ease;
				left: 0;
			}
		}

		.SortByFilter {
			.comp-title {
				background: linear-gradient(to right, var(--grey), var(--grey) 50%, var(--white) 50%);
				background-clip: text;
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
				background-size: 210% 100%;
				background-position: 100%;
				transition: background-position var(--trTime) ease;

				&:not(.disabled) {
					@media (hover: hover) {
						&:hover {
							background-position: 0 100%;
						}
					}
				}
			}
		}
	}

	.mobileVersion {
		.filterWrap {
			width: calc(100% + (2 * var(--containerPadding)));
			margin: 0 calc(-1 * var(--containerPadding)) var(--filterWrapMargBottom);
			position: relative;
			z-index: 6;

			.filter {
				position: relative;

				.filterTitle {
					display: flex;
					justify-content: space-between;
					align-items: center;
					border-top: 1px solid var(--grey);
					border-bottom: 1px solid var(--grey);
					font-size: 20px;

					i {
						font-size: var(--filterTitleIconSize);
						transform-origin: center;
						transition: all var(--trTime2) ease;

						&.active {
							transform: rotate(180deg);
						}
					}
				}

				.filterItems {
					position: absolute;
					top: 100%;
					left: 0;
					right: 0;
					z-index: 11;
					background-color: var(--black);

					&.active {
						z-index: 12;

						.MuiCollapse-wrapperInner {
							width: 100%;
							height: 75vh;
							overflow: scroll;
						}
					}
				}
			}

			.ResetAll {
				position: absolute;
				z-index: 9;
				top: 100%;
				min-width: 210px;
				left: 0;
				right: 0;
				background-color: var(--black);
				padding: calc(var(--filterTitleMargTop) * 1.4) var(--filterTitleMargLeftRight);
				pointer-events: none;

				.reset-all-btn {
					pointer-events: all;
				}

				/* @media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
					.comp-title {
						font-size: 12px;
					}
				} */
			}

			.SortByFilter {
				position: absolute;
				z-index: 9;
				top: 100%;
				min-width: 210px;
				right: 0;
				background-color: var(--black);

				.filterItems .comp-title {
					background: linear-gradient(to right, var(--grey), var(--grey) 50%, var(--white) 50%);
					background-clip: text;
					-webkit-background-clip: text;
					-webkit-text-fill-color: transparent;
					background-size: 210% 100%;
					background-position: 100%;
					transition: background-position var(--trTime) ease;

					&:hover {
						background-position: 0 100%;
					}
				}

				&.active {
					border-left: 1px solid var(--grey);
					border-right: 1px solid var(--grey);
					border-bottom: 1px solid var(--grey);
				}

				.comp-title {
					line-height: 1;
				}

				.SortByFilterTitle {
					display: flex;
					align-items: center;
					justify-content: flex-end;
					padding: calc(var(--filterTitleMargTop) * 1.9) var(--filterTitleMargLeftRight);

					i {
						margin-left: var(--sp2x);
						font-size: var(--SortByIconSize);
						transition: all var(--trTime2) ease;
						transform-origin: center;

						&.active {
							transform: rotate(180deg);
						}
					}
				}

				.filterItems {
					text-align: right;

					.comp-title {
						margin-bottom: var(--filterItemsMargBottom);

						&:first-child {
							margin-top: var(--filterItemsMargBottom);
						}

						&:last-child {
							margin-bottom: var(--filterItemsPaddingTop);
						}
					}

					> div > div {
						margin: 0 var(--filterItemsPaddingLeftRight);
					}
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) {
		position: sticky;
		top: 70px;
		z-index: 10;
		background-color: var(--black);

		.mobileVersion {
			&:after {
				content: '';
				background-color: var(--black);
				position: absolute;
				bottom: 100%;
				left: 0;
				width: 100%;
				height: 100px;
			}
		}
	}

	@media only screen and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
		top: -2px;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) {
		.desktopVersion {
			display: none;
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
		--filterWrapMargBottom: var(--sp11x);

		top: 95px;

		.mobileVersion {
			.filterWrap {
				width: 100%;
				margin: 0 0 var(--filterWrapMargBottom);
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;

				.filter {
					flex: 0 0 calc(50% - var(--productItemPaddingLeftRight));
					max-width: calc(50% - var(--productItemPaddingLeftRight));

					.filterTitle {
						border-top: 1px solid var(--grey);
						border-bottom: 0;
					}

					.filterItems {
						border-bottom: 1px solid var(--grey);
					}
				}

				/* .SortByFilter{
                    top:calc(100% + var(--sp1x));
                }

                .ResetAll{
                    top:calc(100% + var(--sp1x));
                } */
			}
		}
	}

	@media only screen and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
		--filterTitleMargTop: 0;
		--filterTitleMargLeftRight: 0;
		--filterItemsPaddingLeftRight: var(--sp2x);
		--SortByMargLeft: var(--sp4x);
		--filterItemsMargBottom: var(--sp1-5x);

		flex: 0 0 var(--filterSize);
		max-width: var(--filterSize);

		.desktopVersion {
			position: sticky;
			top: calc(var(--marginTop) + var(--headerPaddingTop) - 10px);
			z-index: 12;

			.filterWrap {
				position: relative;

				.SortByFilter {
					position: absolute;
					top: calc(var(--sp1x) * -1);
					left: calc(100% + var(--sp6x));
					background-color: var(--black);

					.SortByItems {
						padding: var(--sp1-5x) 0px;

						.comp-title {
							position: relative;
							font-size: 16px;
							cursor: pointer;

							&:before {
								content: '';
								border-radius: 100px;
								height: 5px;
								width: 5px;
								background-color: var(--white);
								overflow: hidden;
								position: absolute;
								top: 50%;
								opacity: 0;
								transition: all var(--trTime2) ease;
								transform: translateY(-50%);
								left: calc(-1 * var(--filterItemsPaddingLeftRight));
							}

							&.active {
								&:before {
									opacity: 1;
								}
							}
						}

						> div {
							margin-left: var(--SortByMargLeft);

							&:first-child {
								margin-left: var(--sp2x);
							}
						}
					}
				}

				.filterTop {
					position: absolute;
					top: calc(-1 * (var(--marginTop) + (var(--headerPaddingTop) * 2)));
					left: calc(100% + var(--sp6x));
					height: calc(var(--marginTop) + (var(--headerPaddingTop) * 2));
					background-color: var(--black);

					.filterTopWrap {
						position: sticky;
						top: var(--headerPaddingTop);

						.filterTopItems {
							cursor: pointer;

							.IngredientImg {
								height: var(--IngredientImgHeight);
								width: var(--IngredientImgWidth);
								transform: translateY(0%) scale(1);
								transition: all var(--trTime2) ease;

								.image {
									object-fit: contain;
								}
							}

							.Ingredient {
								.word {
									display: flex;

									.letter {
										font-size: 14px;
										transition: all var(--trTime2) ease;
										transform: translateY(70%);
										opacity: 0;
										visibility: hidden;

										&:nth-child(1) {
											transition-delay: 0.05s;
										}

										&:nth-child(2) {
											transition-delay: 0.1s;
										}

										&:nth-child(3) {
											transition-delay: 0.15s;
										}

										&:nth-child(4) {
											transition-delay: 0.2s;
										}

										&:nth-child(5) {
											transition-delay: 0.25s;
										}

										&:nth-child(6) {
											transition-delay: 0.3s;
										}

										&:nth-child(7) {
											transition-delay: 0.35s;
										}

										&:nth-child(8) {
											transition-delay: 0.4s;
										}

										&:nth-child(9) {
											transition-delay: 0.45s;
										}

										&:nth-child(10) {
											transition-delay: 0.5s;
										}

										&:nth-child(11) {
											transition-delay: 0.55s;
										}

										&:nth-child(12) {
											transition-delay: 0.6s;
										}

										&:nth-child(13) {
											transition-delay: 0.65s;
										}

										&:nth-child(14) {
											transition-delay: 0.7s;
										}

										&:nth-child(15) {
											transition-delay: 0.75s;
										}

										&:nth-child(16) {
											transition-delay: 0.8s;
										}

										&:nth-child(17) {
											transition-delay: 0.85s;
										}

										&:nth-child(18) {
											transition-delay: 0.9s;
										}

										&:nth-child(19) {
											transition-delay: 0.95s;
										}

										&:nth-child(20) {
											transition-delay: 1s;
										}

										&:nth-child(21) {
											transition-delay: 1.05s;
										}

										&:nth-child(22) {
											transition-delay: 1.1s;
										}

										&:nth-child(23) {
											transition-delay: 1.15s;
										}

										&:nth-child(24) {
											transition-delay: 1.2s;
										}

										&:nth-child(25) {
											transition-delay: 1.25s;
										}

										&:nth-child(26) {
											transition-delay: 1.3s;
										}

										&:nth-child(27) {
											transition-delay: 1.35s;
										}

										&:nth-child(28) {
											transition-delay: 1.4s;
										}

										&:nth-child(29) {
											transition-delay: 1.45s;
										}

										&:nth-child(30) {
											transition-delay: 1.5s;
										}
									}
								}
							}

							&:hover,
							&.active {
								.IngredientImg {
									transform: translateY(-30%) scale(1.2);
								}

								.Ingredient {
									.word {
										.letter {
											transform: translateY(0%);
											opacity: 1;
											visibility: visible;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}

	@media only screen and (max-width: 1600px) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
		--filterItemsMargBottom: var(--sp0-5x);
		--filterItemsMargBottom: var(--sp1-5x);

		.filterItems {
			.Ingredient,
			.Category {
				&:first-child {
					margin-top: var(--filterItemsMargBottom);
				}
			}
		}

		.desktopVersion {
			top: calc(var(--marginTop) + var(--headerPaddingTop) - 20px);
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
		--IngredientImgWidth: 50px;
		--IngredientImgHeight: 28px;
		--SortByMargLeft: var(--sp5x);
		--filterItemsMargBottom: var(--sp1x);

		.filterTop {
			left: calc(var(--logoSize) + var(--sp9x));
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
		--IngredientImgWidth: 64px;
		--IngredientImgHeight: 34px;
		--SortByMargLeft: var(--sp6x);
		--filterItemsMargBottom: var(--sp1x);

		.filterTop {
			left: calc(var(--filterSize) + var(--sp6x));
		}
	}

	@media only screen and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
		--IngredientImgWidth: 80px;
		--IngredientImgHeight: 46px;
		--SortByMargLeft: var(--sp7x);
		--filterItemsMargBottom: var(--sp1-5x);

		.filterTop {
			left: calc(var(--filterSize) + var(--sp6x));
		}

		.desktopVersion {
			.filterTop {
				position: absolute;
				top: calc(-1 * (var(--marginTop) + var(--headerPaddingTop) + 40px));
			}
		}
	}
`;

export default filterStyle;
