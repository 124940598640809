'use client';

import { useRef, useEffect, useMemo, useState, useCallback } from 'react';
import { TimelineLite } from 'gsap';
import { usePathname, useSearchParams } from 'next/navigation';

//* HOC's
import { withUIContext } from '@/context';

//* Helpers
import { config } from '@/helpers';
import { createQueryUrl } from '@/helpers/_functions';

//* Components
import Text from '../Text';
import Button from '../Button';
import Filter from '../Filter';
import Product from '../Product';

const ProdComp = ({ categories, attributes, tags, activeCategory, products, searchPage, ...props }) => {
	//! Next Navigation
	const pathname = usePathname();
	const searchParams = useSearchParams();

	//! Refs
	const categoryRefs = useRef({});
	const wrapperRef = useRef();

	//! States
	const [isLogoActive, setLogoActive] = useState(false);
	const [active, setActive] = useState(false);
	const [activeAll, setActiveAll] = useState(false);

	//! Animations
	const tl1 = useMemo(() => new TimelineLite({ ease: 'power3.out' }), []);
	const tl2 = useMemo(() => new TimelineLite({ ease: 'power3.out' }), []);

	//! Handle Scroll
	const onScroll = () => {
		setLogoActive(window.pageYOffset > 500);
	};

	//! Component Did Mount
	useEffect(() => {
		window.addEventListener('scroll', onScroll);

		return () => {
			window.removeEventListener('scroll', onScroll);
		};
	}, [props.winWidth]);

	useEffect(() => {
		if (active) {
			tl1.to('.filterItems > div', 1, {
				y: '0%',
				stagger: 0.05,
			});

			tl2.to('.productItemWrap > div', 0.8, {
				y: '0%',
				stagger: 0.1,
			});

			setTimeout(() => {
				setActiveAll(true);
			}, 1000);
		}
	}, [active]);

	useEffect(() => {
		if (active === false) {
			setActive(true);
		}
	}, [props.winWidth, pathname, active]);

	//! Handle Next Category
	const handleNextCategory = () => {
		history.pushState({}, '', createQueryUrl({ url: `${config.routes.products.path}/${activeCategory.nextSlug}`, searchParams }));
	};

	//! Handle Category Change
	const upClick = useCallback(() => {
		if (typeof window !== undefined) {
			window.scrollTo({
				top: 0,
				behavior: 'smooth',
			});
		}
	}, []);

	return activeCategory ? (
		<>
			<Filter
				tags={tags}
				categories={categories}
				wrapperRef={wrapperRef}
				attributes={attributes}
				searchPage={searchPage}
				widthRef={props.widthRef}
				categoryRefs={categoryRefs}
				activeCat={activeCategory.slug}
				activeCategory={activeCategory}
				className={activeAll ? 'activeItem' : ''}
			/>

			<div
				ref={wrapperRef}
				className={`productGroupWrap ${activeAll ? 'activeItem' : ''}`}>
				{(products?.length > 0 && !searchPage) || searchPage ? (
					<div
						className='productGroupItem'
						ref={(cat) => (categoryRefs.current[activeCategory.slug] = cat)}>
						<div className='productTitle'>
							<Text
								tag={'h1'}
								className={'h7 arm-bold grey'}
								text={activeCategory.name}
							/>
						</div>

						{products?.length > 0 ? (
							<>
								<div className='productItemWrap'>
									{products.map((item, ind) => {
										if (item.length !== 0) {
											return (
												<Product
													key={ind}
													scroll={false}
													shallow={false}
													tags={item.tags}
													name={item.name}
													price={item.price}
													productId={item.id}
													quantity={item.quantity}
													sale_price={item.sale_price}
													description={item.description}
													feature_image={item.feature_image}
													slug={`${item.category.slug}/${item.slug}`}
												/>
											);
										} else {
											return (
												<Text
													key={ind}
													tag={'div'}
													className={'p arm-bold grey'}
													text={'isAbsent'}
												/>
											);
										}
									})}
								</div>

								{activeCategory.nextSlug && activeCategory.name != 'allProducts' ? (
									<Button
										splitBtn={'nextCategory'}
										onClick={handleNextCategory}
										className={'btn btn-large btn-black btn-doubleText next-cat-btn'}
									/>
								) : null}
							</>
						) : (
							<div className='productGroupItem'>
								<div className='productTitle'>
									<Text
										tag={'div'}
										className={'h7 arm-bold grey'}
										text={'NoResults'}
									/>
								</div>
							</div>
						)}
					</div>
				) : (
					<div className='productGroupItem'>
						<div className='productTitle'>
							<Text
								tag={'div'}
								className={'h7 arm-bold grey'}
								text={'NoResults'}
							/>
						</div>
					</div>
				)}
			</div>

			{products?.length > 0 ? (
				<div
					onClick={upClick}
					className={`UpBtn ${isLogoActive ? 'active' : ''}`}>
					<Text
						tag={'div'}
						className={'p arm-regular white'}
						text={'Up'}
					/>
				</div>
			) : null}
		</>
	) : null;
};

export default withUIContext(ProdComp, ['winWidth']);
