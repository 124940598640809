import styled from 'styled-components';

const HomeMenuStyle = styled.div`
	--menuPadRight: var(--sp4x);
	--menuMaxWidth: 350px;

	max-width: var(--menuMaxWidth);
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate3d(-50%, -50%, 0);
	color: var(--white);
	overflow: hidden;
	scrollbar-width: none;
	background-color: var(--black);
	-ms-overflow-style: none;
	z-index: 88;

	&::-webkit-scrollbar {
		display: none;
	}

	.HomeMenuWrap {
		width: max-content;
		overflow-x: hidden;

		a {
			color: var(--white);
			overflow: hidden;
			background: linear-gradient(to right, var(--grey), var(--grey) 50%, var(--white) 50%);
			background-clip: text;
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
			background-size: 200% 100%;
			background-position: 100%;
			transition: background-position var(--trTime) ease;
			text-decoration: none;

			&:hover {
				background-position: 0 100%;
			}
		}

		.menu-items-cont {
			display: flex;
			white-space: nowrap;
			/* overflow-x: hidden; */

			.menu-item {
				position: relative;
				font-family: arm-bold;
				font-size: var(--h9);
				padding: 0 var(--menuPadRight);

				&:after {
					content: '';
					position: absolute;
					top: 50%;
					right: 0%;
					transform: translate3d(50%, -50%, 0);
					width: 8px;
					height: 8px;
					border-radius: 100px;
					background-color: var(--white);
				}
			}
		}
	}

	@media only screen and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
		.HomeMenuWrap {
			.menu-item {
				text-transform: lowercase;
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
		--menuPadRight: var(--sp5x);
		--menuMaxWidth: 380px;
	}

	@media only screen and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
		--menuPadRight: var(--sp6x);
		--menuMaxWidth: 400px;

		.HomeMenuWrap {
			.menu-items-cont {
				.menu-item {
					&:after {
						width: 10px;
					}
				}
			}
		}
	}
`;

export default HomeMenuStyle;
