//* HOC's
import { withCartContext } from '@/context';

//* Helpers
import { config } from '@/helpers';

//* Components
import CustomLink from '@/components/common/CustomLink';

//* Style
import BasketStyle from './style';

const Basket = ({ items_quantity, subtotal }) => {
	return (
		<BasketStyle>
			<CustomLink
				className='basketWrap relativeWrap'
				url={config.routes.cart.path}>
				<i className={'icon-basket relativeWrap'}>
					<span className='quantityProd arm-bold'>{items_quantity}</span>
				</i>

				{subtotal > 0 ? (
					<div className='priceProd relativeWrap pSm arm-regular '>
						{subtotal}
						<i className='icon-currency' />
					</div>
				) : null}
			</CustomLink>
		</BasketStyle>
	);
};

export default withCartContext(Basket, ['items_quantity', 'subtotal']);
