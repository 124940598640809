'use client';

import { useEffect, useState, useRef } from 'react';
import { usePathname, useParams } from 'next/navigation';

//* HOC's
import withUIContext from '@/context/consumerHOC/UIConsumer';
import withLanguageContext from '@/context/consumerHOC/LanguageConsumer';

//* Helpers
import { config } from '@/helpers';

//* Components
import Logo from '../Logo';
import Search from './HeaderComponent/Search';
import Basket from './HeaderComponent/Basket';
import SingIn from './HeaderComponent/SingIn';
import Burger from './HeaderComponent/Burger';
import SearchPopup from './HeaderComponent/SearchPopup';
import MenuComponent from './HeaderComponent/MenuComponent';
import LanguageSwitcher from './HeaderComponent/LanguageSwitcher';

//* Style
import Header from './style';

const AppHeader = ({ winWidth, selectedLang }) => {
	//! Next Navigation
	const pathname = usePathname();
	const params = useParams();

	//! Ref
	const previousRef = useRef();

	//! States
	const [checked, setChecked] = useState(false);
	const [opened, setOpened] = useState(false);
	const [openedPop, setOpenedPop] = useState(false);
	const [scrollDir, setScrollDir] = useState('show');

	//! Checks
	const checkHome = pathname.replace(`${selectedLang}/`, '') == config.routes.home.path && winWidth >= 1440;
	const checkProductMob = pathname.includes(config.routes.products.path);
	const checkHeaderTransparent = pathname.includes(config.routes.account.path);
	const isBlackHeader = (typeof document !== 'undefined' && document.body.classList.contains('product-popup') && previousRef?.current?.prevRoute?.includes(config.routes.cart.path)) || pathname.includes(config.routes.cart.path) || pathname.includes(config.routes.checkout.path) || pathname.includes(config.routes.thankYouMessage.path);

	const isProductIndividual = pathname.replace(`${selectedLang}/`, '') === `${config.routes.products.path}/${params?.slug}/${params?.productSlug}/`;

	useEffect(() => {
		if (checked && !opened) {
			setOpened(true);

			document.body.classList.add('modal-open');
		} else if (!checked && opened) {
			setOpened(false);

			document.body.classList.remove('modal-open');
		}
	}, [checked, opened]);

	useEffect(() => {
		setChecked(false);

		document.body.classList.value == 'modal-open' && document.body.classList.remove('modal-open');

		return () => {
			previousRef.current = { prevRoute: pathname };
		};
	}, [pathname]);

	const handleClick = () => {
		setChecked(!checked);
	};

	const searchPopupClick = () => {
		if (openedPop == true) {
			setOpenedPop(false);

			document.body.classList.remove('modal-open');
		} else {
			setOpenedPop(true);
			setChecked(false);

			document.body.classList.add('modal-open');
		}
	};

	useEffect(() => {
		if (pathname.includes('menu')) {
			const threshold = 0;
			let lastScrollY = window.pageYOffset;
			let ticking = false;

			const updateScrollDir = () => {
				const scrollY = window.pageYOffset;

				if (Math.abs(scrollY - lastScrollY) < threshold) {
					ticking = false;
					return;
				}

				setScrollDir(scrollY > lastScrollY ? 'hide' : 'show');
				lastScrollY = scrollY > 0 ? scrollY : 0;
				ticking = false;
			};

			const onScroll = () => {
				if (!ticking) {
					window.requestAnimationFrame(updateScrollDir);
					ticking = true;
				}
			};

			window.addEventListener('scroll', onScroll);

			return () => window.removeEventListener('scroll', onScroll);
		}
	}, [scrollDir, pathname]);

	return (
		<Header className={`${checkHeaderTransparent ? 'headerTransparent' : isBlackHeader ? 'headerBlack' : checkProductMob ? 'productMob' : checkHome ? 'Home' : ''} ${scrollDir}`}>
			<Logo
				checked={checked}
				footerLogo={false}
				className={'headerLogo'}
				logoColor={isBlackHeader}
			/>

			<div className={`widgetWrap ${checked ? 'active' : ''}`}>
				{winWidth >= 1440 ? (
					<>
						{!checkHome ? (
							<Burger
								checked={checked}
								onClick={handleClick}
							/>
						) : null}

						<Search onClick={searchPopupClick} />

						<SingIn />

						<Basket />

						{!isProductIndividual && <LanguageSwitcher />}
					</>
				) : (
					<>
						{!isProductIndividual && <LanguageSwitcher />}

						<Basket />

						<SingIn />

						<Search onClick={searchPopupClick} />

						<Burger
							checked={checked}
							onClick={handleClick}
						/>
					</>
				)}
			</div>

			<SearchPopup
				openedPop={openedPop}
				onClick={searchPopupClick}
			/>

			<MenuComponent
				opened={opened}
				onClick={handleClick}
				isHomePage={checkHome}
			/>
		</Header>
	);
};

export default withUIContext(withLanguageContext(AppHeader, ['selectedLang']), ['winWidth']);
