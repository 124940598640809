import styled from 'styled-components';

const CursorStyle = styled.div.attrs((props) => ({ className: props.className }))`
	--cursorSize: 85px;
	--cursorAftrerEl: -10px;

	position: fixed;
	z-index: 111;
	transform: translate3d(-50%, -50%, 0);
	transition: top 0.05s ease, left 0.05s ease, scale 0.5s ease;
	visibility: hidden;
	pointer-events: none;

	&:after {
		position: absolute;
		top: 50%;
		right: 20%;
		clip-path: polygon(0 0, 0% 100%, 100% 50%);
		transform: translate(100%, -50%);
		content: '';
		height: 10px;
		width: 6px;
		background-color: var(--white);
		transition: all 0.1s ease;
		opacity: 0;
	}

	&:before {
		position: absolute;
		top: 50%;
		left: 20%;
		clip-path: polygon(100% 0, 0 50%, 100% 100%);
		transition: all 0.1s ease;
		transform: translate(-100%, -50%);
		content: '';
		height: 10px;
		width: 6px;
		background-color: var(--white);
		opacity: 0;
	}

	.cursorDrag {
		border: 2px solid var(--white);
		width: var(--cursorSize);
		height: var(--cursorSize);
		border-radius: 100px;
		border: 2px solid var(--white);
		width: var(--cursorSize);
		height: var(--cursorSize);
		transform: scale(0.7);
		transition: all 0.1s ease;
		opacity: 0;
	}

	&.active {
		visibility: visible;
		transform: translate3d(-50%, -50%, 0);
		.cursorDrag {
			transform: scale(1);
			opacity: 1;
		}

		&:after {
			opacity: 1;
			right: var(--cursorAftrerEl);
		}

		&:before {
			opacity: 1;
			left: var(--cursorAftrerEl);
		}
	}

	&.cursor-clicked {
		.cursorDrag {
			transform: scale(0.7);
		}
		&:after {
			transform: translate(0%, -50%);
		}

		&:before {
			transform: translate(0%, -50%);
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
		--cursorSize: 70px;
		--cursorAftrerEl: -15px;

		&:after,
		&:before {
			height: 10px;
			width: 6px;
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.LaptopSize}) {
		--cursorSize: 85px;
		--cursorAftrerEl: -18px;

		&:after,
		&:before {
			height: 12px;
			width: 8px;
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
		--cursorSize: 85px;
		--cursorAftrerEl: -18px;

		&:after,
		&:before {
			height: 12px;
			width: 8px;
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
		--cursorSize: 100px;
		--cursorAftrerEl: -20px;

		&:after,
		&:before {
			height: 12px;
			width: 8px;
		}
	}

	@media only screen and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
		--cursorSize: 120px;
		--cursorAftrerEl: -24px;
		&:after,
		&:before {
			height: 14px;
			width: 10px;
		}
	}
`;

export default CursorStyle;
