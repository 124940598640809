'use client';

import { useCallback, useState } from 'react';

//* HOC's
import withUIContext from '@/context/consumerHOC/UIConsumer';

//* Components
import Nav from './types/nav';
import Default from './types/default';
import Social from './types/social';
import Custom from './types/custom';
import Translate from '../../Translate';

const MenuItem = (props) => {
    const [isActive, setIsActive] = useState(false);

    const checkType = useCallback(() => {
        const type = props.type;

        if (type === 'nav') {
            return props.svg ? (
                <div className='navCont'>
                    <span
                        className={'Nav' + props.classes}
                        onMouseMove={props.onMouseMove}
                        onMouseOver={props.onMouseMove}
                        onMouseOut={props.onMouseOut}>
                        <Nav
                            path={props.path}
                            className={props.classes}>
                            <Translate val={props.item.name} />
                        </Nav>
                    </span>
                </div>
            ) : (
                <Nav
                    className={props.classes}
                    path={props.path}
                    data={props.item.name}>
                    <Translate val={props.item.name} />
                </Nav>
            );
        } else if (type === 'custom') {
            return (
                <Custom
                    checked={props.checked}
                    className={props.classes}
                    path={props.path}
                    data={props.item.name}
                    text={props.item.name}>
                    <Translate val={props.item.name} />
                </Custom>
            );
        } else if (type === 'social') {
            return (
                <Social
                    path={props.item.path}
                    icon={props.item.iconName || props.item.name}
                />
            );
        } else {
            return (
                <Default path={props.path}>
                    <Translate val={props.item.name} />
                </Default>
            );
        }
    }, [props.type, props.path, props.item, props.onMouseMove, props.onMouseOut, props.svg, props.classes, props.checked]);

    const onClick = useCallback(() => {
        if (props.winWidth < props.screenSizes.screenLg) {
            props.children && setIsActive(!isActive);
        }
        props.onClick && props.onClick();
    }, [props.winWidth, props.onClick, props.children, props.screenSizes.screenLg, isActive]);

    return (
        <li
            className={`${props.classes} ${isActive ? 'active' : ''}`}
            onClick={onClick}>
            {checkType()}
            {props.children}
        </li>
    );
};

export default withUIContext(MenuItem, ['screenSizes', 'winWidth']);
