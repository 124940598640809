'use client';

import React, { memo as Memo, useState, useEffect, useMemo } from 'react';
import { usePathname } from 'next/navigation';
import Link from 'next/link';
import { Collapse } from '@mui/material';

//* HOC's
import { withLanguageContext } from '@/context';

//* Style
import LanguageSwitcherStyle from './style';

const LanguageSwitcher = Memo(({ selectedLang, languages }) => {
	const [selected, setSelected] = useState(false);

	const handleClick = () => {
		setSelected(!selected);
	};

	const pathname = usePathname();

	useEffect(() => {
		setSelected(false);
	}, [pathname]);

	const langs = useMemo(() => {
		return Object.keys(languages).map((lang) => {
			const route = pathname.replace(`/${selectedLang}`, '');

			return selectedLang !== lang ? (
				<Link
					key={lang}
					as={`/${lang}${route}`}
					href={`/[lang]${route}`}
					className={`${selectedLang === lang ? 'active' : ''} p arm-regular capitalize`}>
					{languages[lang].params.name}
				</Link>
			) : null;
		});
	}, [selectedLang, languages, pathname]);

	return (
		<LanguageSwitcherStyle>
			<div
				onClick={handleClick}
				className={'activeLanguage p arm-regular capitalize'}>
				{selectedLang}
			</div>
			<Collapse
				in={selected}
				timeout='auto'
				className='lang-cont'>
				{langs}
			</Collapse>
		</LanguageSwitcherStyle>
	);
});

export default withLanguageContext(LanguageSwitcher, ['selectedLang', 'languages']);
