import styled from 'styled-components';

const ModalStyle = styled.div.attrs((props) => ({ className: props.className }))`
	--addressModalTopPadd: var(--sp8x);
	--addressModalRightPadd: var(--containerPadding);
	--closePad: var(--containerPadding);
	--addressModalWidth: 100%;

	.addressModalWrap {
		height: 100vh;
	}

	.title {
		margin-bottom: var(--sp2x);
	}

	.addressModal {
		padding: var(--addressModalTopPadd) var(--addressModalRightPadd);
		background-color: var(--white);
		position: relative;
		width: var(--addressModalWidth);

		.icon-X {
			position: absolute;
			top: var(--closePad);
			left: var(--closePad);
			cursor: pointer;
		}
	}

	.ModalBtnCont {
		margin-top: var(--sp3x);
		> div {
			width: 100%;
		}
		.col {
			width: 100%;
			.btn-black {
				border: 2px solid var(--black);
			}
			&:first-child {
				margin-bottom: var(--sp2x);
			}
		}
	}

	.backElem {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
		//Modall
		--addressModalTopPadd: var(--sp8x);
		--addressModalRightPadd: var(--sp2-5x);
		--closePad: var(--sp2x);
		--addressModalWidth: 90%;
	}

	@media only screen and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		.addressModal {
			margin: 50px auto;
		}

		.ModalBtnCont {
			margin-top: var(--sp3x);
			display: flex;
			justify-content: space-between;
			.col {
				width: calc(50% - var(--sp1-5x));
				&:first-child {
					margin-bottom: 0;
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.LaptopSize}) {
		//Modall
		--addressModalTopPadd: var(--sp5x);
		--addressModalRightPadd: var(--sp9x);
		--closePad: var(--sp2x);
		--addressModalWidth: 75%;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
		//Modall
		--addressModalTopPadd: var(--sp7x);
		--addressModalRightPadd: var(--sp12x);
		--closePad: var(--sp3x);
		--addressModalWidth: 59%;

		.ModalBtnCont {
			margin-top: var(--sp4x);
			.col {
				width: calc(50% - var(--sp2x));
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
		//Modall
		--addressModalTopPadd: var(--sp10x);
		--addressModalRightPadd: var(--sp12x);
		--closePad: var(--sp4x);
		--addressModalWidth: 48%;

		.ModalBtnCont {
			margin-top: var(--sp4x);
			.col {
				width: calc(50% - var(--sp2x));
			}
		}
	}

	@media only screen and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
		//Modall
		--addressModalTopPadd: var(--sp10x);
		--addressModalRightPadd: var(--sp12x);
		--closePad: var(--sp4x);
		--addressModalWidth: 1135px;

		.ModalBtnCont {
			margin-top: var(--sp6x);
			.col {
				width: calc(50% - var(--sp2x));
			}
		}
	}
`;

export default ModalStyle;
