'use client';

import _ from 'lodash';
import { useParams, usePathname, useRouter } from 'next/navigation';
import { useMemo } from 'react';

//* HOC's
import { withAccountContext, withCartContext, withDataContext, withLanguageContext } from '@/context';

//* Helpers
import { config } from '@/helpers';
import formJson from '@/helpers/forms.json';

//* Components
import { CustomLink, Form, Text } from '@/components/common';

const AddressForm = ({ globalData, addresses, getCart, id, selectedLang, ...props }) => {
    //! Next Navigation
    const params = useParams();
    const pathname = usePathname();
    const router = useRouter();

    const cities = useMemo(() => {
        const cities = globalData?.cities;

        return cities?.map((city) => ({ label: city.name, value: city.id }));
    }, [globalData, selectedLang]);

    const addressNewParams = useMemo(() => {
        const params = _.cloneDeep(formJson.addressParams);

        params.fields.city_id.props.list = cities;

        const formId = id || params.id;

        if (formId) {
            let editableAddress = addresses.filter((addr) => addr.id == formId);

            if (editableAddress.length) {
                for (let key in params.fields) {
                    if (key === 'is_default') {
                        _.setWith(params.fields[key], 'props.list[0].checked', editableAddress[0][key], params);
                    } else {
                        _.setWith(params.fields[key], 'props.value', editableAddress[0][key], params);
                    }
                }
            }
        } else {
            params.fields.city_id.props.value = cities?.[0].value;
        }

        return params;
    }, [cities, params, addresses, selectedLang, formJson.addressParams]);

    const formSubmit = (obj) => {
        obj.is_default = !!obj.is_default.length;

        (id ? props.updateAddress(id, obj) : props.createAddress(obj)).then(() => {
            if (!pathname.includes(config.routes.checkout.path)) {
                router.push('/account/addresses');
            } else {
                if (obj.is_default) {
                    getCart();
                }
            }
            props.onClick && props.onClick();
        });
    };

    return (
        <div className={'addressForm'}>
            <Text
                tag={'div'}
                className={'h7 formItemTitle black arm-bold'}
                text={'NewAddress'}
            />

            <div className={'formCont'}>
                <Form
                    submit={formSubmit}
                    wrapClass={'blackForm'}
                    className={'formItems'}
                    {...addressNewParams}
                />

                <div className='PasswordPop'>
                    {props.onClick ? (
                        <span onClick={props.onClick}>
                            <Text
                                tag={'div'}
                                className={'h9 arm-regular grey3 underline cursorPointer'}
                                text={'Cancel'}
                            />
                        </span>
                    ) : (
                        <CustomLink url={config.routes.addresses.path}>
                            <Text
                                tag={'div'}
                                className={'h9 arm-regular grey3 underline'}
                                text={'Cancel'}
                            />
                        </CustomLink>
                    )}
                </div>
            </div>
        </div>
    );
};

export default withLanguageContext(withDataContext(withAccountContext(withCartContext(AddressForm, ['getCart']), ['addresses', 'createAddress', 'updateAddress']), ['globalData']), ['selectedLang']);
