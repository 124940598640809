'use client';

import { useMemo, useEffect, useCallback, useRef, useState } from 'react';
import { TimelineLite } from 'gsap';

//* HOC's
import withUIContext from '@/context/consumerHOC/UIConsumer';

//* Components
import Menu from '@/components/common/Menu';
import ContactInformation from '../ContactInformation';

//* Helpers
import config from '@/helpers/_config';

//* Styles
import MenuStyle from './style';
import HomeMenuStyle from './homeStyle';

const MenuComponent = ({ globalData, isHomePage, ...props }) => {
    //! State
    const [menuAnim, setMenuAnim] = useState(false);

    //! Routes from Config
    const routes = useMemo(() => config.routes, []);

    //! Big Menu Object
    const menuObj = useMemo(
        () => ({
            contClass: 'menu-items-cont',
            itemClass: 'menu-item',
            items: [
                {
                    name: routes.products.name,
                    path: routes.products.path,
                },
                {
                    name: routes.aboutUs.name,
                    path: routes.aboutUs.path,
                },
                {
                    name: routes.contactUs.name,
                    path: routes.contactUs.path,
                },
                {
                    name: routes.news.name,
                    path: routes.news.path,
                },
                {
                    name: routes.muramoney.name,
                    path: routes.muramoney.path,
                },
            ],
        }),
        [routes]
    );

    //! Refs
    const textRef = useRef();
    const hieroglyphRef1 = useRef();
    const hieroglyphRef2 = useRef();
    const hieroglyphRef3 = useRef();
    const hieroglyphRef4 = useRef();

    //! Global Variables
    const fastSpeed = useMemo(() => 15, []);
    const slowSpeed = useMemo(() => 50, []);

    const tl = useMemo(() => new TimelineLite({ repeat: -1 }), []);
    const tl2 = useMemo(() => new TimelineLite({ ease: 'power4.out' }), []);

    //! Animation
    const toggleHover = useCallback(
        (startDuration) => {
            tl.duration(startDuration);
        },
        [textRef]
    );

    //! Animation Start
    useEffect(() => {
        if (!isHomePage || props.winWidth < 1440) {
            tl2.to(hieroglyphRef1.current, 1, {
                y: '0%',
                delay: 0,
            })
                .to(hieroglyphRef2.current, 1.2, {
                    y: '0%',
                    delay: -1,
                })
                .to(hieroglyphRef3.current, 0.9, {
                    y: '0%',
                    delay: -1.1,
                })
                .to(hieroglyphRef4.current, 0.9, {
                    y: '0%',
                    delay: -1.1,
                });
        } else {
            tl.to(textRef.current, 15, {
                x: '-50%',
                ease: 'none',
            });
        }
    }, [menuAnim, props.winWidth, isHomePage]);

    useEffect(() => {
        setMenuAnim(isHomePage);

        props.opened ? tl2.play() : tl2.reverse(-1);

        menuAnim ? tl.play() : tl.clear();
    }, [menuAnim, isHomePage, props.opened]);

    return !isHomePage || props.winWidth < 1440 ? (
        <MenuStyle className={props.opened ? 'active' : ''}>
            <div className='menuWrap'>
                <Menu
                    type={'custom'}
                    checked={props.opened}
                    onClick={props.onClick}
                    {...menuObj}
                />
            </div>

            <ContactInformation
                type
                onClick={props.onClick}
            />

            <div className={'hieroglyphCont FlexBox'}>
                <div
                    ref={hieroglyphRef1}
                    className='hieroglyph h1 white'>
                    シ
                    <br />
                    ョ
                    <br />
                    ッ
                    <br />プ
                </div>
                <div
                    ref={hieroglyphRef2}
                    className='hieroglyph h1 white'>
                    私
                    <br />
                    た
                    <br />
                    ち
                    <br />
                    に
                    <br />
                    関
                    <br />
                    し
                    <br />
                    て
                    <br />は
                </div>
                <div
                    ref={hieroglyphRef3}
                    className='hieroglyph h1 white'>
                    ニ
                    <br />
                    ュ
                    <br />
                    ー
                    <br />ス
                </div>
                <div
                    ref={hieroglyphRef4}
                    className='hieroglyph h1 white'>
                    連
                    <br />
                    絡
                    <br />先
                </div>
            </div>
        </MenuStyle>
    ) : (
        <HomeMenuStyle>
            <div
                ref={textRef}
                className='HomeMenuWrap FlexBox'
                onMouseEnter={() => toggleHover(slowSpeed)}
                onMouseLeave={() => toggleHover(fastSpeed)}>
                <Menu
                    type={'nav'}
                    {...menuObj}
                />
                <Menu
                    type={'nav'}
                    {...menuObj}
                />
            </div>
        </HomeMenuStyle>
    );
};

export default withUIContext(MenuComponent, ['winWidth']);
