import React, { memo as Memo, useMemo } from 'react';

//* HOC's
import { withLanguageContext } from '@/context';

//* Helpers
import config from '../../../helpers/_config';

//* Components
import CustomLink from '../CustomLink';

const PrivacyPolicyLink = Memo((props) => {
	//! Routes from Config
	const routes = useMemo(() => config.routes, []);

	return (
		<div className={`PrivacyPolicy ${props.className ? props.className : ''}`}>
			<CustomLink
				className={'p arm-regular'}
				url={routes.privacyPolicy.path}
				content={props.translate('PrivacyPolicy2')}
			/>
		</div>
	);
});

export default withLanguageContext(PrivacyPolicyLink, ['translate', 'selectedLang']);
