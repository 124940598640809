import React, { memo as Memo } from 'react';

//* Components
import CustomLink from '../../../CustomLink';

const Default = Memo((props) => {
	return (
		<CustomLink
			url={props.path + 'dsfdf'}
			content={props.text}
		/>
	);
});

export default Default;
