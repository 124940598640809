'use client';

import { memo as Memo, useState, useRef, useCallback, useEffect } from 'react';
import { usePathname, useParams, useSearchParams } from 'next/navigation';
import { Collapse } from '@mui/material';

//* HOC's
import { withUIContext } from '@/context';

//* Helpers
import { config } from '@/helpers';
import { createQueryString, createQueryUrl } from '@/helpers/_functions';
import { stringToLetters } from '@/helpers/_functions';

//* Components
import Text from '../Text';
import CustomImage from '../Image';

//* Style
import FilterStyle from '@/styles/pagesStyles/FilterStyle';

const Filter = Memo(({ activeCat, categoryRefs, wrapperRef, widthRef, categories, attributes, tags, searchPage, winWidth, className }) => {
	//! Next Navigation
	const pathname = usePathname();
	const searchParams = useSearchParams();
	const { slug } = useParams();

	//! Ref
	const titleRefs = useRef([]);

	//! States
	const [dotPosition, setDotPosition] = useState(0);
	const [widthCont, setWidthCont] = useState(0);
	const [activeTag, setActiveTag] = useState();
	const [activeIng, setActiveIng] = useState();
	const [selected, setSelected] = useState({ sec1: false, sec2: false, sec3: false });
	const [backLayer, setBackLayer] = useState(false);

	const handleClick = (index) => {
		if (index.sec1 === selected.sec1 && index.sec2 === selected.sec2 && index.sec3 === selected.sec3) {
			setSelected({ sec1: false, sec2: false, sec3: false });
			document.body.classList.remove('modal-open');
		} else if (index == false) {
			setSelected({ sec1: false, sec2: false, sec3: false });
			document.body.classList.remove('modal-open');
		} else {
			setSelected(index);
			document.body.classList.add('modal-open');
		}

		setBackLayer(!backLayer);
	};

	useEffect(() => {
		if (winWidth < 1440) {
			setSelected({ sec1: false, sec2: false, sec3: false });
			setBackLayer(false);
		}

		if (slug && slug.length === 1) {
			onCategoryChange(slug[0]);
		}
	}, [winWidth]);

	useEffect(() => {
		for (let i = 0; i < categories?.length; i++) {
			if (activeCat === categories[i].slug) {
				setDotPosition(titleRefs?.current[i]?.offsetTop);
			}
		}
	}, [activeCat, categories]);

	useEffect(() => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	}, [activeCat]);

	useEffect(() => {
		setActiveTag(searchParams.get('tag'));
		setActiveIng(searchParams.get('attr'));

		if (typeof window !== undefined && searchParams && searchParams.size >= 1) {
			window.scrollTo({ top: 0 });
		}
	}, [searchParams]);

	//! Handle Category Change
	const onCategoryChange = useCallback(
		(slug) => {
			if (typeof window !== undefined) {
				const el = categoryRefs.current?.[slug]?.getBoundingClientRect();
				const wrap = wrapperRef.current?.getBoundingClientRect();

				el?.top &&
					window.scrollTo({
						top: el.top - wrap.top,
						behavior: 'instant',
					});

				history.pushState({}, '', createQueryUrl({ url: `${config.routes.products.path}/${slug}`, searchParams }));
			}
		},
		[activeCat, pathname, searchParams]
	);

	useEffect(() => {
		widthRef.current && setWidthCont(widthRef.current?.children[1].offsetWidth);
	}, [widthCont, winWidth]);

	//! On Filter Param Change
	const onFilterChange = useCallback(
		(type, typeId, multiple = false) => {
			const queryParams = createQueryString({ type, typeId, multiple, searchParams });

			history.pushState({ queryParams }, '', pathname + '?' + queryParams);
		},
		[searchParams]
	);

	//! Rest Filters
	const resetAllFilters = useCallback(() => {
		history.pushState({}, '', pathname);
	}, [pathname]);

	return (
		<FilterStyle className={className || ''}>
			{winWidth >= 1440 ? (
				<div className='desktopVersion'>
					<div className='filterWrap'>
						{!searchPage && (
							<div className='filter'>
								<div className='filterTitle cursorPointer'>
									<Text
										tag={'div'}
										className={'h7 arm-regular'}
										text={'Category'}
									/>
								</div>
								{categories.length > 0 ? (
									<div className='filterItemsWrap'>
										<div className='filterItems'>
											{categories.map((item, ind) => {
												return (
													<div
														key={ind}
														ref={(ref) => (titleRefs.current[ind] = ref)}
														onClick={() => onCategoryChange(item.slug, ind)}
														className={`Category ${activeCat === item.slug ? 'active' : ''}`}>
														<Text
															tag={'span'}
															className={'h10 arm-regular grey2 cursorPointer'}
															text={item.name}
														/>
													</div>
												);
											})}
										</div>

										{(activeCat || activeCat == '') && (
											<div
												className={'after-elem-min'}
												style={{ top: dotPosition + 10 }}
											/>
										)}
									</div>
								) : null}
							</div>
						)}

						{widthCont != 0 ? (
							<>
								<div
									className='filterTop'
									style={{ width: widthCont - 48 }}>
									<div className='filterTopWrap FlexBox spaceBetween alignMiddle'>
										{attributes.map((item, ind) => {
											return (
												<div
													key={ind}
													className={`filterTopItems FlexBoxColumn alignMiddle ${activeIng == item.name ? 'active' : ''}`}
													onClick={() => onFilterChange('attr', item.name)}>
													<CustomImage
														src={item.image.src}
														alt={item.name}
														className={'IngredientImg'}
													/>
													<Text
														tag={'span'}
														className={'Ingredient arm-regular'}
														children={stringToLetters(item.name)}
													/>
												</div>
											);
										})}
									</div>
								</div>

								<div
									className='SortByFilter FlexBox spaceBetween'
									style={{ width: widthCont - 48 }}>
									{searchParams && searchParams.size >= 1 ? (
										<div
											className={`${searchPage ? '' : 'underline cursorPointer'} FlexBox alignMiddle`}
											onClick={() => !searchPage && resetAllFilters()}>
											<Text
												tag={'div'}
												className={`${searchPage ? 'disabled' : ''} p arm-regular capitalize`}
												text={searchPage ? 'searchResults' : 'ResetAllFilters'}
											/>
										</div>
									) : (
										<div />
									)}

									<div className='SortByItems FlexBox cursorPointer'>
										{tags.map((item, ind) => {
											return (
												<div
													key={ind}
													onClick={() => onFilterChange('tag', item.name)}>
													<Text
														tag={'div'}
														className={`arm-bold capitalize cursorPointer ${activeTag == item.name ? 'active' : ''}`}
														text={item.name}
													/>
												</div>
											);
										})}
									</div>
								</div>
							</>
						) : null}
					</div>
				</div>
			) : (
				<>
					<div
						className={`filterBackColor ${backLayer ? 'active' : ''}`}
						onClick={() => handleClick(false)}
					/>

					<div className='mobileVersion'>
						<div className='filterWrap'>
							{!searchPage ? (
								<div className='filter'>
									<div
										className='filterTitle cursorPointer'
										onClick={() => {
											handleClick({ sec1: true, sec2: false, sec3: false });
										}}>
										<Text
											tag={'div'}
											className={'arm-regular'}
											text={'Category'}
										/>
										<i className={`icon-arrow-bottom ${selected.sec1 ? 'active' : ''}`} />
									</div>

									<div
										className={`filterItems ${selected.sec1 ? 'active' : ''}`}
										onClick={() => {
											handleClick(false);
										}}>
										<Collapse
											in={selected.sec1}
											timeout='auto'>
											{categories.map((item, ind) => {
												return (
													<div
														key={ind}
														onClick={() => onCategoryChange(item.slug)}
														ref={(ref) => (titleRefs.current[ind] = ref)}
														className={`Category cursorPointer ${activeCat === item.slug ? 'active' : ''}`}>
														<Text
															tag={'span'}
															className={'pMd arm-regular grey2 cursorPointer'}
															text={item.name}
														/>
													</div>
												);
											})}
										</Collapse>
									</div>
								</div>
							) : (
								''
							)}

							<div className='filter'>
								<div
									className='filterTitle cursorPointer'
									onClick={() => {
										handleClick({ sec1: false, sec2: true, sec3: false });
									}}>
									<Text
										tag={'div'}
										className={'arm-regular'}
										text={'Ingredient'}
									/>
									<i className={`icon-arrow-bottom ${selected.sec2 ? 'active' : ''}`} />
								</div>

								<div
									className={`filterItems ${selected.sec2 ? 'active' : ''}`}
									onClick={() => {
										handleClick(false);
									}}>
									<Collapse
										in={selected.sec2}
										timeout='auto'>
										{attributes.map((item, ind) => {
											return (
												<Text
													attr={{
														onClick: () => onFilterChange('attr', item.name),
													}}
													key={ind}
													tag={'div'}
													className={'Ingredient pMd arm-regular grey2 cursorPointer'}
													text={item.name}
												/>
											);
										})}
									</Collapse>
								</div>
							</div>

							{searchParams && searchParams.size >= 1 ? (
								<div
									className='ResetAll'
									onClick={() => !searchPage && resetAllFilters()}>
									<div className={`${searchPage ? '' : 'underline'} FlexBox alignMiddle`}>
										<Text
											tag={'div'}
											className='h10 arm-regular capitalize cursorPointer reset-all-btn'
											text={searchPage ? 'searchResults' : 'ResetAllFilters'}
										/>
									</div>
								</div>
							) : (
								''
							)}

							<div className={`SortByFilter ${selected.sec3 ? 'active' : ''}`}>
								<div
									className='SortByFilterTitle cursorPointer'
									onClick={() => {
										handleClick({ sec1: false, sec2: false, sec3: true });
									}}>
									<Text
										tag={'div'}
										className={'h10 arm-regular'}
										text={'SortBy'}
									/>
									<i className={`icon-arrow-bottom ${selected.sec3 ? 'active' : ''}`} />
								</div>

								<div
									onClick={() => handleClick(false)}
									className={`filterItems ${selected.sec3 ? 'active' : ''}`}>
									<Collapse
										timeout='auto'
										in={selected.sec3}>
										{tags.map((item, ind) => {
											return (
												<Text
													key={ind}
													tag={'div'}
													text={item.name}
													className={'cursorPointer h10 arm-regular grey2 capitalize'}
													attr={{
														onClick: () => onFilterChange('tag', item.name),
													}}
												/>
											);
										})}
									</Collapse>
								</div>
							</div>
						</div>
					</div>
				</>
			)}
		</FilterStyle>
	);
});

export default withUIContext(Filter, ['winWidth']);
